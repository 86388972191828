export enum BOOKING_TYPE {
  BOOKING = 'booking',
  MAILBOX = 'mailbox',
  STORAGE = 'storage',
  RECURRING = 'recurring',
}

export const LeasesTypeArr = Object.values(BOOKING_TYPE)

export enum BOOKING_PARAMS {
  TYPE = 'type',
  BOOKING_ID = 'bookingId',
}

export enum BOOKING_TYPE_CODE {
  BOOKING = 1,
  MAILBOX = 2,
  STORAGE = 6,
}

export const getBookingTypeFromCode = (code: number): BOOKING_TYPE => {
  switch (code) {
    case BOOKING_TYPE_CODE.BOOKING:
      return BOOKING_TYPE.BOOKING
    case BOOKING_TYPE_CODE.MAILBOX:
      return BOOKING_TYPE.MAILBOX
    case BOOKING_TYPE_CODE.STORAGE:
      return BOOKING_TYPE.STORAGE
    default:
      return BOOKING_TYPE.RECURRING
  }
}
