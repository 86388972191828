import type { FC, JSX } from 'react'
import { Fragment, memo } from 'react'

import type { RadioChangeEvent } from 'antd'
import { Radio } from 'antd'

import { ArrayElements } from 'common/components/ArrayElements/ArrayElements'
import { Show } from 'common/components/Show/Show'

import { PaymentCard } from 'features/Profile/features/Payment/components/PaymentCard/PaymentCard'
import type { ISettingPaymentCard } from 'features/SettingsOld/interfaces/ISettingPaymentCard'

import styles from './PaymentCardList.module.scss'

type Props = {
  selected: string
  isFetching: boolean
  isDisabled?: boolean
  list: ISettingPaymentCard[]
  handleAddNewCard?: () => void
  handleEditCard?: (card: ISettingPaymentCard) => void
  handleChangeMethod?: (event: RadioChangeEvent) => Promise<void> | void
}

export const PaymentCardList: FC<Props> = memo(
  ({
    list,
    isDisabled,
    isFetching,
    selected,
    handleAddNewCard,
    handleEditCard,
    handleChangeMethod,
  }) => {
    return (
      <Radio.Group
        value={selected}
        className={styles.parent}
        onChange={handleChangeMethod}
        disabled={isFetching || isDisabled}>
        <Show
          when={!isFetching}
          fallback={
            <ArrayElements total={2}>
              <div className={styles.parentDisabled}>
                <Radio>
                  <PaymentCard isFetching />
                </Radio>
              </div>
            </ArrayElements>
          }>
          {list.map(
            (card: ISettingPaymentCard): JSX.Element => (
              <Fragment key={card.id}>
                <Radio value={card.id}>
                  <PaymentCard
                    isFetching={isFetching}
                    card={card}
                    isActive={selected === card.id}
                    handleEditCard={handleEditCard ? () => handleEditCard(card) : undefined}
                  />
                </Radio>
              </Fragment>
            ),
          )}
        </Show>
        <Show when={handleAddNewCard}>
          <Radio disabled value='new_card'>
            <PaymentCard
              isFetching={isFetching}
              isDisabled
              isValidList={!!list.length}
              handleAddCard={handleAddNewCard}
            />
          </Radio>
        </Show>
      </Radio.Group>
    )
  },
)
