import 'styles/index.scss'
import type { FC } from 'react'
import { memo, useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import Header from 'layout/Header/Header'
import { Sidebar } from 'layout/Sidebar/Sidebar'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { generateRouter } from 'routes/routes.utils'

import { useScreenWidth } from 'app/hooks/useScreenWidth'
import { useAuth, useNotification } from 'app/providers'

import { CartWidget } from 'common/components/CartWidget/CartWidget'
import { Show } from 'common/components/Show/Show'

import { Admin } from 'features/Admin'
import { Cart } from 'features/Cart'
import { Faq } from 'features/Faq'
import { Home } from 'features/Home'
import { Patients } from 'features/Patients'
import { Profile } from 'features/Profile/Profile'
import { PromotionsRoute } from 'features/Promotions'
import { Provider } from 'features/Provider/pages/Provider'
import { Staff } from 'features/Staff'
import { Support } from 'features/Support/pages/Support'

export const Main: FC = memo(() => {
  const { isMobile } = useScreenWidth()
  const { isSidebarVisible, toggleIsSidebarVisible } = useAuth()
  const { notification } = useNotification()

  const routesConfig = useMemo(
    () => ({
      [PATH_SEGMENT.FAQ]: <Faq />,
      [PATH_SEGMENT.STAFF]: <Staff />,
      [PATH_SEGMENT.ADMIN]: <Admin />,
      [PATH_SEGMENT.PATIENTS]: <Patients />,
      [PATH_SEGMENT.PROMOTIONS]: <PromotionsRoute />,
      [`${PATH_SEGMENT.HOME}/*`]: <Home />,
      [`${PATH_SEGMENT.PROVIDER}/*`]: <Provider />,
      [`${PATH_SEGMENT.PROFILE}/*`]: <Profile />,
      [`${PATH_SEGMENT.SUPPORT}/*`]: <Support />,
      [PATH_SEGMENT.OTHER]: <Navigate to={PATH_SEGMENT.HOME} replace />,
    }),
    [],
  )

  return (
    <>
      <Header />
      <Show when={!isMobile}>
        <Sidebar />
      </Show>
      <Show when={isMobile && isSidebarVisible}>
        <Sidebar
          isSidebarVisible={isSidebarVisible}
          toggleIsSidebarVisible={toggleIsSidebarVisible}
        />
      </Show>

      <div className='application-layout__content'>
        <div
          className='application-layout__content-container'
          onClick={() => isMobile && isSidebarVisible && toggleIsSidebarVisible()}>
          {notification}
          {generateRouter(routesConfig)}

          <Cart />
          <CartWidget />
        </div>
      </div>
    </>
  )
})
