import type { FC } from 'react'
import React, { memo, useEffect, useMemo } from 'react'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { Navigate, useParams } from 'react-router-dom'
import { generateRouter } from 'routes/routes.utils'
import { PatientSchedule } from './PatientSchedule/PatientSchedule'
import { PatientTracker } from './PatientTracker/PatientTracker'
import { Staff } from './Staff/Staff'
import { useSites } from 'common/hooks/useSites'
import { Documents } from './Documents/Documents'
import { Shop } from './Shop/Shop'
import { TabPanel } from 'common/components/TabPanel/TabPanel'
import { bookingConfig } from './config'
import { useLazyFetchLeaseInfoQuery } from 'features/Info/state/api/infoApi'
import { UPDATE_INFO_TIME_ZONE } from 'features/Info/state/slice/infoSlice'
import { useAppDispatch } from 'common/hooks/redux'
import { BookingDetails } from './BookingDetails/BookingDetails'
import { Spinner } from 'common/components/Spinner'

export const Booking: FC = memo(() => {
  const dispatch = useAppDispatch()

  const { bookingId } = useParams()
  const { selectedSite } = useSites()

  const [fetchBooking, { data: booking, isFetching: isFetchingBooking }] =
    useLazyFetchLeaseInfoQuery()

  const bookingApiProps = useMemo(() => {
    if (!selectedSite || !bookingId) {
      return
    }

    return {
      bookingId,
      connection: selectedSite.code,
    }
  }, [bookingId, selectedSite])

  useEffect(() => {
    if (!bookingApiProps) {
      return
    }

    fetchBooking({ ...bookingApiProps })
  }, [bookingApiProps])

  useEffect(() => {
    if (!booking) {
      return
    }

    dispatch(UPDATE_INFO_TIME_ZONE(booking.time_zone))
  }, [booking])

  const tabs = useMemo(() => {
    return bookingConfig.getTabs(booking?.lease_type_name)
  }, [booking])

  const routesConfig = useMemo(
    () => ({
      [`${PATH_SEGMENT.DETAILS}/*`]: (
        <BookingDetails
          bookingApiProps={bookingApiProps}
          booking={booking}
          isFetchingBooking={isFetchingBooking}
        />
      ),
      [PATH_SEGMENT.PATIENT_SCHEDULE]: <PatientSchedule />,
      [PATH_SEGMENT.PATIENT_TRACKER]: <PatientTracker />,
      [PATH_SEGMENT.STAFF]: <Staff bookingApiProps={bookingApiProps} />,
      [`${PATH_SEGMENT.DOCUMENTS}/*`]: <Documents bookingApiProps={bookingApiProps} />,
      [PATH_SEGMENT.SHOP]: <Shop />,
      [PATH_SEGMENT.ROOT]: <Navigate to={PATH_SEGMENT.DETAILS} replace />,
    }),
    [booking, bookingApiProps, isFetchingBooking],
  )

  return (
    <>
      {!!booking ? (
        <>
          <TabPanel tabs={tabs} />
          {generateRouter(routesConfig)}
        </>
      ) : (
        <div className='content-page-wrapper'>
          <Spinner size='large' isLoading={true} />
        </div>
      )}
    </>
  )
})

export type BookingType = typeof Booking

Booking.displayName = 'Booking'
