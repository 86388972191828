import type { FC } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'

import type { UploadFile, UploadProps } from 'antd'
import { Carousel, Skeleton, Spin, Upload } from 'antd'

import { useNotification } from 'app/providers'

import removeIcon from 'features/Info/assets/trash.svg'
import uploadIcon from 'features/Info/assets/upload.svg'

import { Button } from 'common/components/Button/Button'
import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { UPLOAD_CONSTANTS } from 'common/constants/uploadConstants'
import { UtilService } from 'common/services/utilService'

import { INFO_CONSTANTS } from 'features/Info/constants/infoConstants'
import type { IMediaFile } from 'features/Info/interfaces/IMediaFile'

import './infoUpload.scss'

const MAX_IMAGES = 20

const { fileSizeInMB } = UtilService
const { Dragger } = Upload
const acceptedUploadType = ['image/png', 'image/jpeg', 'application/pdf']

interface IProps {
  screen: number
  isFetching: boolean
  isDisabled: boolean
  isRemovingMedia: boolean
  uploadedFiles: IMediaFile[]
  isUploading?: boolean
  handleRemoveMedia: (id: string) => void
  handleAddMedia: (screen: number, file: File) => void
}

export const InfoUpload: FC<IProps> = ({
  uploadedFiles,
  handleRemoveMedia,
  screen,
  isFetching,
  isDisabled,
  handleAddMedia,
  isRemovingMedia,
  isUploading,
}) => {
  const { setNotification } = useNotification()
  const [fileList, setFileList] = useState<IMediaFile[]>([...uploadedFiles])
  const uploadRef = useRef(null)
  const [isLocalUploading, setIsLocalUploading] = useState(false)

  useEffect(() => {
    setFileList(uploadedFiles)
    setIsLocalUploading(false)
  }, [uploadedFiles])

  const props: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'file',
    maxCount: MAX_IMAGES,
    multiple: false,
    fileList: uploadedFiles,
    beforeUpload: () => {
      setIsLocalUploading(true)
    },
    onChange(info) {
      const { file } = info

      if (fileSizeInMB(file.size) >= 10) {
        setNotification({
          type: ALERT_CONSTANTS.ERROR,
          title: UPLOAD_CONSTANTS.SIZE_ERROR_TITLE,
          description: UPLOAD_CONSTANTS.SIZE_ERROR_DESCRIPTION,
        })

        setIsLocalUploading(false)
        return
      }
      if (!acceptedUploadType.includes(file.type as string)) {
        setNotification({
          type: ALERT_CONSTANTS.ERROR,
          title: UPLOAD_CONSTANTS.TYPE_ERROR_TITLE,
          description: UPLOAD_CONSTANTS.TYPE_ERROR_DESCRIPTION,
        })

        setIsLocalUploading(false)
        return
      }

      if (acceptedUploadType.includes(file.type as string) && fileSizeInMB(file.size) < 10) {
        info.file.status = 'uploading'
        setFileList([...info.fileList] as IMediaFile[])
        handleAddMedia(screen, info.file.originFileObj)
        setIsLocalUploading(false)
      }
    },
    customRequest: () => {},
  }

  const uploadProcess = useMemo(
    () =>
      !uploadedFiles.length ? (
        <Dragger {...props} itemRender={() => null}>
          <h3 className='media-upload-title'>{INFO_CONSTANTS.SELECT_DRAG}</h3>
          <p className='media-upload-description'>{INFO_CONSTANTS.FILE_TYPES}</p>
          <p className='media-upload-description'>{INFO_CONSTANTS.DIMENSIONS}</p>
        </Dragger>
      ) : (
        <Carousel className='media-carousel' autoplay dots={false}>
          {uploadedFiles.map((file: any, index: number) => (
            <img
              key={index}
              className='media-uploaded-image'
              src={
                file.hasOwnProperty('originFileObj')
                  ? URL.createObjectURL(file?.originFileObj)
                  : file?.url
              }
              alt={ALT_CONSTANTS.UPLOADED_IMAGE}
            />
          ))}
        </Carousel>
      ),
    [uploadedFiles],
  )

  const isActionDisabled =
    isDisabled || isFetching || isRemovingMedia || isLocalUploading || isUploading

  return (
    <div className={`${isDisabled ? 'media-disabled' : ''}`}>
      <span className='media-label'>
        {INFO_CONSTANTS.DISPLAY}
        {screen}
      </span>

      <div className='media-slide'>
        {isFetching ? <Skeleton.Image active className='media-upload-skeleton' /> : uploadProcess}
      </div>

      <Upload
        {...props}
        ref={uploadRef}
        disabled={isActionDisabled}
        fileList={fileList}
        listType='picture-card'
        className='media-upload'
        itemRender={(originNode, file: UploadFile) => {
          return (
            <Spin spinning={isRemovingMedia || isFetching || isUploading}>
              <div className='media-upload-item'>
                <img
                  className='media-upload-image'
                  src={file?.thumbUrl || file?.url}
                  alt={ALT_CONSTANTS.UPLOADED_IMAGE}
                />

                <Button
                  disabled={isActionDisabled}
                  onClick={() => handleRemoveMedia(file?.uid)}
                  className='reset-button media-remove'>
                  <div className='remove-icon'>
                    <img src={removeIcon} alt={ALT_CONSTANTS.ACTION_ICON} />
                  </div>
                </Button>
              </div>
            </Spin>
          )
        }}>
        {fileList.length >= MAX_IMAGES || isFetching || isDisabled ? null : (
          <img src={uploadIcon} alt={ALT_CONSTANTS.ACTION_ICON} />
        )}
      </Upload>
    </div>
  )
}
