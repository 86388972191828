import { Table } from 'common/components/Table/Table'
import { TABLE_ACTIONS } from 'common/constants/tableConstants'

import { INFO_CONSTANTS } from 'features/Info/constants/infoConstants'
import { ACTIVITY_TABLE_COLUMNS } from 'features/Info/constants/infoTableConstants'

import styles from './patientTracker.module.scss'
import type { FC } from 'react'
import { memo } from 'react'

export const PatientTracker: FC = memo(() => {
  return (
    <div className={styles.parent}>
      <div className={styles.parentTimeActivity}>
        <h2 className={styles.parentTitle}>{INFO_CONSTANTS.REAL_TIME_ACTIVITY}</h2>
        <Table
          inTableEdit
          haveSelection
          itemKey='code'
          dataSource={[]}
          isLoading={false}
          columns={ACTIVITY_TABLE_COLUMNS}
          handleUpdateRow={() => {}}
          handleRemoveRow={() => {}}
          handleCreateRow={() => {}}
          actions={[TABLE_ACTIONS.EDIT, TABLE_ACTIONS.DELETE]}
        />
      </div>

      <div className={styles.parentHistory}>
        <h3 className={styles.parentHistoryTitle}>{INFO_CONSTANTS.ACTIVITY_HISTORY}</h3>
      </div>
    </div>
  )
})
