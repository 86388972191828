import type { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { createApi } from '@reduxjs/toolkit/query/react'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { HTTP_METHODS } from 'common/constants/httpMethodsConstants'
import type { IOption } from 'common/interfaces/IOption'
import { UtilService } from 'common/services/utilService'
import { baseQuery } from 'common/store/api/fetchBaseQuery'
import { REDUCERS } from 'common/store/reducerConstants'

const { withPaginationParams } = UtilService

export const supportApi = createApi({
  reducerPath: REDUCERS.SUPPORT,
  baseQuery: baseQuery(),
  tagTypes: ['Support', 'Message'],
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchTickets: build.query({
      query: ({ site, tableParams }) => ({
        url: withPaginationParams('/support-tickets', tableParams),
        headers: { 'Database-Connection': site },
      }),
      providesTags: ['Support'],
    }),
    fetchTicketCategories: build.query({
      query: () => ({
        url: '/support-tickets/create',
      }),
      transformResponse: ({ categories }: { categories: IOption[] }): IOption[] => categories,
    }),
    createTicket: build.mutation({
      query: (ticket) => ({
        data: ticket,
        url: '/support-tickets',
        method: HTTP_METHODS.POST,
      }),
    }),
    fetchTicketDetails: build.query({
      query: ({ id, connection }) => ({
        url: `/support-tickets/${id}`,
        headers: { 'Database-Connection': connection },
      }),
      providesTags: ['Message'],
    }),
    addMessageOnTicket: build.mutation({
      query: ({ id, data, connection }) => ({
        data: data,
        method: HTTP_METHODS.POST,
        url: `/support-tickets/${id}/add-message`,
        headers: { 'Database-Connection': connection },
      }),
      invalidatesTags: ['Message'],
    }),
  }),
})

export const {
  useLazyFetchTicketsQuery,
  useCreateTicketMutation,
  useLazyFetchTicketCategoriesQuery,
  useAddMessageOnTicketMutation,
  useFetchTicketDetailsQuery,
  useLazyFetchTicketDetailsQuery,
}: any = supportApi
