import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'

import { withLazy } from 'app/hocs/withLazy'
import { PusherProvider } from 'app/providers/pusher/PusherProvider'

import { TabPanel } from 'common/components/TabPanel/TabPanel'

import {
  PromotionRoutes,
  PromotionsDocumentsTabs,
  PromotionsTabs,
} from 'features/Promotions/constants/promotionsRoutes'
import { PatientSchedule } from 'features/Home/Book/Booking/PatientSchedule/PatientSchedule'
import { PatientTracker } from 'features/Home/Book/Booking/PatientTracker/PatientTracker'
import { Staff } from 'features/Home/Book/Booking/Staff/Staff'

const PromotionDetails = withLazy(() => import('./PromotionDetails'))
const PromotionNotifications = withLazy(() => import('./PromotionNotifications'))
const PromotionBookingContracts = withLazy(() => import('./PromotionBookingContracts'))
const PromotionCheckout = withLazy(() => import('./PromotionCheckout'))

export const PromotionsRoute = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()

  const onChangeTab = (key: string) => {
    navigate(`/home/${params.connection}/promotions/${params.leaseId}/${key}`)
  }

  const lastPath = location.pathname.split('/').pop() as PromotionRoutes

  const activeKey = PromotionsTabs.map(({ path }) => path).find(
    (route) => route === lastPath || params['*'].includes(route),
  )

  return (
    <>
      {!!activeKey && (
        <TabPanel
          destroyInactiveTabPane
          tabs={PromotionsTabs}
          activeKey={activeKey}
          onChange={onChangeTab}
        />
      )}
      <PusherProvider selectedSite={params.connection} selectedLease={params.leaseId}>
        <Routes>
          <Route index element={<Navigate to={PromotionRoutes.Details} replace />} />
          <Route path={PromotionRoutes.Details} element={<PromotionDetails />} />
          <Route path={PromotionRoutes.PatientSchedule} element={<PatientSchedule />} />
          <Route path={PromotionRoutes.PatientActivity} element={<PatientTracker />} />
          <Route
            path={`${PromotionRoutes.PatientSchedule}/:tabKey`}
            element={<PatientSchedule />}
          />

          <Route path={PromotionRoutes.Staff} element={<Staff />} />
          <Route path={PromotionRoutes.BookingContracts} element={<PromotionBookingContracts />} />
          <Route path={PromotionRoutes.Notifications} element={<PromotionNotifications />} />
          <Route
            path={PromotionRoutes.Documents}
            element={<TabPanel tabs={PromotionsDocumentsTabs} />}
          />
          <Route path={PromotionRoutes.Checkout} element={<PromotionCheckout />} />
          <Route path='*' element={<Navigate to={PromotionRoutes.Details} replace />} />
        </Routes>
      </PusherProvider>
    </>
  )
}
