import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import type { ISiteRoom } from 'common/interfaces/ISiteRoom'
import type { TableParamsType } from 'common/interfaces/ITable'
import { UtilService } from 'common/services/utilService'
import { baseQuery } from 'common/store/api/fetchBaseQuery'
import { REDUCERS } from 'common/store/reducerConstants'

import type { BOOKING_TYPE } from 'features/Booking/constants/bookingType'
import type {
  BookingOption,
  BookingOptionTypes,
  IBooking,
} from 'features/Booking/interfaces/IBooking'
import type { IRoomOptionProduct, IRoomTimeSlot } from 'features/Booking/interfaces/ILeaseInfoRoom'

const { withPaginationParams } = UtilService

export enum BOOKING_TAGS {
  BOOKINGS = 'Bookings',
}

export const bookingApi = createApi({
  reducerPath: REDUCERS.BOOKING,
  baseQuery: baseQuery(),
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchBookings: build.query<IBooking[], BOOKING_TYPE>({
      query: (type) => ({
        url: `/leases?filter=${type}`,
      }),
      providesTags: [BOOKING_TAGS.BOOKINGS],
      transformResponse: (data: { items: IBooking[] }): IBooking[] => data.items,
    }),

    fetchBookingsWithPagination: build.query<
      {
        items: IBooking[]
        total_items: number
      },
      {
        type: BOOKING_TYPE
        tableParams: TableParamsType
        connection: string
      }
    >({
      query: ({ connection, type, tableParams }) => {
        let url = withPaginationParams('leases', tableParams) + `&filter=${type}`
        return {
          url,
          headers: {
            'Database-Connection': connection,
          },
        }
      },
      providesTags: [BOOKING_TAGS.BOOKINGS],
    }),

    fetchBookingOption: build.query<BookingOption, { request_url_id: number; connection: string }>({
      query: ({ request_url_id, connection }) => ({
        url: `/booking/${request_url_id}/option`,
        headers: {
          'Database-Connection': connection,
        },
      }),
      providesTags: [BOOKING_TAGS.BOOKINGS],
    }),

    fetchBookingTypes: build.query<BookingOptionTypes, string>({
      query: (connection) => ({
        url: '/booking/types',
        headers: {
          'Database-Connection': connection,
        },
      }),
      providesTags: [BOOKING_TAGS.BOOKINGS],
    }),
    fetchRooms: build.query<
      {
        rooms: ISiteRoom[]
        site_options: ISiteRoom[]
        medical_staff: ISiteRoom[]
      },
      string
    >({
      query: (connection) => ({
        url: '/newlease',
        headers: {
          'Database-Connection': connection,
        },
      }),
    }),
    fetchRoomSlots: build.query({
      query: ({ roomId, date, connection }) => ({
        url: `/lease/room/${roomId}/day-slots/${date}`,
        headers: { 'Database-Connection': connection },
      }),
      transformResponse: ({ slots }: { slots: IRoomTimeSlot[] }): IRoomTimeSlot[] => {
        const getServiceOptions = (services: IRoomOptionProduct[]) => {
          const options: IRoomOptionProduct[] = services.filter(
            ({ type }: IRoomOptionProduct): boolean => type !== 'service',
          )

          const filteredServices: IRoomOptionProduct[] = services.filter(
            ({ type }: IRoomOptionProduct): boolean => type === 'service',
          )

          return {
            options,
            services: filteredServices,
          }
        }

        return slots.map((slot: IRoomTimeSlot): IRoomTimeSlot => {
          if (!slot?.options) return slot
          const { options, services } = getServiceOptions(slot.options.services)
          return {
            ...slot,
            options: {
              services: services,
              products: [...options, ...slot.options.products],
              provision_packages: slot.options.provision_packages,
            },
          }
        })
      },
    }),
    fetchRoomDays: build.query({
      query: ({ id, date, connection }) => ({
        url: `/lease/room/${id}/calendar/${date}`,
        headers: { 'Database-Connection': connection },
      }),
    }),
  }),
})

export const {
  useLazyFetchRoomSlotsQuery,
  useLazyFetchRoomDaysQuery,
  useLazyFetchRoomsQuery,
  useFetchBookingsQuery,
  useLazyFetchBookingsQuery,
  useLazyFetchBookingTypesQuery,
  useFetchBookingTypesQuery,
  useFetchBookingOptionQuery,
  useLazyFetchBookingOptionQuery,
  useLazyFetchBookingsWithPaginationQuery,
}: any = bookingApi
