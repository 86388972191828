import { Row } from 'antd'

import { Button } from 'common/components/Button/Button'
import { Modal } from 'common/components/Modal'
import { BUTTON_CONSTANTS, BUTTON_MODIFIER } from 'common/constants/buttonConstants'

import { INFO_CONSTANTS } from 'features/Info/constants/infoConstants'

import styles from './cancelLeaseNoteModal.module.scss'

interface IProps {
  isVisible: boolean
  onHide: () => void
}
export function CancelLeaseNoteModal({ isVisible, onHide }: IProps) {
  return (
    <Modal
      style={{ minWidth: '500px' }}
      keyboard
      open={isVisible}
      onCancel={onHide}
      title={INFO_CONSTANTS.NOTE}
      destroyOnClose>
      <>
        <p className={styles.parent}>
          {INFO_CONSTANTS.CANCEL_NOTE_1}{' '}
          <span className={styles.parentSiteManager}>{INFO_CONSTANTS.SITE_MANAGER}</span>{' '}
          {INFO_CONSTANTS.CANCEL_NOTE_2}
        </p>
        <Row justify='center' align='middle'>
          <Button onClick={onHide} modifier={BUTTON_MODIFIER.SECONDARY}>
            {BUTTON_CONSTANTS.OK}
          </Button>
        </Row>
      </>
    </Modal>
  )
}
