import { type FC, memo } from 'react'
import { useParams } from 'react-router-dom'

import { Table } from 'common/components/Table/Table'
import type { ITriggerRequest } from 'common/interfaces/IRequestResponse'
import { TableService } from 'common/services/tableService'

import { INFO_CONSTANTS } from 'features/Info/constants/infoConstants'
import { INFO_DOCUMENTS_TABLE_COLUMNS } from 'features/Info/constants/infoDocumentsTableColumns'
import type { IInfoDocument } from 'features/Info/interfaces/IInfoDocument'
import { useLazyDownloadContractQuery } from 'features/Info/state/api/infoApi'

import styles from './documentsTable.module.scss'
import type { DocumentsTableProps } from './documentsTable.types'

const { withDownloadPdfForColumn } = TableService

export const DocumentsTable: FC<DocumentsTableProps> = memo(({ isLoading, data }) => {
  const params = useParams()

  const [downloadContract, { isLoading: isDownloadingContract }]: ITriggerRequest =
    useLazyDownloadContractQuery()

  return (
    <div className={styles.parent}>
      <h1 className={styles.parentTitle}>{INFO_CONSTANTS.CONTRACTS}</h1>
      <Table
        itemKey='name'
        dataSource={data}
        haveActions={false}
        columns={withDownloadPdfForColumn<IInfoDocument>({
          columnKey: 'name',
          columns: INFO_DOCUMENTS_TABLE_COLUMNS,
          tooltipContent: INFO_CONSTANTS.CONTRACT,
          downloadContract: (contract) => {
            return downloadContract({ ...params, type: contract.type })
          },
        })}
        isLoading={isLoading || isDownloadingContract}
      />
    </div>
  )
})
