import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { ICartOptionContent } from 'common/interfaces/ICartContent'
import { REDUCERS } from 'common/store/reducerConstants'

import { type BOOKING_TYPE, BOOKING_TYPE_CODE } from 'features/Booking/constants/booking.constants'
import type { ICartOptionData } from 'features/Cart/interfaces/ICart'
import type { ICartTaxes } from 'features/Cart/interfaces/ICartTaxes'
import type { CartUpdatePayload, ICartSlice } from 'features/Cart/state/interfaces/ICartSlice'

const initialState: ICartSlice = {
  cart: null,
  isOpenCart: false,
  isUpdateMode: false,
  isSignContract: false,
  activeBooking: null,
  leaseConnection: null,
}

export const cartSlice = createSlice({
  name: REDUCERS.CART,
  initialState: initialState,
  reducers: {
    UPDATE_CART_PRICE(state, action: PayloadAction<ICartTaxes>) {
      const { amount } = state.cart[BOOKING_TYPE_CODE.BOOKING]?.data ?? {}
      if (amount)
        state.cart[BOOKING_TYPE_CODE.BOOKING].data.amount = { ...amount, ...action.payload }
    },

    UPDATE_CART_CONNECTION(state, action: PayloadAction<string>) {
      state.cart[BOOKING_TYPE_CODE.BOOKING].database_connection = action.payload
    },

    UPDATE_CART_CONTENT(state, action: PayloadAction<ICartOptionContent | null>) {
      state.cart[BOOKING_TYPE_CODE.BOOKING] = action.payload
    },

    UPDATE_CART_CONTENT_DATA(state, action: PayloadAction<Partial<ICartOptionData>>) {
      const { cart } = state
      const bookingCart = cart[BOOKING_TYPE_CODE.BOOKING]
      const isValidRoomsData: boolean =
        !!action.payload?.rooms?.length || !!action.payload.signature
      if (bookingCart?.data && isValidRoomsData)
        state.cart[BOOKING_TYPE_CODE.BOOKING].data = { ...bookingCart.data, ...action.payload }
      else if (isValidRoomsData)
        state.cart[BOOKING_TYPE_CODE.BOOKING] = { data: action.payload } as any
    },

    HANDLE_EDIT_CART(state) {
      state.isOpenCart = false
      state.isSignContract = false
      state.isUpdateMode = true
    },

    TOGGLE_IS_OPEN_CART_DETAILS(state) {
      return {
        ...state,
        isOpenCart: !state.isOpenCart,
        isSignContract: state.isSignContract ? !state.isSignContract : state.isSignContract,
      }
    },

    HANDLE_TOGGLE_IS_OPEN_CART_OVERLAY(state) {
      state.isOpenCart = !state.isOpenCart
    },
    HANDLE_TOGGLE_IS_UPDATE_MODE(state, action: PayloadAction<boolean>) {
      state.isUpdateMode = action.payload
    },
    TOGGLE_IS_SIGN_CONTRACT(state) {
      state.isSignContract = !state.isSignContract
    },
    updateActiveBooking(
      state,
      action: PayloadAction<{
        bookingType: BOOKING_TYPE
        requestId?: number
        visible: boolean
      } | null>,
    ) {
      state.activeBooking = action.payload
    },

    updateCartState(state, action: PayloadAction<CartUpdatePayload>) {
      const { key, value } = action.payload
      if (key in state) {
        state[key as string] = value
      }
    },
    updateSingleCart(
      state,
      action: PayloadAction<{ key: number; data: ICartOptionContent | null }>,
    ) {
      const { key, data } = action.payload
      if (!data) {
        delete state.cart[key]
      } else {
        state.cart[key] = data
      }
    },
    updateLeaseConnection(state, action: PayloadAction<string | null>) {
      state.leaseConnection = action.payload
    },
  },
})

export const {
  UPDATE_CART_PRICE,
  UPDATE_CART_CONTENT,
  UPDATE_CART_CONNECTION,
  UPDATE_CART_CONTENT_DATA,
  TOGGLE_IS_OPEN_CART_DETAILS,
  TOGGLE_IS_SIGN_CONTRACT,
  updateCartState,
  updateActiveBooking,
  updateSingleCart,
  updateLeaseConnection,
} = cartSlice.actions
