import 'styles/index.scss'
import type { FC } from 'react'
import { useMemo } from 'react'

import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { withProviders } from './app/hocs'
import { useAuth } from './app/providers'

import { Payment } from './features/Payment'

import { Auth } from './pages/Auth'
import { Guest } from './pages/Guest'
import { Main } from './pages/Main'
import { SiteRedirect } from './pages/SiteRedirect'
import { PATH_SEGMENT } from './routes/pathSegments'
import { ProtectedRoute } from './routes/ProtectedRoutes/ProtectedRoute'
import { PublicRoute } from './routes/ProtectedRoutes/PublicRoute'
import { generateRouter } from './routes/routes.utils'
import { theme } from './theme'

dayjs.extend(isSameOrAfter)
dayjs.extend(utc)
dayjs.extend(isBetween)
dayjs.extend(timezone)

const App: FC = () => {
  const { user } = useAuth()

  const routesConfig = useMemo(
    () => ({
      [`${PATH_SEGMENT.GUEST}/*`]: <Guest />,
      [PATH_SEGMENT.ROOT]: (
        <ProtectedRoute>
          <SiteRedirect />
        </ProtectedRoute>
      ),
      [`${PATH_SEGMENT.ROOT}:siteId/*`]: (
        <ProtectedRoute>
          <Main />
        </ProtectedRoute>
      ),
      [PATH_SEGMENT.CHECKOUT]: (
        <ProtectedRoute>
          <Payment />
        </ProtectedRoute>
      ),
      [`${PATH_SEGMENT.AUTH}/*`]: (
        <PublicRoute>
          <Auth />
        </PublicRoute>
      ),
    }),
    [user],
  )

  return <ConfigProvider theme={theme}>{generateRouter(routesConfig)}</ConfigProvider>
}

export default withProviders(App)
