import { useEffect, useRef } from 'react'

import ZoomIn from 'features/Info/assets/zoom-in.svg'
import ZoomOut from 'features/Info/assets/zoom-out.svg'

import styles from './InfoZoomControls.module.scss'

const ZoomControls: React.FC<{
  onZoomIn: () => void
  onZoomOut: () => void
  setZoomControlsWidth: (width: number) => void
}> = ({ onZoomIn, onZoomOut, setZoomControlsWidth }) => {
  const zoomControlRef = useRef(null)

  useEffect(() => {
    if (zoomControlRef.current) {
      setTimeout(() => {
        setZoomControlsWidth(zoomControlRef.current?.offsetWidth)
      }, 0)
    }
  }, [zoomControlRef])

  return (
    <div className={styles.parent} ref={zoomControlRef}>
      <button onClick={onZoomOut}>
        <img src={ZoomOut} alt={ZoomOut} />
      </button>
      <button onClick={onZoomIn}>
        <img src={ZoomIn} alt={ZoomIn} />
      </button>
    </div>
  )
}

export default ZoomControls
