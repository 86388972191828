import type { FC, MouseEvent } from 'react'
import { memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage, useLocation } from 'react-use'

import collapse from 'assets/icons/collapse.svg'
import expand from 'assets/icons/expand.svg'
import locationPin from 'assets/icons/location-pin-red.svg'

import { SuffixSelect } from 'common/components/Select'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { LOCALE_STORAGE_KEYS } from 'common/constants/localeStorageConstants'
import { useAppDispatch } from 'common/hooks/redux'
import { useSites } from 'common/hooks/useSites'
import { UtilService } from 'common/services/utilService'
import { useSetDefaultSiteMutation } from 'common/store/api/rootApi'

import { CartIcon, TOGGLE_IS_OPEN_CART_DETAILS, useCartManager } from 'features/Cart'

import styles from './cartWidget.module.scss'

export const CartWidget: FC = memo(() => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { cartAmount } = useCartManager()
  const { sites, isFetchingSites, selectedSite } = useSites()

  const [changeDefaultSite] = useSetDefaultSiteMutation()
  const [cartWidgetCollapsed, setCartWidgetCollapsed] = useLocalStorage<boolean>(
    LOCALE_STORAGE_KEYS.CART_WIDGET_COLLAPSED,
    false,
  )

  const toggleOpenCart = useCallback(() => {
    dispatch(TOGGLE_IS_OPEN_CART_DETAILS())
  }, [])

  const toggleCollapse = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      setCartWidgetCollapsed(!cartWidgetCollapsed)
    },
    [cartWidgetCollapsed],
  )

  const changeSiteHandler = useCallback(
    (code: string) => {
      changeDefaultSite({ site_code: code })
        .unwrap()
        .then(() => {
          const currentPath = location.pathname

          const newPath = currentPath.replace(selectedSite?.code, code)

          navigate(newPath)
        })
    },
    [selectedSite],
  )

  return (
    <div
      className={!cartWidgetCollapsed ? styles.widgetContainer : styles.widgetContainerCollapsed}>
      {cartWidgetCollapsed ? (
        <div className={styles.widgetCartContainerCollapsed} onClick={toggleOpenCart}>
          <CartIcon />
          <div className={styles.widgetTogglerExpanded} onClick={toggleCollapse}>
            <img src={expand} alt={ALT_CONSTANTS.ACTION_ICON} />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.widgetTogglerCollapsed} onClick={toggleCollapse}>
            <img src={collapse} alt={ALT_CONSTANTS.ACTION_ICON} />
          </div>
          <div className={styles.widgetCartContainer} onClick={toggleOpenCart}>
            <SuffixSelect
              isLoading={isFetchingSites}
              icon={locationPin}
              listOptions={sites}
              value={selectedSite?.code}
              onChange={changeSiteHandler}
              customStyles={{ borderRadius: '12px', marginBottom: '7px' }}
            />

            <div className={styles.widgetAmount}>
              <p>{UtilService.numberToDollar(cartAmount)}</p>
              <p className={styles.widgetCart}>
                <CartIcon isEmbedded />
                <span>{'Cart ->'}</span>
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  )
})
