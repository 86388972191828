import type { FC, JSX } from 'react'
import { useCallback } from 'react'

import { useAuth } from 'app/providers'

import { Show } from 'common/components/Show/Show'
import ThumbnailsSkeleton from 'common/components/ThumbnailsSkeleton/ThumbnailsSkeleton'
import { BOOKING_STATUS_CODE } from 'common/constants/bookingConstants'
import useNavigateParams from 'common/hooks/useNavigateParams'

import type { IBooking } from 'features/Booking'
import {
  BOOKING_PARAMS,
  getBookingTypeFromCode,
} from 'features/Booking/constants/booking.constants'
import { BookingThumbnail } from 'features/Home/Dashboard/User/ActiveBookings/BookingThumbnail/BookingThumbnail'
import { PromotionRoutes } from 'features/Promotions/constants/promotionsRoutes'

import styles from './bookingThumbnails.module.scss'
import type { BookingThumbnailsProps } from './bookingThumbnails.types'
import { PATH_SEGMENT } from 'routes/pathSegments'

export const BookingThumbnails: FC<BookingThumbnailsProps> = ({ bookings, isLoading }) => {
  const navigate = useNavigateParams()
  const { isDoctor } = useAuth()

  const navigateToBookingHandler = useCallback((booking: IBooking) => {
    const { database_connection, id, is_promotion, status_code, lease_type_code } = booking

    if (is_promotion) {
      const mainPath =
        status_code === BOOKING_STATUS_CODE.WAITING_TO_SIGN
          ? PromotionRoutes.BookingContracts
          : PromotionRoutes.Details

      return navigate(`${database_connection}/promotions/${id}/${mainPath}`)
    }

    navigate(`${PATH_SEGMENT.BOOKING}/${id}`, {
      [BOOKING_PARAMS.TYPE]: getBookingTypeFromCode(lease_type_code),
    })
  }, [])

  return (
    <div className={styles.thumbnailsContainer}>
      <Show
        when={!isLoading}
        fallback={<ThumbnailsSkeleton total={4} rows={2} maxWidth={417} imgHeight={150} />}>
        {bookings?.map(
          (booking: IBooking, i): JSX.Element => (
            <BookingThumbnail
              booking={booking}
              key={`${booking.id}_${i}`}
              withPrice={isDoctor}
              navigateToBookingHandler={navigateToBookingHandler}
            />
          ),
        )}
      </Show>
    </div>
  )
}
