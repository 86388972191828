import { Checkbox } from 'antd'
import type { Dayjs } from 'dayjs'
import validator from 'validator'

import { TagStatus } from 'common/components/TagStatus/TagStatus'
import { TABLE_COLUMN_TYPES } from 'common/constants/tableConstants'
import type { IColumn } from 'common/interfaces/ITable'
import type { TAG_STATUS } from 'common/types/tagStatusType'

export const ACTIVITY_TABLE_COLUMNS = [
  {
    title: 'Patient',
    dataIndex: 'patient',
    key: 'patient',
    type: 'text',
    editable: false,
    sortOrder: false,
    filtered: false,
    sorter: () => {},
  },
  {
    title: 'Schedule time',
    dataIndex: 'scheduleTime',
    key: 'scheduleTime',
    type: 'text',
    editable: false,
    sorter: () => {},
  },
  {
    title: 'Schedule Room',
    dataIndex: 'scheduleRoom',
    key: 'scheduleRoom',
    type: 'text',
    editable: false,
    render: (value: string) => <TagStatus status={value as TAG_STATUS} />,
    sorter: () => {},
  },
  {
    title: 'Real time position',
    dataIndex: 'realTimePosition',
    key: 'realTimePosition',
    type: 'text',
    editable: false,
    render: (value: string) => <TagStatus status={value as TAG_STATUS} />,
    sorter: () => {},
  },
]

export const PATIENTS_TABLE_COLUMNS: IColumn[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    inputType: TABLE_COLUMN_TYPES.AUTOCOMPLETE,
    editable: true,
    sorter: () => {},
    render: (_, data) => data?.patient?.name,
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    width: 130,
    inputType: TABLE_COLUMN_TYPES.TEXT,
    editable: true,
    render: (_, data) => data?.patient?.phone,
    isValidField: (value: string) => validator.isMobilePhone(value, ['ro-RO', 'en-US']),
  },
  {
    title: 'Room',
    dataIndex: 'room',
    key: 'room',
    inputType: TABLE_COLUMN_TYPES.SELECT,
    editable: true,
    render: (_, data) => data?.room?.label,
  },
  {
    title: 'Appointment time',
    dataIndex: 'appointment',
    key: 'appointment',
    inputType: TABLE_COLUMN_TYPES.TIME_PICKER_SLOTS,
    editable: true,
    render: (_, data) => {
      if (data?.start_time) {
        const [startHour, startMinute] = data?.start_time?.split(':')
        const [endHour, endMinute] = data?.end_time?.split(':')
        return `${startHour}:${startMinute}AM - ${endHour}:${endMinute}AM`
      }
      return null
    },
    isValidField: ([start, end]: [Dayjs, Dayjs]) => !!start && !!end,
  },

  {
    title: 'Access code',
    dataIndex: 'access_code',
    key: 'access_code',
    className: 'table-col-min-width',
    render: (data) => {
      if (data) return data
      return null
    },
  },
  {
    title: 'Last notification',
    dataIndex: 'last_notification',
    key: 'last_notification',
    sorter: () => {},
  },
  {
    title: 'Bypass temp. check',
    dataIndex: 'temperature_bypass',
    key: 'temperature_bypass',
    editable: true,
    inputType: TABLE_COLUMN_TYPES.CHECKBOX,
    render: (value: number) => <Checkbox checked={Boolean(value)} disabled />,
  },
]
