import type { FC } from 'react'

import classNames from 'classnames'
import parse from 'html-react-parser'

import type { IBookingNotification } from 'features/Info/interfaces/IInfo'

import styles from './infoNotification.module.scss'

interface IProps {
  notification: IBookingNotification
  index: number
}

export const InfoNotification: FC<IProps> = ({ notification, index }) => {
  const highlightCode = (text: string): string => {
    const regex = /(?:#|\*)\s*([^ ]+)/
    const match = text.match(regex)

    if (match && match[1]) {
      const code = match[1]
      const highlightedText = text.replace(regex, `<span style="color: #CB7D61;">#${code}</span>`)
      return highlightedText
    } else {
      return text
    }
  }
  return (
    <div
      className={classNames(
        styles.parent,
        { [styles.parentFirst]: index === 0 },
        { [styles.parentOthers]: index > 0 },
      )}
      key={index}>
      <div>
        <p className={styles.parentTitle}>{notification.title}</p>
        <p className={styles.parentDescription}>{parse(highlightCode(notification.description))}</p>
      </div>
      <div>
        <p className={styles.parentDescription}>{notification.created_at}</p>
      </div>
    </div>
  )
}
