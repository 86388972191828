import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { HTTP_METHODS } from 'common/constants/httpMethodsConstants'
import type { ICartContent, ICartOptionContent } from 'common/interfaces/ICartContent'
import { baseQuery } from 'common/store/api/fetchBaseQuery'
import { REDUCERS } from 'common/store/reducerConstants'

import type { CheckoutDTO, ICartOptionData } from 'features/Cart/interfaces/ICart'
import type { IInfoLease } from 'features/Info/interfaces/IInfo'
import type { IProviderCondition } from 'features/Provider'

export const cartApi = createApi({
  reducerPath: REDUCERS.CART,
  tagTypes: ['Cart'],
  baseQuery: baseQuery(),
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchContract: build.query<IProviderCondition, void>({
      query: () => ({
        url: '/contract-template',
      }),
    }),

    fetchCarts: build.query<Record<number, ICartOptionContent>, void>({
      query: () => ({
        url: '/carts',
      }),
      providesTags: ['Carts'],
    }),
    fetchCart: build.query<ICartContent, void>({
      query: () => ({
        url: '/cart',
      }),
      providesTags: ['Cart'],
    }),
    deleteCart: build.mutation<undefined, void>({
      query: () => ({
        url: '/cart',
        method: HTTP_METHODS.DELETE,
      }),
      invalidatesTags: ['Cart'],
    }),
    updateCart: build.mutation({
      query: (data) => ({
        data,
        url: '/cart',
        method: HTTP_METHODS.PUT,
      }),
    }),
    createCart: build.mutation({
      query: ({ data, connection }) => ({
        data,
        url: '/cart',
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': connection },
      }),
    }),
    checkoutCart: build.mutation({
      query: (data) => ({
        data,
        url: '/checkout',
        method: HTTP_METHODS.POST,
      }),
      transformResponse: ({ item }: { item: IInfoLease }) => item,
      invalidatesTags: ['Levels'],
    }),
    // other bookings
    createSingleCart: build.mutation<
      ICartOptionContent,
      {
        data: Partial<ICartOptionData>
        request_url_id: number
        connection: string
      }
    >({
      query: ({ data, connection, request_url_id }) => ({
        data,
        url: `/booking/${request_url_id}/cart`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': connection },
      }),
    }),
    fetchSingleCart: build.query<ICartOptionContent, number>({
      query: (request_url_id) => ({
        url: `/booking/${request_url_id}}/cart`,
      }),
    }),
    deleteSingleCart: build.mutation<undefined, number>({
      query: (request_url_id) => ({
        url: `/booking/${request_url_id}/cart`,
        method: HTTP_METHODS.DELETE,
      }),
    }),
    fetchContractTemplate: build.query<IProviderCondition, number>({
      query: (request_url_id) => ({
        url: `/booking/${request_url_id}/contract-template`,
      }),
    }),

    checkoutSingleCart: build.mutation<
      IInfoLease,
      {
        data: CheckoutDTO
        request_url_id: number
      }
    >({
      query: ({ data, request_url_id }) => ({
        data,
        url: `/booking/${request_url_id}/checkout`,
        method: HTTP_METHODS.POST,
      }),
      transformResponse: ({ item }: { item: IInfoLease }) => item,
      invalidatesTags: ['Levels'],
    }),
    fetchPostalFormContract: build.query<IProviderCondition, number>({
      query: (request_url_id) => ({
        url: `/booking/${request_url_id}/postal-form-template`,
      }),
    }),

    updateSingleCart: build.mutation<
      ICartOptionContent,
      {
        data: Partial<ICartOptionData>
        request_url_id: number
      }
    >({
      query: ({ data, request_url_id }) => ({
        data,
        url: `/booking/${request_url_id}/cart`,
        method: HTTP_METHODS.PUT,
      }),
    }),
  }),
})

export const {
  // common
  useFetchCartsQuery,
  useLazyFetchCartsQuery,
  // main lease
  useFetchContractQuery,
  useLazyFetchContractQuery,
  useLazyFetchCartQuery,
  useDeleteCartMutation,
  useUpdateCartMutation,
  useCreateCartMutation,
  useCheckoutCartMutation,
  // other lease (mailbox, storage etc)
  useCheckoutSingleCartMutation,
  useCreateSingleCartMutation,
  useDeleteSingleCartMutation,
  useFetchSingleCartQuery,
  useLazyFetchSingleCartQuery,
  useUpdateSingleCartMutation,
  useFetchContractTemplateQuery,
  useLazyFetchContractTemplateQuery,
  useLazyFetchPostalFormContractQuery,
  useFetchPostalFormContractQuery,
} = cartApi
