import type { FC, JSX } from 'react'

import ThumbnailsSkeleton from 'common/components/ThumbnailsSkeleton/ThumbnailsSkeleton'

import { InfoShopCard } from 'features/Info/components/InfoShopCard/InfoShopCard'
import type { IInfoShopItem } from 'features/Info/interfaces/IInfoShop'

import styles from './infoShopCardList.module.scss'

interface IProps {
  isLoading: boolean
  shopItems: IInfoShopItem[]
  handleSelectShopItem: (shopItem: IInfoShopItem) => void
}

export const InfoShopCardList: FC<IProps> = ({ shopItems, isLoading, handleSelectShopItem }) => {
  if (isLoading)
    return (
      <div className={styles.parent}>
        <ThumbnailsSkeleton
          rows={3}
          align='center'
          maxWidth={266}
          minWidth={266}
          border={false}
          imgHeight={173}
        />
      </div>
    )
  return (
    <div className={styles.parent}>
      {shopItems.map(
        (shopItem: IInfoShopItem, index: number): JSX.Element => (
          <InfoShopCard
            key={index}
            shopItem={shopItem}
            handleSelectShopItem={handleSelectShopItem}
          />
        ),
      )}
    </div>
  )
}
