import { Skeleton } from 'antd'

import { ArrayElements } from 'common/components/ArrayElements/ArrayElements'

import styles from './InfoScheduleSkeleton.module.scss'

const InfoScheduleSkeleton = () => {
  return (
    <div className={styles.parent}>
      <Skeleton.Input active block className={styles.parentSkeleton} size='small' />

      <div className={styles.parentHeader}>
        <ArrayElements total={3}>
          <div className={styles.parentHeaderElement}>
            <ArrayElements total={3}>
              <Skeleton.Input active block className={styles.parentSkeleton} />
            </ArrayElements>
          </div>
        </ArrayElements>
      </div>
      <div className={styles.parentHeader}>
        <ArrayElements total={3}>
          <div className={styles.parentHeaderElement}>
            <ArrayElements total={3}>
              <Skeleton active />
            </ArrayElements>
          </div>
        </ArrayElements>
      </div>
    </div>
  )
}

export default InfoScheduleSkeleton
