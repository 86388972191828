import { isSameDay } from 'date-fns'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'

import { formatSortersAsParams } from 'common/components/Table/table.util'
import type { IOption } from 'common/interfaces/IOption'
import type { INavigationTab } from 'common/interfaces/ISidebarTab'
import type { TableParamsType } from 'common/interfaces/ITable'

const SIZE_IN_BYTES = 1024

export const UtilService = {
  getTimeZone: (): string => Intl.DateTimeFormat().resolvedOptions().timeZone,
  getFilledArray: (len: number) => Array(len).fill(0),

  capitalizeFirstLetter: (str: string): string => str.charAt(0).toUpperCase() + str.slice(1),

  replaceLineToSpace: (str: string): string => str.toLowerCase().replace(' ', '-'),

  replaceSpaceToLine: (str: string): string => str.replace('-', ' '),

  replaceSpaceToUnderscore: (str: string): string => str.toLowerCase().replace(/\s+/g, '_'),

  capitalizeString: (str: string): string =>
    str
      .split(' ')
      .map((word: string): string => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '),

  clearObjectByEmptyValues: <T extends object>(obj: T): T => {
    const copyObj: T = JSON.parse(JSON.stringify(obj))
    for (let key in copyObj) if (String(copyObj[key]) === '') delete copyObj[key]
    return copyObj
  },

  getValidUrl: (str: string): string => {
    if (/[^\w\s]/gi.test(str)) {
      return str
        .replaceAll(' ', '')
        .replaceAll(/[^\w\s]/gi, ' ')
        .replaceAll(' ', '-')
        .toLowerCase()
    }
    return str.replaceAll(' ', '-').toLowerCase()
  },

  optionsToNavigationTabs: (options: IOption[]): INavigationTab[] =>
    Array.isArray(options)
      ? options.map(
          (option: IOption): INavigationTab => ({
            title: option.name,
            path: String(option.code),
          }),
        )
      : [],

  arrDeepEqual: (firstObject: object, secondObject: object): boolean =>
    JSON.stringify(firstObject) === JSON.stringify(secondObject),

  parseCountry: (country: string): string => country.split(',').at(1).trim(),

  deepEqual(
    object1: object,
    object2: object,
    excludeKeys: string[] = [],
    skipLengthCheck = false,
  ): boolean {
    if (!object1 || !object2) return false

    const keys1: string[] = Object.keys(object1)
    const keys2: string[] = Object.keys(object2)

    if (keys1.length !== keys2.length && skipLengthCheck) return false

    const isObject = (object: object) => object != null && typeof object === 'object'

    for (const key of keys1) {
      if (excludeKeys.includes(key)) continue

      const val1 = object1[key as keyof object]
      const val2 = object2[key as keyof object]
      const areObjects = isObject(val1) && isObject(val2)
      if (
        (areObjects && !UtilService.deepEqual(val1, val2, excludeKeys)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false
      }
    }
    return true
  },

  fileSizeInMB: (size: number): number =>
    Number((size / (SIZE_IN_BYTES * SIZE_IN_BYTES)).toFixed(2)),

  fileSizeFormatted: (size: number): string => {
    const sizeInMB = size / (SIZE_IN_BYTES * SIZE_IN_BYTES)
    if (sizeInMB < 1) {
      const sizeInKB = (size / SIZE_IN_BYTES).toFixed(2)
      return `${sizeInKB} KB`
    } else {
      return `${sizeInMB.toFixed(2)} MB`
    }
  },

  numberToDollar: (price: number): string => {
    const getFormattedPrice = (num: number): string =>
      num.toLocaleString('en-US', { style: 'currency', currency: 'USD' })

    if (!price) return getFormattedPrice(0)
    return getFormattedPrice(price)
  },

  sortTabPanelDays: (days: IOption[]): IOption[] => {
    const copyDays = structuredClone(days)
    copyDays.forEach((day: IOption, index: number) => {
      if (isSameDay(new Date(day.name), new Date()))
        copyDays.unshift(copyDays.splice(index, 1).at(0))
    })
    return copyDays
  },

  sortNumberString: (a: number | string, b: number | string): number => {
    const isANumber: boolean = typeof a === 'number'
    const isBNumber: boolean = typeof b === 'number'

    if (isANumber && isBNumber) return (a as number) - (b as number)
    else if (!isANumber && isBNumber) return 1
    else if (isANumber && !isBNumber) return -1
    else return 0
  },

  getInitials: (firstName: string, lastName: string): string => {
    if (firstName && lastName) return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
    return ''
  },

  downloadPdfFromResponse: (data: string, fileName: string): void => {
    const url: string = window.URL.createObjectURL(new Blob([data]))
    const link: HTMLAnchorElement = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.pdf`)
    window.document.body.appendChild(link)
    link.click()
  },

  safeParseJSON: <T>(json: string, defaultValue: T): T => {
    try {
      return JSON.parse(json) as T
    } catch (e) {
      return defaultValue
    }
  },
  objectHasObject: (obj: object, target: object): boolean => {
    return JSON.stringify(obj).includes(JSON.stringify(target))
  },

  enumHasValue: <T extends Record<string, string>>(value: string, enumObject: T): boolean => {
    return Object.values(enumObject).includes(value)
  },

  structuredClone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj))
  },

  withPaginationParams: (reqUrl: string, params: TableParamsType): string => {
    let url = `${reqUrl}?per_page=${params.pageSize}&page=${params.current}`

    if (params?.sorters && Object.keys(params.sorters).length > 0) {
      const sortParam = formatSortersAsParams(params.sorters)
      url += `&sort=${sortParam}`
    }
    if (params.search) url += `&search=${encodeURIComponent(params.search)}`

    return url
  },

  textToCamelCase: (text: string): string => {
    return text.toLowerCase().replace(/(\W|_)([a-z])/g, (g) => g[1].toUpperCase())
  },
  partition<T>(
    array: T[] = [],
    filter: (value: T, index: number, array: T[]) => boolean,
  ): [T[], T[]] {
    const pass: T[] = []
    const fail: T[] = []
    array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e))
    return [pass, fail]
  },
  formatTableDate: (init_date: string | null): string => {
    if (!init_date) return 'N/A'
    const date = dayjs(init_date)

    if (date?.isValid()) {
      return date.format('MM/DD/YYYY h:mm A')
    }
    return 'N/A'
  },

  isSameOrBefore: (date1: Dayjs, date2: Dayjs): boolean => {
    return date1.isSame(date2) || date1.isBefore(date2)
  },

  generateDates: (startDate: string, endDate: string): string[] => {
    const start = dayjs(startDate)
    const end = dayjs(endDate)
    const dates: string[] = []

    const isSameOrBefore = (date1: Dayjs, date2: Dayjs): boolean => {
      return date1.isSame(date2) || date1.isBefore(date2)
    }

    for (let m = start; isSameOrBefore(m, end); m = m.add(1, 'day')) {
      dates.push(m.format('YYYY-MM-DD'))
    }

    return dates
  },

  generateDatesWithValues: (
    startDate: string,
    endDate: string,
    value: number = 1,
    includeEndDate: boolean = true,
  ): Record<string, number> => {
    const start = dayjs(startDate)
    const end = dayjs(endDate)
    const datesWithValues: Record<string, number> = {}

    const isSameOrBefore = (date1: Dayjs, date2: Dayjs): boolean => {
      return date1.isSame(date2) || date1.isBefore(date2)
    }

    for (
      let m = start;
      includeEndDate ? isSameOrBefore(m, end) : m.isBefore(end);
      m = m.add(1, 'day')
    ) {
      datesWithValues[m.format('YYYY-MM-DD')] = value
    }

    return datesWithValues
  },
}
