import type { FC } from 'react'
import { memo, useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { PATH_SEGMENT } from 'routes/pathSegments'
import { PathUtils } from 'routes/routes.utils'

import { useAuth } from 'app/providers'

import { Spinner } from 'common/components/Spinner'
import { useSites } from 'common/hooks/useSites'

export const SiteRedirect: FC = memo(() => {
  const navigate = useNavigate()
  const { user } = useAuth()

  const { sites } = useSites()

  const selectedSite = useMemo(() => {
    return sites?.find((site) => site.favorite)?.code
  }, [sites])

  const redirectToSite = useCallback(() => {
    if (selectedSite) {
      navigate(PathUtils.getHome(selectedSite), { replace: true })
    } else {
      navigate(PATH_SEGMENT.ROOT, { replace: true })
    }
  }, [navigate, selectedSite])

  useEffect(() => {
    if (user) {
      redirectToSite()
    }
  }, [user, redirectToSite])

  return (
    <div className='whole-page-wrapper'>
      <Spinner size='large' isLoading={true} />
    </div>
  )
})
