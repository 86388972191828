import type { FC } from 'react'
import { memo, useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import { PATH_SEGMENT } from 'routes/pathSegments'
import { generateRouter } from 'routes/routes.utils'

import {
  AuthLogin,
  AuthRegister,
  AuthResetPassword,
  AuthSetNewPassword,
  AuthValidateEmail,
} from 'features/Auth'
import { AuthTermsAndConditions } from 'features/Auth/components/AuthTermsAndConditions/AuthTermsAndConditions'

export const Auth: FC = memo(() => {
  const routesConfig = useMemo(
    () => ({
      [PATH_SEGMENT.LOGIN]: <AuthLogin />,
      [PATH_SEGMENT.REGISTER]: <AuthRegister />,
      [PATH_SEGMENT.VALIDATE_EMAIL]: <AuthValidateEmail />,
      [PATH_SEGMENT.RESET_PASSWORD]: <AuthResetPassword />,
      [PATH_SEGMENT.SET_NEW_PASSWORD]: <AuthSetNewPassword />,
      [PATH_SEGMENT.TERMS_AND_CONDITIONS]: <AuthTermsAndConditions />,
      [PATH_SEGMENT.ROOT]: <Navigate to={PATH_SEGMENT.LOGIN} replace />,
    }),
    [],
  )

  return generateRouter(routesConfig)
})
