import type { FC } from 'react'
import { memo, useCallback, useMemo, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import classNames from 'classnames'
import { SidebarItem } from 'layout/Sidebar/SidebarItem/SidebarItem'
import { PATH_SEGMENT } from 'routes/pathSegments'

import { useScreenWidth } from 'app/hooks/useScreenWidth'
import { useAuth } from 'app/providers'

import { UtilService } from 'common/services/utilService'

import { sidebarConfig } from './config'
import styles from './sidebar.module.scss'
import type { SidebarProps } from './sidebar.types'

export const Sidebar: FC<SidebarProps> = memo(({ isSidebarVisible, toggleIsSidebarVisible }) => {
  const [hoveredNavigation, setHoveredNavigation] = useState<string | null>(null)

  const navigate = useNavigate()
  const location = useLocation()

  const { isMobile } = useScreenWidth()
  const { isSuperAdminAsDoctor, user, isDoctor, hasCompletedDocuments } = useAuth()

  const isUnconfirmedDoctor = useMemo(
    () => isDoctor && !hasCompletedDocuments,
    [isDoctor, hasCompletedDocuments],
  )

  const isSuperDoctor = useMemo(() => user?.is_super_doctor || isSuperAdminAsDoctor, [user])

  const isGuest = useMemo(() => !user, [user])

  const sidebarItems = useMemo(() => {
    return sidebarConfig.getItems(isGuest, isUnconfirmedDoctor, isSuperDoctor)
  }, [])

  const handleChangeHoveredIcon = useCallback(
    (newHoveredIcon: string) => {
      if (hoveredNavigation !== newHoveredIcon) {
        setHoveredNavigation(newHoveredIcon)
      }
    },
    [hoveredNavigation],
  )

  const isActiveRoute = useCallback(
    (route: string) => {
      const pathSegments = location.pathname.split('/').map((segment) => segment.toLowerCase())
      return pathSegments[2].includes(route)
    },
    [location.pathname],
  )

  const sidebarItemClickHandler = useCallback(
    (route: string) => () => {
      if (isGuest || isUnconfirmedDoctor) {
        return
      }
      if (isSidebarVisible && isMobile) {
        toggleIsSidebarVisible?.()
      }
      navigate(route)
    },
    [isGuest, isUnconfirmedDoctor, isSidebarVisible, isMobile],
  )

  return (
    <div className={styles.sidebar} data-guest={!user}>
      <div className={styles.sidebarNavigation}>
        {sidebarItems.map(({ title, route, icon }, index: number) => (
          <NavLink
            key={index}
            data-cy={`sidebar-btn-${UtilService.replaceSpaceToUnderscore(title)}`}
            onClick={sidebarItemClickHandler(route)}
            data-disabled={isGuest || isUnconfirmedDoctor}
            onMouseLeave={() => setHoveredNavigation(null)}
            onMouseOver={() => handleChangeHoveredIcon(title)}
            className={classNames(styles.navigationItem, styles.activeNavigationItem, {
              [styles.activeAdminItem]: route === PATH_SEGMENT.ADMIN,
              [styles.disabledRoute]: isGuest || isUnconfirmedDoctor,
            })}
            to={route}>
            <SidebarItem
              title={title}
              icon={icon}
              isActive={isActiveRoute(route)}
              isHovered={hoveredNavigation === title}
            />
          </NavLink>
        ))}
      </div>
    </div>
  )
})
