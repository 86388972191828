import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'app/providers'

import Popup from 'common/components/Popup'
import { BUTTON_CONSTANTS } from 'common/constants/buttonConstants'
import { POPUP_CONSTANTS } from 'common/constants/popupConstants'

import { TOGGLE_IS_CREATE_NEW_LEASE } from 'features/Booking/state/slices/leaseSlice'
import { PATH_SEGMENT } from 'routes/pathSegments'

const useUnconfirmedPopup = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isDoctor, hasCompletedDocuments } = useAuth()
  const isUnconfirmedDoctor = isDoctor && !hasCompletedDocuments

  const showPopup = (callback?: () => void) => {
    Popup.showInfo(
      {
        title: isUnconfirmedDoctor
          ? POPUP_CONSTANTS.DOCTOR_LIMITED_LEASE_TITLE
          : POPUP_CONSTANTS.GUEST_TITLE,
        message: isUnconfirmedDoctor
          ? POPUP_CONSTANTS.DOCTOR_LIMITED_LEASE_DESCRIPTION
          : POPUP_CONSTANTS.GUEST_ADD_CART,
        mainButtonText: isUnconfirmedDoctor
          ? BUTTON_CONSTANTS.ACCOUNT_DETAILS
          : BUTTON_CONSTANTS.SIGN_UP,
        secondButtonText: isUnconfirmedDoctor ? BUTTON_CONSTANTS.CANCEL : BUTTON_CONSTANTS.LOGIN,
      },
      () => {
        navigate(isUnconfirmedDoctor ? PATH_SEGMENT.VALIDATE_IDENTITY : PATH_SEGMENT.REGISTER)
        dispatch(TOGGLE_IS_CREATE_NEW_LEASE())
        callback?.()
      },
      () => {
        if (isUnconfirmedDoctor) return
        navigate(PATH_SEGMENT.LOGIN)
        dispatch(TOGGLE_IS_CREATE_NEW_LEASE())
        callback?.()
      },
    )
  }

  return { showPopup }
}

export default useUnconfirmedPopup
