import type { FC } from 'react'
import { memo, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { Show } from 'common/components/Show/Show'
import { useAppDispatch } from 'common/hooks/redux'
import type { IRequestResponse } from 'common/interfaces/IRequestResponse'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'

import { InfoShopCardList } from 'features/Info/components/InfoShopCardList/InfoShopCardList'
import { INFO_CONSTANTS } from 'features/Info/constants/infoConstants'
import type { IInfoShopItem } from 'features/Info/interfaces/IInfoShop'
import { useFetchLeaseShopQuery, useFetchLeaseStatusQuery } from 'features/Info/state/api/infoApi'
import type { INFO_LEASE_STATUS_TYPE } from 'features/Info/types/infoLeaseStatusType'
import { OptionOverlay, SET_OVERLAY_OPTION, TOGGLE_IS_OPEN_OPTION_OVERLAY } from 'features/Option'

import styles from './shop.module.scss'
import { PATH_SEGMENT } from 'routes/pathSegments'
import type { ShopProps } from './shop.types'

export const Shop: FC<ShopProps> = memo(({ bookingApiProps }) => {
  const dispatch = useAppDispatch()

  const {
    data: shopItems,
    isLoading,
    isError,
    refetch: refetchShopItems,
  }: IRequestResponse<IInfoShopItem[]> = useFetchLeaseShopQuery(bookingApiProps)

  useEffect(() => {
    refetchShopItems()
  }, [])

  const { data: leaseStatus }: IRequestResponse<INFO_LEASE_STATUS_TYPE> =
    useFetchLeaseStatusQuery(bookingApiProps)

  const handleAddToCartShopItem = (shopItem: ISiteRoom): void => {
    dispatch(SET_OVERLAY_OPTION(shopItem))
    dispatch(TOGGLE_IS_OPEN_OPTION_OVERLAY())
  }
  if (isError) return <Navigate to={PATH_SEGMENT.LOGIN} />

  return (
    <div className={styles.parent}>
      <div className={styles.parentInfo}>
        <h1>{INFO_CONSTANTS.SHOP}</h1>
        <Show when={leaseStatus}>
          <span className={styles.parentInfoStatus}>{leaseStatus}</span>
        </Show>
      </div>
      <p className={styles.parentInfoDescription}>{INFO_CONSTANTS.SHOP_DESCRIPTION}</p>
      <InfoShopCardList
        isLoading={isLoading}
        shopItems={shopItems || []}
        handleSelectShopItem={handleAddToCartShopItem}
      />
      <OptionOverlay />
    </div>
  )
})
