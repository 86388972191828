import type { ReactElement } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import FallbackComponent from 'common/components/FallbackComponent/FallbackComponent'

import { PATH_SEGMENT } from './pathSegments'

export type RoutesConfigType = Record<string, ReactElement>

export const generateRoute = (
  path: string,
  element: ReactElement,
  routeProps = {},
  hasErrorBoundary = true,
) => {
  const resolvedElement = (
    <FallbackComponent hasErrorBoundary={hasErrorBoundary}>{element}</FallbackComponent>
  )

  return <Route path={path} key={path} element={resolvedElement} {...routeProps} />
}

export const generateRouter = (reutesConfig: RoutesConfigType, includeErrorRoute = true) => {
  const routesJSX = Object.entries(reutesConfig).map(([path, element]: [string, ReactElement]) =>
    generateRoute(path, element),
  )

  includeErrorRoute && routesJSX.push(PathUtils.errorRoute)

  return (
    <div className='container'>
      <Routes>{routesJSX}</Routes>
    </div>
  )
}

export const PathUtils = {
  _errorRoute: <></>,
  get errorRoute() {
    this._errorRoute = this._errorRoute || this.getErrorRoute()
    return this._errorRoute
  },

  getHome(siteId: string): string {
    return `/${siteId}/${PATH_SEGMENT.HOME}`
  },

  getAuth(): string {
    return [PATH_SEGMENT.ROOT, PATH_SEGMENT.AUTH].join('')
  },

  getLogin(): string {
    return [this.getAuth(), PATH_SEGMENT.LOGIN].join('/')
  },

  getRegister(): string {
    return [this.getAuth(), PATH_SEGMENT.REGISTER].join('/')
  },

  getTermsAndConditions(): string {
    return [this.getAuth(), PATH_SEGMENT.TERMS_AND_CONDITIONS].join('/')
  },

  getUserTermsAndConditions(): string {
    return [PATH_SEGMENT.PROVIDER, PATH_SEGMENT.TERMS_AND_CONDITIONS].join('/')
  },

  getAdmin(): string {
    return [PATH_SEGMENT.ROOT, PATH_SEGMENT.ADMIN].join('')
  },

  getGuest(): string {
    return [PATH_SEGMENT.ROOT, PATH_SEGMENT.GUEST].join('')
  },

  getError(): string {
    return PATH_SEGMENT.ERROR
  },

  getDefaultErrorNavigation() {
    return <Navigate to={this.getError()} />
  },

  getErrorRoute() {
    return <Route path='*' element={this.getDefaultErrorNavigation()} />
  },
}
