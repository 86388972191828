import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

import { Calendar } from 'common/components/Calendar/Calendar'
import { UtilService } from 'common/services/utilService'

import { useGetPromotionCalendarQuery } from 'features/Promotions/state/api/promotionsApi'

dayjs.extend(isBetween)

type Props = {
  currentDate: dayjs.Dayjs
  startDate: string
  endDate: string
  handleChangeDate: (newDate: string) => void
}

const PromotionMonthView = ({ currentDate, startDate, endDate, handleChangeDate }: Props) => {
  const params = useParams()

  const [selectedMonth, setSelectedMonth] = useState(currentDate.format('YYYY-MM'))

  const leaseConnection = {
    leaseId: params?.leaseId,
    connection: params?.connection,
  }

  const { data, isLoading, isFetching } = useGetPromotionCalendarQuery({
    ...leaseConnection,
    date: selectedMonth,
  })

  const handleChangeMonth = (_: number, newMonth: string) => {
    const newMonthDayjs = dayjs(newMonth, 'YYYY-MM')
    const startDayjs = dayjs(startDate)
    const endDayjs = dayjs(endDate)

    if (newMonthDayjs.isBetween(startDayjs, endDayjs, 'month', '[]')) {
      setSelectedMonth(newMonth)
    }
  }

  useEffect(() => {
    setSelectedMonth(currentDate.format('YYYY-MM'))
  }, [currentDate])

  const days = UtilService.generateDatesWithValues(startDate, endDate, 1, false)

  const selectedDays = Object.keys(data ?? {}).filter((key) => data[key])

  return (
    <Calendar
      isStrictDisable
      days={days}
      withFakeRerender
      showCurrentDay={false}
      isLoading={isLoading || isFetching}
      handleChangeDate={handleChangeDate}
      handleChangeMonth={handleChangeMonth}
      selectedDays={selectedDays}
      currentDay={currentDate.format('YYYY-MM-DD')}
    />
  )
}

export default PromotionMonthView
