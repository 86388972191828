import type { FC } from 'react'

import { InfoUpload } from 'features/Info/components/InfoUpload/InfoUpload'
import { INFO_CONSTANTS } from 'features/Info/constants/infoConstants'
import type { IInfoLease } from 'features/Info/interfaces/IInfo'

import styles from './infoScreenList.module.scss'

interface IProps {
  isFetching: boolean
  isDisabled: boolean
  booking: IInfoLease
  isRemovingMedia: boolean
  isUploading?: boolean
  handleRemoveMedia: (mediaId: string) => void
  handleAddMedia: (screen: number, file: File) => void
}

export const InfoScreenList: FC<IProps> = ({
  booking,
  isDisabled,
  handleAddMedia,
  isFetching,
  isRemovingMedia,
  isUploading = false,
  handleRemoveMedia,
}) => {
  const { display_1 = [], display_2 = [] } = booking?.media ?? {}
  return (
    <section className={styles.parent}>
      <h3 className={styles.parentTitle}>{INFO_CONSTANTS.MEDIA}</h3>
      <p className={styles.parentDescription}>{INFO_CONSTANTS.MEDIA_DESCRIPTION}</p>
      <div className={styles.parentDocuments}>
        <InfoUpload
          screen={1}
          isDisabled={isDisabled}
          isFetching={isFetching}
          uploadedFiles={display_1}
          handleAddMedia={handleAddMedia}
          isRemovingMedia={isRemovingMedia}
          handleRemoveMedia={handleRemoveMedia}
          isUploading={isUploading}
        />
        <InfoUpload
          screen={2}
          isDisabled={isDisabled}
          isFetching={isFetching}
          uploadedFiles={display_2}
          handleAddMedia={handleAddMedia}
          isRemovingMedia={isRemovingMedia}
          handleRemoveMedia={handleRemoveMedia}
          isUploading={isUploading}
        />
      </div>
    </section>
  )
}
