import { type FC, memo, useEffect, useState } from 'react'

import type { TablePaginationConfig } from 'antd'

import { Table } from 'common/components/Table/Table'
import { DEFAULT_TABLE_PARAMS } from 'common/constants/defaultTableParamsConstants'
import type { ITriggerPagRequest, ITriggerRequest } from 'common/interfaces/IRequestResponse'
import { TableService } from 'common/services/tableService'

import {
  HISTORY_TABLE_COLUMNS,
  SETTINGS_CONSTANTS,
} from 'features/Profile/features/Payment/constants/constants'
import type { ISettingHistory } from 'features/SettingsOld/interfaces/ISettingHistory'
import {
  useLazyBillingSiteHistoryQuery,
  useLazyDownloadInvoiceQuery,
} from 'features/SettingsOld/state/api/settingsApi'

import styles from './billingHistory.module.scss'
import { useSites } from 'common/hooks/useSites'
import type { BillingHistoryProps } from './billingHistory.types'

const { withDownloadPdfForColumn } = TableService

export const BillingHistory: FC<BillingHistoryProps> = memo(({ bookingId }) => {
  const { selectedSite } = useSites()

  const [
    fetchSiteHistory,
    { data: siteHistory, isFetching: isLoadingSiteHistory },
  ]: ITriggerPagRequest<ISettingHistory[]> = useLazyBillingSiteHistoryQuery()

  const [downloadContract, { isLoading: isDownloadingInvoice }]: ITriggerRequest =
    useLazyDownloadInvoiceQuery()

  const [tableParams, setTableParams] = useState<TablePaginationConfig>({
    ...DEFAULT_TABLE_PARAMS,
  })

  useEffect(() => {
    if (siteHistory?.total_items >= 0)
      setTableParams({ ...tableParams, total: siteHistory.total_items })
  }, [siteHistory])

  useEffect(() => {
    if (!selectedSite) {
      return
    }
    fetchSiteHistory({ site: selectedSite.code, tableParams, bookingId })
  }, [tableParams.pageSize, tableParams.current, selectedSite, bookingId])

  return (
    <div className={styles.parent}>
      <h1 className={styles.parentTitle}>{SETTINGS_CONSTANTS.BILLING_HISTORY}</h1>
      <Table
        itemKey='id'
        haveActions={false}
        tableParams={tableParams}
        setTableParams={setTableParams}
        dataSource={siteHistory?.items}
        columns={withDownloadPdfForColumn<ISettingHistory>({
          columnKey: 'invoice_number',
          columns: HISTORY_TABLE_COLUMNS,
          downloadContract: downloadContract,
          tooltipContent: SETTINGS_CONSTANTS.INVOICE,
        })}
        isLoading={isLoadingSiteHistory || isDownloadingInvoice}
      />
    </div>
  )
})
