import React, { memo, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Icon } from '@iconify/react'
import { Menu, type MenuProps } from 'antd'
import { PATH_SEGMENT } from 'routes/pathSegments'

import styles from './menu.module.scss'

type MenuItem = Required<MenuProps>['items'][number]

export const iconStyle = {
  color: 'var(--secondary-100)',
  fontSize: '16px',
}

const getIcon = (iconName: string) => <Icon icon={iconName} style={iconStyle} />

const menuPaths = [
  PATH_SEGMENT.PERSONAL_PROFILE,
  PATH_SEGMENT.NOTIFICATIONS,
  PATH_SEGMENT.CHANGE_PASSWORD,
  PATH_SEGMENT.DEPOSIT,
  PATH_SEGMENT.MAILBOX_PROFILE,
  PATH_SEGMENT.CREDIT_CARDS,
  PATH_SEGMENT.BILLING_HISTORY,
  PATH_SEGMENT.PAYMENTS_HISTORY,
]

export const menuStructure: MenuItem[] = [
  {
    key: 'account',
    label: 'My Account',
    icon: getIcon('ant-design:user-outlined'),
    children: [
      {
        key: PATH_SEGMENT.PERSONAL_PROFILE,
        label: <Link to={PATH_SEGMENT.PERSONAL_PROFILE}>Personal Profile</Link>,
      },
      {
        key: PATH_SEGMENT.NOTIFICATIONS,
        label: <Link to={PATH_SEGMENT.NOTIFICATIONS}>Notifications</Link>,
      },
      {
        key: PATH_SEGMENT.CHANGE_PASSWORD,
        label: <Link to={PATH_SEGMENT.CHANGE_PASSWORD}>Change Password</Link>,
      },
    ],
  },
  {
    key: PATH_SEGMENT.CREDIT_CARDS,
    label: <Link to={PATH_SEGMENT.CREDIT_CARDS}>Credit Cards</Link>,
    icon: getIcon('ph:credit-card-duotone'),
  },
  {
    key: 'finance',
    label: 'Finance',
    icon: getIcon('ant-design:dollar-twotone'),
    children: [
      {
        key: PATH_SEGMENT.BILLING_HISTORY,
        label: <Link to={PATH_SEGMENT.BILLING_HISTORY}>Billing History</Link>,
      },
      {
        key: PATH_SEGMENT.PAYMENTS_HISTORY,
        label: <Link to={PATH_SEGMENT.PAYMENTS_HISTORY}>Payments History</Link>,
      },
      {
        key: PATH_SEGMENT.DEPOSIT,
        label: <Link to={PATH_SEGMENT.DEPOSIT}>Deposit</Link>,
      },
    ],
  },
  {
    key: PATH_SEGMENT.MAILBOX_PROFILE,
    label: <Link to={PATH_SEGMENT.MAILBOX_PROFILE}>Mailbox Profile</Link>,
    icon: getIcon('solar:mailbox-bold-duotone'),
  },
]

export const MenuWidget: React.FC = memo(() => {
  const location = useLocation()

  const selectedKeys = useMemo(() => {
    const pathname = location.pathname.split('/').at(-1)
    const selectedKey = menuPaths.find((segment) => pathname === segment)
    return selectedKey ? [selectedKey] : [PATH_SEGMENT.PERSONAL_PROFILE]
  }, [])

  const openKeys = useMemo(() => {
    const accountsRoutes = [
      PATH_SEGMENT.PERSONAL_PROFILE,
      PATH_SEGMENT.NOTIFICATIONS,
      PATH_SEGMENT.CHANGE_PASSWORD,
    ]
    const financeRoutes = [
      PATH_SEGMENT.BILLING_HISTORY,
      PATH_SEGMENT.PAYMENTS_HISTORY,
      PATH_SEGMENT.DEPOSIT,
    ]
    if (accountsRoutes.includes(selectedKeys[0])) {
      return ['account']
    }
    if (financeRoutes.includes(selectedKeys[0])) {
      return ['finance']
    }
    return []
  }, [selectedKeys])

  return (
    <Menu
      className={styles.container}
      defaultSelectedKeys={selectedKeys}
      defaultOpenKeys={openKeys}
      mode='inline'
      items={menuStructure}
    />
  )
})
