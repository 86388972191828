import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useFetchPromotionsDocumentsQuery } from 'features/Promotions/state/api/promotionsApi'
import { DocumentsTable } from 'features/Home/Book/Booking/Documents/DocumentsTable/DocumentsTable'

const PromotionDocuments = () => {
  const params = useParams()
  const leaseApiProps = useMemo(
    () => ({
      leaseId: params?.leaseId,
      connection: params?.connection,
    }),
    [params],
  )
  const { data, isLoading } = useFetchPromotionsDocumentsQuery(leaseApiProps, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  })
  return (
    <>
      <DocumentsTable data={data} isLoading={isLoading} />
    </>
  )
}

export default PromotionDocuments
