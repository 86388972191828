import type { Middleware, Reducer } from '@reduxjs/toolkit'

import { rootApi } from 'common/store/api/rootApi'

import { adminApi } from 'features/Admin'
import { authApi } from 'features/Auth'
import { bookingApi } from 'features/Booking'
import { cartApi } from 'features/Cart'
import { faqApi } from 'features/Faq'
import { infoApi } from 'features/Info'
import { optionApi } from 'features/Option'
import { patientsApi } from 'features/Patients'
import { promotionsApi } from 'features/Promotions'
import { providerApi } from 'features/Provider'
import { settingsApi } from 'features/SettingsOld/state/api/settingsApi'
import { staffApi } from 'features/Staff'
import { supportApi } from 'features/Support'

type ApiReducerObject = Record<string, Reducer>

export const APP_API_LIST = [
  faqApi,
  authApi,
  rootApi,
  cartApi,
  infoApi,
  bookingApi,
  staffApi,
  bookingApi,
  promotionsApi,
  optionApi,
  supportApi,
  settingsApi,
  providerApi,
  patientsApi,
  adminApi,
]

const getAppMiddlewareList = (): Middleware[] => APP_API_LIST.map((api) => api.middleware)
const getAppApiReducerList = (): ApiReducerObject =>
  APP_API_LIST.reduce<ApiReducerObject>((acc: ApiReducerObject, api) => {
    acc[api.reducerPath] = api.reducer
    return acc
  }, {})

export const StateService = {
  getAppMiddlewareList,
  getAppApiReducerList,
}
