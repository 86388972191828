import { useMemo } from 'react'
import { BOOKING_TYPE } from 'features/Booking/constants/booking.constants'

export type useBookingReturnType = {
  isRoom: boolean
  isStorage: boolean
  isMailbox: boolean
  isRecurring: boolean
}

export const useBooking = (booking: Record<string, any>): useBookingReturnType => {
  const isRoom = useMemo(() => booking?.lease_type_name === BOOKING_TYPE.BOOKING, [booking])
  const isStorage = useMemo(() => booking?.lease_type_name === BOOKING_TYPE.STORAGE, [booking])
  const isMailbox = useMemo(() => booking?.lease_type_name === BOOKING_TYPE.MAILBOX, [booking])
  const isRecurring = useMemo(() => booking?.lease_type_name === BOOKING_TYPE.RECURRING, [booking])

  return {
    isRoom,
    isStorage,
    isMailbox,
    isRecurring,
  }
}
