import { memo } from 'react'
import { Navigate } from 'react-router-dom'

import { MenuWidget } from './components/menu-widget/menu-widget'
import { MenuSelect } from './components/menu-select/menu-select'

import { Grid, Layout } from 'antd'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { generateRouter } from 'routes/routes.utils'
import { ChangePassword } from './features/ChangePassword/ChangePassword'
import { Deposit } from './features/Deposit/Deposit'
import { Mailbox } from './features/Mailbox/Mailbox'
import { Notifications } from './features/Notifications/Notifications'
import { CreditCards } from './features/Payment/CreditCards'
import { PersonalProfile } from './features/PersonalProfile/PersonalProfile'

import styles from './profile.module.scss'
import { UserWidget } from './components/UserWidget/UserWidget'
import { BillingHistory } from './features/BillingHistory/BillingHistory'
import { PaymentHistory } from './features/PaymentsHistory/PaymentHistory'

const { Sider, Content } = Layout
const { useBreakpoint } = Grid

const routesConfig = {
  [PATH_SEGMENT.PERSONAL_PROFILE]: <PersonalProfile />,
  [PATH_SEGMENT.NOTIFICATIONS]: <Notifications />,
  [PATH_SEGMENT.CHANGE_PASSWORD]: <ChangePassword />,
  [PATH_SEGMENT.DEPOSIT]: <Deposit />,
  [PATH_SEGMENT.MAILBOX_PROFILE]: <Mailbox />,
  [PATH_SEGMENT.CREDIT_CARDS]: <CreditCards />,
  [PATH_SEGMENT.BILLING_HISTORY]: <BillingHistory />,
  [PATH_SEGMENT.PAYMENTS_HISTORY]: <PaymentHistory />,
  [PATH_SEGMENT.OTHER]: <Navigate to={PATH_SEGMENT.PERSONAL_PROFILE} replace />,
}

export const Profile = memo(() => {
  const breakpoint = useBreakpoint()

  const isTabletOrSmaller = !breakpoint.lg

  return (
    <>
      <div className={styles.header}>
        <UserWidget />
      </div>
      <Layout>
        {isTabletOrSmaller ? (
          <div className={styles.mobileSelect}>
            <MenuSelect />
          </div>
        ) : (
          <Sider width={266} className={styles.sider}>
            <MenuWidget />
          </Sider>
        )}
        <Content className={styles.container}>{generateRouter(routesConfig)}</Content>
      </Layout>
    </>
  )
})
