export enum PATH_SEGMENT {
  ADMIN = 'admin',
  AUTH = 'auth',
  BILLING = 'billing',
  BILLING_HISTORY = 'billing-history',
  BOOKING = 'booking',
  CONTRACTS = 'contracts',
  CHANGE_PASSWORD = 'change-password',
  CHECKOUT = 'checkout',
  CREDIT_CARDS = 'credit-cards',
  DEPOSIT = 'deposit',
  DETAILS = 'details',
  DICTIONARY = 'dictionary',
  ERROR = 'error',
  FAQ = 'faq',
  GUEST = 'guest',
  HOME = 'home',
  LOGIN = 'login',
  MAILBOX_PROFILE = 'mailbox-profile',
  BOOK = 'book',
  NOTIFICATIONS = 'notifications',
  PATIENTS = 'patients',
  PATIENT_SCHEDULE = 'patient-schedule',
  PATIENT_TRACKER = 'patient-tracker',
  DOCUMENTS = 'documents',
  SHOP = 'shop',
  PAYMENTS_HISTORY = 'payments-history',
  PERSONAL_PROFILE = 'personal-profile',
  PROFILE = 'profile',
  PROMOTIONS = 'promotions',
  PROVIDER = 'provider',
  REGISTER = 'register',
  RESET_PASSWORD = 'reset-password',
  ROOT = '/',
  SETTINGS = 'settings',
  SET_NEW_PASSWORD = 'set-new-password',
  STAFF = 'staff',
  START_VALIDATION_PROCESS = 'start-processing-documents',
  SUPPORT = 'support',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  TICKET_DETAILS = 'ticket-details',
  VALIDATE_EMAIL = 'validate-email',
  VALIDATE_IDENTITY = 'validate-doctor-credentials',
  VALIDATION_PROCESSING = 'status-processing-documents',
  VALIDATION_RESULT = 'processing-result',
  OTHER = '*',
}
