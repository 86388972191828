import { type FC, type JSX } from 'react'
import { shallowEqual } from 'react-redux'

import classNames from 'classnames'
import type { FormikValues } from 'formik'
import { Form, Formik } from 'formik'

import { Button } from 'common/components/Button/Button'
import { FormControl } from 'common/components/FormItems/FormControl/FormControl'
import { Show } from 'common/components/Show/Show'
import {
  BUTTON_CONSTANTS,
  BUTTON_MODIFIER,
  BUTTON_PROPORTION,
} from 'common/constants/buttonConstants'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'
import { INPUT_TYPES } from 'common/constants/inputConstants'
import type { IOption } from 'common/interfaces/IOption'

import { SETTINGS_BILLING_LABELS } from 'features/Profile/features/Payment/constants/constants'
import { SettingsCardInfo } from 'features/SettingsOld/components/SettingsCardInfo/SettingsCardInfo'
import {
  INITIAL_ADD_CARD_DATA,
  SETTINGS_ADD_CARD_KEYS,
  USA_COUNTRY_ID,
} from 'features/SettingsOld/constants/settingsAddCard'
import type { ISettingBilling } from 'features/SettingsOld/interfaces/ISettingBilling'
import type { ISettingPaymentCard } from 'features/SettingsOld/interfaces/ISettingPaymentCard'
import { SETTINGS_BILLING_SCHEMA } from 'features/SettingsOld/schemas/settingsBillingSchema_Square'
import { SETTINGS_EDIT_BILLING_SCHEMA } from 'features/SettingsOld/schemas/settingsEditBillingSchema'

import styles from './settingsBillingForm.module.scss'

interface IProps {
  isRemoving: boolean
  isUpdating: boolean
  handleClose: () => void
  isLoadingStates: boolean
  isLoadingBilling: boolean
  editCard: ISettingPaymentCard
  formData: ISettingBilling | {}

  onRemove: () => Promise<void>
  locations: { states: IOption[]; countries: IOption[] }
  handleSubmit: (
    values: FormikValues,
    reset: () => void,
    setFieldError: (field: string, message: string) => void,
  ) => Promise<void>
}

export const SettingsBillingForm: FC<IProps> = ({
  isRemoving,
  onRemove,
  editCard,
  handleClose,
  formData = {},
  isLoadingBilling,
  isLoadingStates,
  handleSubmit,
  isUpdating,
  locations: { states, countries },
}) => {
  const getCountryFromList = (list: IOption[], country: string | number) => {
    return list.find((item: IOption): boolean => item.code === country || item.name === country)
  }

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validationSchema={editCard ? SETTINGS_EDIT_BILLING_SCHEMA : SETTINGS_BILLING_SCHEMA}
      initialValues={
        isLoadingBilling || isLoadingStates ? {} : !editCard ? INITIAL_ADD_CARD_DATA : formData
      }
      onSubmit={(values, { resetForm, setFieldError }) =>
        handleSubmit(values, resetForm, setFieldError)
      }>
      {({ values, initialValues, setFieldValue }: any): JSX.Element => {
        const country = getCountryFromList(countries, values?.address_country)
        return (
          <Form id='billing-form' className={styles.parent}>
            <SettingsCardInfo
              editCard={editCard}
              isLoading={isLoadingBilling}
              setFieldValue={setFieldValue}
            />

            <div className={styles.parentFormItems}>
              <div className={styles.parentFormChild}>
                <h3 className={styles.parentFormDescription}>
                  {SETTINGS_BILLING_LABELS.BILLING_ADDRESS}
                </h3>
                <FormControl
                  required
                  type={INPUT_TYPES.TEXT}
                  isLoading={isLoadingBilling}
                  control={FORM_CONTROL_TYPE.INPUT}
                  name={SETTINGS_ADD_CARD_KEYS.NAME}
                  label={SETTINGS_BILLING_LABELS.FULL_NAME}
                />
                <div className={styles.parentFormItem}>
                  <FormControl
                    required
                    type={INPUT_TYPES.TEXT}
                    isLoading={isLoadingBilling}
                    control={FORM_CONTROL_TYPE.INPUT}
                    name={SETTINGS_ADD_CARD_KEYS.LINE_1}
                    label={SETTINGS_BILLING_LABELS.ADDRESS}
                  />
                  <FormControl
                    type={INPUT_TYPES.TEXT}
                    isLoading={isLoadingBilling}
                    control={FORM_CONTROL_TYPE.INPUT}
                    name={SETTINGS_ADD_CARD_KEYS.LINE_2}
                    label={SETTINGS_BILLING_LABELS.APARTMENT}
                  />
                </div>
                <div className={styles.parentFormItem}>
                  <FormControl
                    required
                    type={INPUT_TYPES.TEXT}
                    isLoading={isLoadingBilling}
                    control={FORM_CONTROL_TYPE.INPUT}
                    name={SETTINGS_ADD_CARD_KEYS.CITY}
                    label={SETTINGS_BILLING_LABELS.CITY}
                  />
                  <FormControl
                    required
                    type={INPUT_TYPES.TEXT}
                    isLoading={isLoadingBilling}
                    control={FORM_CONTROL_TYPE.INPUT}
                    name={SETTINGS_ADD_CARD_KEYS.ZIP_CODE}
                    label={SETTINGS_BILLING_LABELS.ZIP_CODE}
                  />
                </div>
                <div className={styles.parentFormItem}>
                  <FormControl
                    required
                    options={countries}
                    type={INPUT_TYPES.TEXT}
                    control={FORM_CONTROL_TYPE.SELECT}
                    onChange={(e) => {
                      if (e.target?.value !== USA_COUNTRY_ID) {
                        setFieldValue(SETTINGS_ADD_CARD_KEYS.STATE, null)
                      }
                    }}
                    name={SETTINGS_ADD_CARD_KEYS.COUNTRY}
                    label={SETTINGS_BILLING_LABELS.COUNTRY}
                    isLoading={isLoadingStates || isLoadingBilling}
                  />

                  <Show when={country?.code === USA_COUNTRY_ID}>
                    <FormControl
                      required
                      options={states}
                      type={INPUT_TYPES.TEXT}
                      control={FORM_CONTROL_TYPE.SELECT}
                      name={SETTINGS_ADD_CARD_KEYS.STATE}
                      label={SETTINGS_BILLING_LABELS.STATE}
                      isLoading={isLoadingStates || isLoadingBilling}
                    />
                  </Show>
                </div>
              </div>
            </div>
            <div className={styles.parentItems}>
              <div
                className={classNames(styles.parentDelete, {
                  [styles.parentDeleteHidden]: !editCard,
                })}>
                <Button
                  loading={isRemoving}
                  onClick={onRemove}
                  proportion={BUTTON_PROPORTION.LARGE}
                  data-cy='delete-btn'>
                  {BUTTON_CONSTANTS.DELETE}
                </Button>
              </div>

              <div className={styles.parentManage}>
                <Button
                  modifier={BUTTON_MODIFIER.DEFAULT}
                  onClick={handleClose}
                  data-cy='cancel-btn'>
                  {BUTTON_CONSTANTS.CANCEL}
                </Button>
                <Button
                  htmlType='submit'
                  form='billing-form'
                  loading={isUpdating}
                  data-cy='submit-btn'
                  disabled={shallowEqual(values, initialValues)}>
                  {BUTTON_CONSTANTS.SUBMIT}
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
