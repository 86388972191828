import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'

import type { UploadFile, UploadProps } from 'antd'
import { Upload as AntUpload } from 'antd'

import { useAuth, useNotification } from 'app/providers'

import trashIcon from 'assets/icons/trash-gray.svg'

import { Button } from 'common/components/Button/Button'
import { ModalPreviewFile } from 'common/components/Modal'
import { Show } from 'common/components/Show/Show'
import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import {
  BUTTON_CONSTANTS,
  BUTTON_MODIFIER,
  BUTTON_PROPORTION,
} from 'common/constants/buttonConstants'
import { UPLOAD_CONSTANTS } from 'common/constants/uploadConstants'
import type { IUpload } from 'common/interfaces/IUpload'
import { UtilService } from 'common/services/utilService'

import './upload.scss'

const { Dragger } = AntUpload

const { fileSizeInMB, fileSizeFormatted } = UtilService
const acceptedUploadType = ['image/png', 'image/jpeg', 'application/pdf']

export const Upload: FC<IUpload> = ({
  label,
  maxCount,
  onChange,
  fileList,
  iconSrc,
  name,
  ...rest
}) => {
  const { user } = useAuth()
  const { setNotification } = useNotification()
  const [previewFile, setPreviewFile] = useState<UploadFile | null>(null)

  const props: UploadProps = useMemo(
    (): UploadProps => ({
      name: 'file',
      multiple: true,
      maxCount: maxCount,
      headers: { Authorization: `Bearer ${user.token}` },
      action: `${process.env.REACT_APP_RESIDEN_API_BASE_URL}/file-upload`,
      onChange(info) {
        if (
          acceptedUploadType.includes(info.file.type as string) &&
          fileSizeInMB(info.file.size) < 10
        )
          onChange(info.fileList as any)
        else if (fileSizeInMB(info.file.size) >= 10)
          setNotification({
            type: ALERT_CONSTANTS.ERROR,
            title: UPLOAD_CONSTANTS.SIZE_ERROR_TITLE,
            description: UPLOAD_CONSTANTS.SIZE_ERROR_DESCRIPTION,
          })
      },
      beforeUpload: (file: UploadFile): boolean => {
        if (fileSizeInMB(file.size) < 10 && acceptedUploadType.includes(file.type as string)) {
          onChange([...fileList, file] as any)
          return true
        }

        if (!acceptedUploadType.includes(file.type as string)) {
          setNotification({
            type: ALERT_CONSTANTS.ERROR,
            title: UPLOAD_CONSTANTS.TYPE_ERROR_TITLE,
            description: UPLOAD_CONSTANTS.TYPE_ERROR_DESCRIPTION,
          })
        }
        return false
      },
    }),
    [fileList],
  )

  const handleRemoveFile = useCallback(
    (fileUid: string): void => {
      const filteredFiles: any = fileList.filter(
        (item: UploadFile): boolean => item.uid !== fileUid,
      )
      onChange(filteredFiles)
    },
    [fileList],
  )

  const handlePreviewFile = useCallback(
    (file: any): void => {
      setPreviewFile(file)
    },
    [fileList],
  )

  return (
    <div className='upload'>
      <ModalPreviewFile onClose={() => setPreviewFile(null)} previewFile={previewFile} />
      <Show when={label}>
        <label htmlFor={name} className='upload-label'>
          {label}
        </label>
      </Show>

      <Dragger
        multiple={false}
        fileList={fileList}
        accept='image/png, image/jpeg, application/pdf'
        name={name}
        {...props}
        {...rest}
        itemRender={(originNode, file: UploadFile) => {
          const { name, size, uid } = file
          return (
            <div className='upload-file-details'>
              <span className='upload-info'>
                <p className='upload-file-name'>
                  {name.length > 30 ? `${name.slice(0, 30)} ${name.slice(-8, name.length)}` : name}
                </p>
                <span className='upload-dot' />
                <Button onClick={() => handlePreviewFile(file)} className='reset-button'>
                  <span className='upload-preview'>{UPLOAD_CONSTANTS.PREVIEW}</span>
                </Button>
              </span>
              <span className='upload-size'>
                <span>{fileSizeFormatted(size)}</span>
                <Button onClick={() => handleRemoveFile(uid)} className='reset-button'>
                  <img src={trashIcon} alt={ALT_CONSTANTS.ACTION_ICON} />
                </Button>
              </span>
            </div>
          )
        }}>
        <div className='upload-content'>
          <div className='upload-details'>
            <img src={iconSrc} className='upload-icon' alt={ALT_CONSTANTS.ACTION_ICON} />
            <span className='upload-description'>
              <h3 className='upload-title'>
                <span className='upload-title-select'>{UPLOAD_CONSTANTS.SELECT_FILE}</span>
                <span>{UPLOAD_CONSTANTS.DRAG_AND_DROP}</span> <span>(max 10 files)</span>
              </h3>
              <p className='upload-file-type'>{UPLOAD_CONSTANTS.FILE_DESCRIPTION}</p>
            </span>
          </div>
          <Button proportion={BUTTON_PROPORTION.SMALL} modifier={BUTTON_MODIFIER.SECONDARY}>
            {BUTTON_CONSTANTS.UPLOAD}
          </Button>
        </div>
      </Dragger>
    </div>
  )
}
