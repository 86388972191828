/* eslint-disable jsx-a11y/anchor-is-valid */

import type { FC } from 'react'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'

import type { PaginationProps, TablePaginationConfig } from 'antd'
import { Pagination } from 'antd'
import { compareAsc, format, parseISO } from 'date-fns'

import { useScreenWidth } from 'app/hooks/useScreenWidth'
import { useAuth, useNotification } from 'app/providers'

import { Show } from 'common/components/Show/Show'
import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import { DEFAULT_TABLE_PARAMS } from 'common/constants/defaultTableParamsConstants'
import { RESPONSE_PROPERTY_CONSTANTS } from 'common/constants/reponsePropertyConstants'
import { useAppSelector } from 'common/hooks/redux'
import type { ITriggerPagRequest, ITriggerRequest } from 'common/interfaces/IRequestResponse'
import { DateService } from 'common/services/dateService'

import { BOOKING_TYPE } from 'features/Booking/constants/booking.constants'
import { GoodToKnow } from 'features/Home/Book/Booking/BookingDetails/GoodToKnow/GoodToKnow'
import { BookingHeader } from 'features/Home/Book/Booking/BookingDetails/BookingHeader/BookingHeader'
import { InfoNotification } from 'features/Info/components/InfoNotification/InfoNotification'
import { InfoScreenList } from 'features/Info/components/InfoScreenList/InfoScreenList'
import type { IBookingNotification, IInfoDetails } from 'features/Info/interfaces/IInfo'
import {
  useLazyFetchLeaseSlotsQuery,
  useLazyFetchStorageLeaseNotificationsQuery,
  useRemoveLeaseMediaMutation,
  useUpdateLeaseMediaMutation,
  useUpdateLeaseMusicMutation,
} from 'features/Info/state/api/infoApi'

import styles from './bookingDetails.module.scss'
import type { BookingDetailsProps } from './bookingDetails.types'
import { TabPanel } from 'common/components/TabPanel/TabPanel'

export const BookingDetails: FC<BookingDetailsProps> = memo(
  ({ booking, bookingApiProps, isFetchingBooking }) => {
    const [tableParams, setTableParams] = useState<TablePaginationConfig>({
      ...DEFAULT_TABLE_PARAMS,
    })

    const { isDoctor } = useAuth()
    const { screenWidth } = useScreenWidth()
    const { setNotification } = useNotification()

    const [updateLeaseMedia, { error, isLoading: isUploading }]: ITriggerRequest =
      useUpdateLeaseMediaMutation()
    const [removeLeaseMedia, { isLoading: isRemovingMedia }]: ITriggerRequest =
      useRemoveLeaseMediaMutation()
    const [updateLeaseMusic]: ITriggerRequest = useUpdateLeaseMusicMutation()
    const [
      fetchInfoDetails,
      { data: slots, isFetching: isFetchingSlots },
    ]: ITriggerRequest<IInfoDetails> = useLazyFetchLeaseSlotsQuery()

    const [fetchStorageLeaseNotifications, { data: notifications }]: ITriggerPagRequest<
      IBookingNotification[]
    > = useLazyFetchStorageLeaseNotificationsQuery()

    const { timeZone } = useAppSelector((state) => state.infoReducer)

    const bookingType = useMemo(() => booking?.lease_type_name, [booking])
    const isBookingActive = useMemo(() => booking?.status === 'Active', [booking])
    const isRoomBooking = useMemo(() => bookingType === BOOKING_TYPE.BOOKING, [bookingType])
    const hasNotifications = useMemo(
      () => [BOOKING_TYPE.STORAGE, BOOKING_TYPE.RECURRING].includes(bookingType as BOOKING_TYPE),
      [bookingType],
    )

    useEffect(() => {
      if (!bookingApiProps) {
        return
      }

      fetchStorageLeaseNotifications({
        ...bookingApiProps,
        params: { ...tableParams },
      })
    }, [tableParams.pageSize, tableParams.current, bookingApiProps])

    const handleAddMedia = (screen: number, file: File): void => {
      updateLeaseMedia({
        screen,
        file,
        ...bookingApiProps,
      })
    }

    const handleRemoveMedia = (mediaId: string): void => {
      removeLeaseMedia({
        mediaId,
        ...bookingApiProps,
      })
    }

    const fetchDaySlots = useCallback(
      (date: string): void => {
        fetchInfoDetails({ ...bookingApiProps, date })
      },
      [bookingApiProps],
    )

    const handleUpdateAmbientMusic = async (ambient_music_id: number): Promise<void> => {
      const response = await updateLeaseMusic({ ambient_music_id, ...bookingApiProps })
      if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.SUCCESS)) {
        setNotification({
          title: 'Ambient music updated',
          description: response.data.message,
          type: ALERT_CONSTANTS.SUCCESS,
        })
      }
    }

    const bookingDays = [...(booking?.lease_days || [])]?.sort((a, b) =>
      compareAsc(parseISO(a.code as string), parseISO(b.code as string)),
    )

    const defaultActiveCurrentDay = format(DateService.getNowToServerZone(timeZone), 'yyyy-MM-dd')

    const onNotificationsPageChange = (page: number): void => {
      setTableParams({ ...tableParams, current: page })
    }

    const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
      if (type === 'prev') {
        return <a>Previous</a>
      }
      if (type === 'next') {
        return <a>Next</a>
      }
      return originalElement
    }

    const uploadError = useMemo(() => {
      if (!error) {
        return
      }

      return (error as any).data?.[0]
    }, [error])

    useEffect(() => {
      if (uploadError) {
        setNotification({
          type: ALERT_CONSTANTS.ERROR,
          title: 'Error',
          description: uploadError,
        })
      }
    }, [uploadError])

    console.log('>>> bookingDays', bookingDays)

    return (
      <div className={styles.parent}>
        <div className={styles.parentWrapper}>
          <BookingHeader
            isDoctor={isDoctor}
            booking={booking}
            isLoading={isFetchingBooking}
            bookingApiProps={bookingApiProps}
          />

          <section className={styles.parentLeaseDetails}>
            <Show when={isRoomBooking}>
              <TabPanel tabs={[]} />
              {/*  <DynamicTabPanel*/}
              {/*    propActiveKey={defaultActiveCurrentDay}*/}
              {/*    independently*/}
              {/*    forceActiveTab*/}
              {/*    polkaDotCurrentDay*/}
              {/*    onChangeTab={fetchDaySlots}*/}
              {/*    options={bookingDays}*/}
              {/*    timeZone={timeZone}>*/}
              {/*    <InfoSlotList*/}
              {/*      slots={slots?.time_slots}*/}
              {/*      isLeaseActive={isBookingActive}*/}
              {/*      isFetching={isFetchingSlots || isLoading}*/}
              {/*    />*/}

              {/*    <InfoLongTermList*/}
              {/*      options={slots?.long_term_options}*/}
              {/*      isFetching={isFetchingSlots || isLoading}*/}
              {/*    />*/}
              {/*  </DynamicTabPanel>*/}
            </Show>

            <Show when={hasNotifications}>
              <h1>Notifications</h1>
              {notifications?.items?.map((notification: IBookingNotification, index: number) => (
                <InfoNotification notification={notification} index={index} />
              ))}

              <Show when={!!notifications?.items?.length}>
                <Pagination
                  showSizeChanger={false}
                  className={styles.parentNotificationPaginator}
                  total={notifications?.total_items}
                  itemRender={itemRender}
                  onChange={onNotificationsPageChange}
                />
              </Show>
            </Show>
          </section>
          <Show when={isRoomBooking && screenWidth > 1200}>
            <InfoScreenList
              booking={booking}
              isDisabled={!isBookingActive}
              handleAddMedia={handleAddMedia}
              isFetching={isFetchingBooking}
              isRemovingMedia={isRemovingMedia}
              handleRemoveMedia={handleRemoveMedia}
              isUploading={isUploading}
            />
          </Show>
        </div>

        <GoodToKnow
          withDeposit={isDoctor && isRoomBooking}
          isFetching={isFetchingBooking}
          isBookingActive={isBookingActive}
          bookingApiProps={bookingApiProps}
          booking={{
            ...booking?.good_to_know,
            box_number: booking?.box_number,
            start_date: booking?.start_date,
            lease_type_code: booking?.lease_type_code,
            lease_type_name: booking?.lease_type_name,
            end_date: booking?.end_date,
            hardware_identifier: booking?.hardware_identifier,
          }}
          handleUpdateAmbientMusic={handleUpdateAmbientMusic}
        />

        <Show when={isRoomBooking && screenWidth <= 1200}>
          <InfoScreenList
            booking={booking}
            isDisabled={!isBookingActive}
            handleAddMedia={handleAddMedia}
            isFetching={isFetchingBooking}
            isRemovingMedia={isRemovingMedia}
            handleRemoveMedia={handleRemoveMedia}
          />
        </Show>
      </div>
    )
  },
)
