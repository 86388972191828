import type { FC } from 'react'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom'

import { Skeleton } from 'antd'
import dayjs from 'dayjs'

import { ReactComponent as NavigationIcon } from 'assets/icons/navigation-arrow.svg'

import { Show } from 'common/components/Show/Show'
import { TabPanel } from 'common/components/TabPanel/TabPanel'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import useNavigateParams from 'common/hooks/useNavigateParams'
import type { IOption } from 'common/interfaces/IOption'
import type { IRequestResponse } from 'common/interfaces/IRequestResponse'
import { DateService } from 'common/services/dateService'
import { UtilService } from 'common/services/utilService'

import { BOOKING_PARAMS, BOOKING_TYPE } from 'features/Booking/constants/booking.constants'
import InfoAddEditPatient from 'features/Info/components/InfoAddPatient/InfoAddEditPatient'
import InfoAddWalkInPatientButton from 'features/Info/components/InfoAddWalkInPatientButton/InfoAddWalkInPatientButton'
import InfoCalendar from 'features/Info/components/InfoCalendar/InfoCalendar'
import InfoDocumentResourceHeader from 'features/Info/components/InfoDocumentResourceHeader/InfoDocumentResourceHeader'
import InfoEmptyRoomsList from 'features/Info/components/InfoEmptyRoomsList/InfoEmptyRoomsList'
import InfoScheduleSkeleton from 'features/Info/components/InfoScheduleSkeleton/InfoScheduleSkeleton'
import {
  INFO_CONSTANTS,
  PATIENT_SCHEDULE_SCROLL_HEIGHT_OFFSET,
  TAB_KEYS,
} from 'features/Info/constants/infoConstants'
import { useInfoManager } from 'features/Info/hooks/useInfoManager'
import { useInfoPusher } from 'features/Info/hooks/useInfoPusher'
import type { IPatientRoom } from 'features/Info/interfaces/IInfoPatient'
import type { AppointmentDate, IPatientSchedule } from 'features/Info/interfaces/IInfoSchedule'
import { useFetchPatientActivityDaysQuery } from 'features/Info/state/api/infoApi'
import { CLEANUP_INFO_STATE, UPDATE_INFO_ACTIVE_TAB_KEY } from 'features/Info/state/slice/infoSlice'

import styles from './patientSchedule.module.scss'
import type { PatientScheduleProps } from './patientSchedule.types'

export const PatientSchedule: FC<PatientScheduleProps> = memo(({ bookingApiProps }) => {
  const [addPatient, setAddPatient] = useState<boolean>(false)

  const [editedPatient, setEditedPatient] = useState<IPatientSchedule | null>(null)
  const [prePopulateSchedule, setPrePopulateSchedule] = useState<number | null>(null)
  const [hasFinishedFirstLoad, setHasFinishedFirstLoad] = useState<boolean>(false)
  const [customAppointmentData, setCustomAppointmentData] = useState<{
    appointment: AppointmentDate
    room: string
  } | null>(null)
  const { fetchInfoData } = useInfoManager()
  const { rooms, schedules, activeTabKey, isFetching, updatedEvents, timeZone } = useAppSelector(
    (state) => state.infoReducer,
  )
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigateParams()
  const [searchParams] = useSearchParams()
  const leaseType = searchParams.get(BOOKING_PARAMS.TYPE)

  const params = useParams()
  useInfoPusher(TAB_KEYS.PATIENT_SCHEDULE)
  const { '*': splat } = params

  const leaseConnectionDetails = {
    date: activeTabKey,
    leaseId: bookingApiProps?.bookingId ?? params.bookingId,
    connection: bookingApiProps?.connection ?? params.connection,
  }

  const { connection, leaseId } = leaseConnectionDetails
  const isCurrentDaySelected = useMemo(() => {
    return DateService.getIsValidAndCurrentDay(activeTabKey[TAB_KEYS.PATIENT_SCHEDULE], timeZone)
  }, [activeTabKey[TAB_KEYS.PATIENT_SCHEDULE], timeZone])

  const isTheSelectedDayBeforeCurrentDay = useMemo(() => {
    return DateService.getIsDateBeforeCurrentDate(activeTabKey[TAB_KEYS.PATIENT_SCHEDULE], timeZone)
  }, [activeTabKey[TAB_KEYS.PATIENT_SCHEDULE], timeZone])

  const isSelectedDayAfterCurrentDay = useMemo(
    () => !isCurrentDaySelected && !isTheSelectedDayBeforeCurrentDay,
    [activeTabKey[TAB_KEYS.PATIENT_SCHEDULE], timeZone],
  )

  const navigateToPatientActivity = () => {
    if (isPromotion) {
      return navigate(`/home/${connection}/promotions/${leaseId}/patient-activity`)
    }
    navigate(`/home/${connection}/bookings/${leaseId}/patient-activity`, {
      [BOOKING_PARAMS.TYPE]: BOOKING_TYPE.BOOKING,
    })
  }

  const checkInitialValue = () => {
    if (splat) {
      const date = dayjs(splat.split('/').pop())
      if (date.isValid()) {
        return dispatch(
          UPDATE_INFO_ACTIVE_TAB_KEY({
            key: TAB_KEYS.PATIENT_SCHEDULE,
            value: date.format('YYYY-MM-DD'),
          }),
        )
      }
    }
    const value = activeTabKey[TAB_KEYS.PATIENT_SCHEDULE]
    if (value) return onChangeLeaseDate(value)
  }

  useEffect(() => {
    checkInitialValue()
  }, [splat])

  const message = useMemo(() => {
    if (isCurrentDaySelected) {
      return (
        <span className={styles.message}>
          <span>{INFO_CONSTANTS.CURRENT_DAY_MESSAGE}</span>
          <span className={styles.messageAction} onClick={navigateToPatientActivity}>
            {INFO_CONSTANTS.PATIENT_ACTIVITY_TITLE}
          </span>
        </span>
      )
    }

    if (isTheSelectedDayBeforeCurrentDay) {
      return <span className={styles.message}>{INFO_CONSTANTS.PAST_DAY_MESSAGE}</span>
    }

    return null
  }, [isCurrentDaySelected, isTheSelectedDayBeforeCurrentDay])

  const {
    data: leaseDays,
    isLoading: isFetchingLeaseDays,
    isSuccess: isSuccessFetchDays,
  }: IRequestResponse<IOption[]> = useFetchPatientActivityDaysQuery(
    {
      leaseId: leaseConnectionDetails.leaseId ?? params.leaseId,
      connection: leaseConnectionDetails.connection ?? params.connection,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const isPromotion = location?.pathname?.includes('promotions')
  const hasEmptyDays = isPromotion && isSuccessFetchDays && leaseDays.length === 0

  useEffect(() => {
    return () => {
      dispatch(CLEANUP_INFO_STATE())
    }
  }, [])

  useEffect(() => {
    const date = dayjs(splat.split('/').pop())
    if ((!splat || !date.isValid()) && Array.isArray(leaseDays)) {
      const today = dayjs().startOf('day')

      const nextAvailableDay = leaseDays.find((day) => {
        const leaseDayDate = dayjs(day.code, 'YYYY-MM-DD')

        return leaseDayDate.isSame(today) || leaseDayDate.isAfter(today)
      })

      dispatch(
        UPDATE_INFO_ACTIVE_TAB_KEY({
          key: TAB_KEYS.PATIENT_SCHEDULE,
          value: nextAvailableDay?.code || leaseDays[0]?.code,
        }),
      )
    }
  }, [leaseDays, splat])

  useEffect(() => {
    // const { tabKey } = params
    if (
      //TODO: do we need this condition ?????
      // tabKey === TAB_KEYS.PATIENT_SCHEDULE &&

      activeTabKey[TAB_KEYS.PATIENT_SCHEDULE]
    ) {
      setHasFinishedFirstLoad(false)

      fetchInfoData(TAB_KEYS.PATIENT_SCHEDULE).then(() => setHasFinishedFirstLoad(true))
    }
  }, [activeTabKey[TAB_KEYS.PATIENT_SCHEDULE], params])

  useEffect(() => {
    const handleScroll = () => {
      const headerAll = document.querySelectorAll('.rbc-time-header')
      if (window.scrollY > PATIENT_SCHEDULE_SCROLL_HEIGHT_OFFSET && headerAll.length > 0) {
        Array.from(headerAll).map((header) => header.classList.add(styles.parentSticky))
      } else {
        Array.from(headerAll).map((header) => header.classList.remove(styles.parentSticky))
      }
    }
    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const adjustedTabs = useMemo(() => UtilService.optionsToNavigationTabs(leaseDays), [leaseDays])

  const onChangeLeaseDate = useCallback(
    (date: string): void => {
      const formatted_date = dayjs(date).format('MM-DD-YYYY')
      const navParams = leaseType ? { [BOOKING_PARAMS.TYPE]: leaseType } : {}

      navigate(formatted_date, navParams)
      dispatch(UPDATE_INFO_ACTIVE_TAB_KEY({ key: TAB_KEYS.PATIENT_SCHEDULE, value: date }))
    },
    [updatedEvents],
  )
  const roomsHeader = useMemo(
    () =>
      rooms?.map((room: IPatientRoom) => ({
        resourceId: `${room.label}/${room.value}`,
        resourceTitle: (
          <InfoDocumentResourceHeader room={room} hasRoomStatus={false} timeZone={timeZone} />
        ),
      })),
    [rooms],
  )

  const handleDisplayEventDetails = (data: any): void => {
    const clickedPatient = schedules.find((patient: IPatientSchedule) => patient.id === data.id)
    setEditedPatient(clickedPatient)
  }

  const handleCloseModal = () => {
    setAddPatient(false)
    setEditedPatient(null)
    setCustomAppointmentData(null)
  }

  const headerSection = () => {
    if (isFetchingLeaseDays) {
      return (
        <>
          <Skeleton.Input active block className={styles.parentSkeleton} />
          <Skeleton.Avatar active size={100} shape='square' style={{ margin: '5px 0' }} />
        </>
      )
    }

    return (
      <>
        <div className={styles.parentContent}>
          {leaseDays && (
            <TabPanel
              polkaDotCurrentDay
              activeKey={activeTabKey[TAB_KEYS.PATIENT_SCHEDULE]}
              onChange={onChangeLeaseDate}
              timeZone={timeZone}
              tabs={adjustedTabs}
            />
          )}
        </div>
        {isSelectedDayAfterCurrentDay && (
          <div className={styles.parentActionContainer}>
            <InfoAddWalkInPatientButton
              handleClick={() => setAddPatient(true)}
              isDisabled={isFetchingLeaseDays}
              label={INFO_CONSTANTS.ADD_PATIENT}
            />
          </div>
        )}
      </>
    )
  }

  const createScheduleWithCustomAppointment = (
    start: Date,
    end: Date,
    resourceId: string,
  ): void => {
    setCustomAppointmentData({ appointment: [start, end], room: resourceId })
    setAddPatient(true)
  }

  return (
    <div className={styles.parent}>
      <h1>{INFO_CONSTANTS.PATIENT_ACTIVITY}</h1>
      <Show when={!hasEmptyDays}>{headerSection()}</Show>
      <Show
        when={isFetching || isFetchingLeaseDays || !hasFinishedFirstLoad}
        fallback={
          <>
            <InfoCalendar
              roomsHeader={roomsHeader}
              handleDisplayEventDetails={handleDisplayEventDetails}
              message={message}
              setPrePopulateSchedule={setPrePopulateSchedule}
              date={activeTabKey[TAB_KEYS.PATIENT_SCHEDULE]}
              addPreComplete={isSelectedDayAfterCurrentDay}
              prePopulateSchedule={prePopulateSchedule}
              createCustomAppointment={createScheduleWithCustomAppointment}
              tabKey={TAB_KEYS.PATIENT_SCHEDULE}
              disabledEverything={!isSelectedDayAfterCurrentDay}
            />
          </>
        }>
        <Show when={hasEmptyDays} fallback={<InfoScheduleSkeleton />}>
          <div className={styles.parentEmptyContainer}>
            <InfoEmptyRoomsList
              title='You need to book days before you can add any patients.'
              content={
                <Link className={styles.parentEmptyLink} to='../details'>
                  Book now <NavigationIcon width={32} height={32} />
                </Link>
              }
            />
          </div>
        </Show>
      </Show>

      <InfoAddEditPatient
        open={addPatient || !!editedPatient}
        isEditing={!!editedPatient}
        editedPatientData={editedPatient}
        prePopulateSchedule={prePopulateSchedule}
        setOpen={handleCloseModal}
        disabledEverything={!isSelectedDayAfterCurrentDay}
        date={activeTabKey[TAB_KEYS.PATIENT_SCHEDULE]}
        title={!!editedPatient ? INFO_CONSTANTS.EDIT_PATIENT : INFO_CONSTANTS.ADD_PATIENT}
        customAppointment={customAppointmentData}
        tabKey={TAB_KEYS.PATIENT_SCHEDULE}
      />
    </div>
  )
})
