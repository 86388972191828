import type { FC } from 'react'
import { memo, useCallback, useEffect } from 'react'
import { useToggle } from 'react-use'

import type { FormikValues } from 'formik'
import Stripe from 'stripe'

import cardIcon from 'features/SettingsOld/assets/card-icon.svg'

import { Modal } from 'common/components/Modal'
import { STRIPE_KEY } from 'common/constants/siteSelectorConstants'
import { useApiResponse } from 'common/hooks/useApiResponse'
import type { ApiResponse, ITriggerRequest } from 'common/interfaces/IRequestResponse'
import { useFetchCountriesQuery, useFetchStatesQuery } from 'common/store/api/rootApi'

import { SETTINGS_CONSTANTS } from 'features/Profile/features/Payment/constants/constants'
import { SettingsBillingForm } from 'features/SettingsOld/components/SettingsBillingForm/SettingsBillingForm'
import type {
  ISettingBilling,
  ISettingBillingWithId,
} from 'features/SettingsOld/interfaces/ISettingBilling'
import type { ISettingPaymentCard } from 'features/SettingsOld/interfaces/ISettingPaymentCard'
import { SettingsService } from 'features/SettingsOld/services/settingsService'
import {
  useCreatePaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useLazyFetchCardDetailsQuery,
  useUpdateCardMutation,
} from 'features/SettingsOld/state/api/settingsApi'

import styles from './ManageCard.module.scss'

const { getUpdatedCardData, getCardFieldError } = SettingsService

type Props = {
  isVisible: boolean
  onClose: () => void
  editCard: ISettingPaymentCard
}

export const ManageCard: FC<Props> = memo(({ editCard, isVisible, onClose }) => {
  const { processApiResponse } = useApiResponse()
  const [isCreatingToken, toggleIsCreatingToken] = useToggle(false)

  const [createMethod] = useCreatePaymentMethodMutation()

  const { data: states, isLoading: isLoadingStates } = useFetchStatesQuery()
  const { data: countries, isLoading: isLoadingCountries } = useFetchCountriesQuery()

  const [removeCard, { isLoading: isRemovingCard }] = useDeletePaymentMethodMutation()

  const [updateCard, { isLoading: isUpdatingCard }]: ITriggerRequest = useUpdateCardMutation()

  const [
    fetchCardDetails,
    { data, isFetching: isFetchingCardDetails },
  ]: ITriggerRequest<ISettingBilling> = useLazyFetchCardDetailsQuery()

  useEffect(() => {
    if (editCard) fetchCardDetails(editCard.id)
  }, [editCard])

  const handleCreateCard = async (
    billingData: FormikValues,
    resetForm: () => void,
    setFieldError: (field: string, message: string) => void,
  ): Promise<void> => {
    toggleIsCreatingToken()

    const card: any = getUpdatedCardData(
      billingData as ISettingBillingWithId<number | string>,
      states,
      countries,
      true,
    )
    const stripe: Stripe = new Stripe(STRIPE_KEY, null)

    try {
      const token: Stripe.Response<Stripe.Token> = await stripe.tokens.create({
        card,
      })
      const response = await createMethod({ card_token: token.id })
      // processApiResponse(response, {
      //   success: 'Payment method added',
      //   error: 'Creating payment method error',
      //   successCallback: (): void => {
      //     resetForm()
      //     onClose()
      //   },
      // })
    } catch (error) {
      const field: string = getCardFieldError(error.code)
      setFieldError(field, error.message)
    }

    toggleIsCreatingToken()
  }

  const handleUpdateCard = async (values: FormikValues, resetForm: () => void): Promise<void> => {
    const formData: ISettingBillingWithId = {
      ...values,
      paymentMethodId: editCard.id,
    } as ISettingBillingWithId

    const data: ISettingBillingWithId = getUpdatedCardData(
      formData,
      states,
      countries,
    ) as ISettingBillingWithId

    const response: ApiResponse = await updateCard(data)

    processApiResponse(response, {
      success: 'Updating card success',
      error: 'Updating card error',
      successCallback: (): void => {
        onClose()
        resetForm()
      },
    })
  }

  const handleRemoveCard = useCallback(async (): Promise<void> => {
    const response = await removeCard(editCard.id)
    // processApiResponse(response, {
    //   success: 'Remove card success',
    //   error: 'Remove card error',
    // })
    onClose()
  }, [editCard])

  return (
    <Modal
      keyboard
      onCancel={onClose}
      open={isVisible}
      cancelButton={false}
      infoIconSrc={cardIcon}
      title={editCard ? SETTINGS_CONSTANTS.EDIT_CARD : SETTINGS_CONSTANTS.ADD_CARD}>
      <div className={styles.parent}>
        <SettingsBillingForm
          formData={data}
          editCard={editCard}
          handleClose={onClose}
          onRemove={handleRemoveCard}
          isRemoving={isRemovingCard}
          locations={{ states, countries }}
          isLoadingBilling={isFetchingCardDetails}
          isUpdating={isCreatingToken || isUpdatingCard}
          isLoadingStates={isLoadingStates || isLoadingCountries}
          handleSubmit={editCard ? handleUpdateCard : handleCreateCard}
        />
      </div>
    </Modal>
  )
})
