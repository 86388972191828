import { useEffect, useMemo, useRef, useState } from 'react'

import { Tooltip } from 'antd'
import { add, differenceInMinutes, format, isBefore, isSameMinute, parse } from 'date-fns'
import type { FormikValues } from 'formik'
import { Form, Formik } from 'formik'

import { useNotification } from 'app/providers'

import trashIcon from 'assets/icons/trash-dark-gray.svg'

import { Button } from 'common/components/Button/Button'
import { FormControl } from 'common/components/FormItems/FormControl/FormControl'
import { Modal, ModalAction } from 'common/components/Modal'
import PhoneInput from 'common/components/PhoneInput/PhoneInput'
import { Show } from 'common/components/Show/Show'
import { ALERT_CONSTANTS } from 'common/constants/alertConstants'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import {
  BUTTON_CONSTANTS,
  BUTTON_MODIFIER,
  BUTTON_PROPORTION,
  BUTTON_SEVERITY,
} from 'common/constants/buttonConstants'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'
import { INPUT_TYPES } from 'common/constants/inputConstants'
import { MODAL_LABELS } from 'common/constants/modalConstants'
import { TIME_FORMAT } from 'common/constants/timeFormat'
import { useAppSelector } from 'common/hooks/redux'
import type { IOption } from 'common/interfaces/IOption'
import { DateService } from 'common/services/dateService'
import { UtilService } from 'common/services/utilService'

import AppointmentProcedure from 'features/Info/components/AppointmentProcedure/AppointmentProcedure'
import InfoWarning from 'features/Info/components/InfoWarning/InfoWarning'
import {
  ACTION_TYPES,
  INFO_CONSTANTS,
  PATIENT_STATUSES,
  ROOM_STATUS,
  TAB_KEYS,
} from 'features/Info/constants/infoConstants'
import {
  INITIAL_PATIENT_DATA,
  NEW_PATIENT_FORM_KEYS,
} from 'features/Info/constants/infoNewPatientFormKeys'
import { NEW_PATIENT_LABELS } from 'features/Info/constants/infoNewPatientLabels'
import { ROOM_TYPE_IDS } from 'features/Info/constants/room.constants'
import { useInfoManager } from 'features/Info/hooks/useInfoManager'
import type { IPatientConsultationStaff, IPatientRoom } from 'features/Info/interfaces/IInfoPatient'
import type { IPatientSchedule } from 'features/Info/interfaces/IInfoSchedule'
import { NEW_PATIENT_SCHEMA } from 'features/Info/schemas/infoNewPatientSchema'
import { InfoService } from 'features/Info/services/infoService'
import { PATIENTS_LABELS } from 'features/Patients/constants/patientsConstants'
import type { IPatient } from 'features/Patients/interfaces/IPatient'

import styles from './InfoAddEditPatient.module.scss'

import './time-picker.css'

interface IProps {
  open: boolean
  isEditing: boolean
  tabKey: string
  prePopulateSchedule: number | null
  editedPatientData?: IPatientSchedule
  date: string
  title?: string
  setOpen: () => void
  disabledEverything?: boolean
  customAppointment?: { appointment: Date[]; room: string }
}

const InfoAddEditPatient = ({
  open,
  isEditing,
  tabKey,
  editedPatientData,
  prePopulateSchedule,
  date,
  title = INFO_CONSTANTS.ADD_WALK_IN_PATIENT,
  setOpen,
  disabledEverything = false,
  customAppointment,
}: IProps) => {
  const [isNotifyOpen, setNotifyOpen] = useState<boolean>(false)
  const [autoCompletedPatient, setAutoCompletedPatient] = useState<IPatient>(null)
  const [selectedRoom, setSelectedRoom] = useState<IPatientRoom>(null)
  const [overlappingEvents, setOverlappingEvents] = useState<number>(0)
  const [overlappingDisabledInterval, setOverlappingDisabledInterval] = useState<boolean>(false)
  const [patientAccessCode, setPatientAccessCode] = useState<number | null>(null)
  const [awaitConfirmationSchedule, setAwaitConfirmationSchedule] = useState<any>(0)
  const [disabledUntil, setDisabledUntil] = useState(
    parseInt(localStorage.getItem(`patient-${editedPatientData?.id}-disabledUntil`) || '0', 10),
  )

  const [overlappingRedLine, setOverlappingRedLine] = useState<boolean>(false)
  const formRef = useRef(null)
  const {
    patients,
    languages,
    consultation_staffs,
    rooms,
    schedules,
    isUpdating,
    disabledIntervals,
    dayInterval,
    timeZone,
  } = useAppSelector((state) => state.infoReducer)
  const {
    createPatientSchedule,
    updatePatientSchedule,
    removePatientSchedule,
    handleNotifyPatient,
  } = useInfoManager()
  const options = patients.map((patient: IPatient) => ({
    value: `${patient.name}/${patient.id}`,
    label: `${patient?.name} - ${patient?.phone}`,
    id: patient?.id,
  }))
  const resetCountryFunction = useRef<() => void | null>(null)

  const { setNotification } = useNotification()

  useEffect(() => {
    if (isEditing) {
      setSelectedRoom(editedPatientData?.room)
    }
    if (title === INFO_CONSTANTS.ADD_WALK_IN_PATIENT) {
      resetCountryFunction.current?.()
    }
    if (customAppointment) {
      const [label, value] = customAppointment.room.split('/')
      const room = rooms.find((room: any) => room.label === label && room.value === value)
      setSelectedRoom(room)
    }
  }, [isEditing, customAppointment, title])

  useEffect(() => {
    const selectedSchedule = schedules.find(
      (schedule: IPatientSchedule) => schedule.id === editedPatientData?.id,
    )

    if (selectedSchedule && selectedSchedule?.access_code) {
      setPatientAccessCode(selectedSchedule?.access_code)
    }
  }, [schedules])

  useEffect(() => {
    if (editedPatientData) {
      setPatientAccessCode(editedPatientData.access_code)

      const storedDisabledUntil = parseInt(
        localStorage.getItem(`patient-${editedPatientData.id}-disabledUntil`) || '0',
        10,
      )
      setDisabledUntil(storedDisabledUntil)
    }
  }, [editedPatientData])

  useEffect(() => {
    if (disabledUntil > Date.now()) {
      const remainingTime = disabledUntil - Date.now()
      const timer = setTimeout(() => {
        setDisabledUntil(0)
      }, remainingTime)

      return () => clearTimeout(timer)
    }
  }, [disabledUntil])

  const displayDeletePatientTrash = useMemo(
    () =>
      isEditing &&
      !disabledEverything &&
      InfoService.checkIfActionShouldBeVisible(
        ACTION_TYPES.DELETE,
        editedPatientData?.status?.code,
      ),
    [isEditing, disabledEverything, editedPatientData],
  )

  const displayCancelAppointmentButton = useMemo(
    () =>
      isEditing &&
      !disabledEverything &&
      editedPatientData?.status?.code !== PATIENT_STATUSES.NO_SHOW &&
      InfoService.checkIfActionShouldBeVisible(
        ACTION_TYPES.CANCEL,
        editedPatientData?.status?.code,
      ),
    [isEditing, disabledEverything, editedPatientData],
  )

  const displayCheckOutButton = useMemo(
    () =>
      isEditing &&
      !disabledEverything &&
      InfoService.checkIfActionShouldBeVisible(
        ACTION_TYPES.CHECK_OUT,
        editedPatientData?.status?.code,
      ),
    [isEditing, disabledEverything, editedPatientData],
  )

  const enableBypassTempCheck = useMemo(
    () =>
      !disabledEverything &&
      InfoService.checkIfActionShouldBeVisible(
        ACTION_TYPES.EDIT_TEMPERATURE,
        editedPatientData?.status?.code,
      ),
    [editedPatientData, selectedRoom, customAppointment],
  )

  const enableChangeRoom = useMemo(
    () =>
      !disabledEverything &&
      InfoService.checkIfActionShouldBeVisible(
        ACTION_TYPES.RESCHEDULE,
        editedPatientData?.status?.code,
      ),
    [editedPatientData, selectedRoom, customAppointment, disabledEverything],
  )

  const enableChangeConsultationStaff = useMemo(
    () =>
      !disabledEverything &&
      InfoService.checkIfActionShouldBeVisible(
        ACTION_TYPES.EDIT_CONSULTATION_STAFF,
        editedPatientData?.status?.code,
      ),
    [editedPatientData, customAppointment, disabledEverything],
  )

  const updateOverlappingSlots = (room: IPatientRoom, values: any) => {
    if (!room) return
    const [start, end] = values

    const isInsideDisabledInterval = disabledIntervals.some((disabledInterval: any) =>
      InfoService.checkIsOverlappingIntervals(
        disabledInterval,
        start,
        end,
        `${room.label}/${room.value}`,
      ),
    )
    setOverlappingDisabledInterval(isInsideDisabledInterval)
    if (isInsideDisabledInterval) {
      return
    }

    const eventsFromRoom = schedules
      .filter(
        (schedule: IPatientSchedule): boolean =>
          schedule.room?.value === room.value && schedule.id !== editedPatientData?.id,
      )
      .map((item: IPatientSchedule) => ({
        id: item.id,
        title: item.patient.name,
        start: new Date(`${date} ${item.start_time}`),
        end: new Date(`${date} ${item.end_time}`),
        resourceId: item?.room?.label,
        status: item.status,
        temperature_bypass: item.temperature_bypass,
        isDraggable: true,
        current_location: item.current_location,
        appointment_start_time: item.appointment_start_time,
        appointment_end_time: item.appointment_end_time,
        original_room: item.original_room,
        room_type_id: item.room_type_id,
        procedure: item.procedure,
      }))

    const { nrOfOverlappingEvents } = InfoService.getNumberOfOverlappingIntervals(
      { start, end, resourceId: room.label },
      eventsFromRoom,
      room.label,
    )
    setOverlappingEvents(nrOfOverlappingEvents)
    return nrOfOverlappingEvents
  }

  const enableChangeAppointmentTime = useMemo(
    () =>
      selectedRoom &&
      !disabledEverything &&
      InfoService.checkIfActionShouldBeVisible(
        ACTION_TYPES.RESIZE_SCHEDULE,
        editedPatientData?.status?.code,
      ),
    [selectedRoom, disabledEverything, editedPatientData],
  )

  const onFieldChange = ({
    field,
    fieldValue,
    setFieldValue,
    values,
    option,
    setErrors,
    errors,
  }: {
    field: string
    fieldValue: any
    setFieldValue: Function
    values?: any
    option?: any
    setErrors?: any
    errors?: any
  }): void => {
    if (field === 'first_name' || field === 'last_name') {
      let patient: IPatient | undefined = undefined
      const patientName = fieldValue
      if (option) {
        patient = patients.find((patient: IPatient): boolean => patient.id === option.id)
      } else {
        patient = patients.find((patient: IPatient): boolean => patient.name.includes(patientName))
      }

      if (patient) {
        setAutoCompletedPatient(patient)
        setFieldValue(NEW_PATIENT_FORM_KEYS.ID, patient.id)
        setFieldValue(NEW_PATIENT_FORM_KEYS.FIRST_NAME, patient.first_name)
        setFieldValue(NEW_PATIENT_FORM_KEYS.LAST_NAME, patient.last_name)
        setFieldValue(NEW_PATIENT_FORM_KEYS.PHONE, patient.phone)
        setFieldValue(NEW_PATIENT_FORM_KEYS.LANGUAGE, patient.language)
        setFieldValue(NEW_PATIENT_FORM_KEYS.LANGUAGE_TYPE_CODE, patient.language_type_code)

        const updatedErrors = (({ room, appointment }) => ({ room, appointment }))(errors)
        setErrors({ ...updatedErrors })
      }
      if (values) updateOverlappingSlots(selectedRoom, values?.appointment)

      return
    }

    if (field === 'language') {
      setFieldValue(NEW_PATIENT_FORM_KEYS.LANGUAGE_TYPE_CODE, fieldValue)
    }

    if (field === 'consultation_staff') {
      setFieldValue(NEW_PATIENT_FORM_KEYS.CONSULTATION_STAFF, fieldValue)
    }

    if (field === 'room') {
      const room: IPatientRoom | undefined = rooms.find(
        (room: IPatientRoom): boolean => room.value === fieldValue,
      )
      setSelectedRoom(room)
      updateOverlappingSlots(room, values.appointment)
      getPreCompleteValues(room, setFieldValue)

      const updatedErrors = { ...errors }
      delete updatedErrors[NEW_PATIENT_FORM_KEYS.APPOINTMENT]
      setErrors({ ...updatedErrors })
    }

    if (field === 'appointment') {
      updateOverlappingSlots(selectedRoom, fieldValue)
    }
  }

  const clearModalData = () => {
    setOpen()
    setSelectedRoom(null)
    setAutoCompletedPatient(null)
    setOverlappingEvents(0)
    setOverlappingDisabledInterval(false)
  }

  const handleCreateAppointment = async (data: any, resetForm: Function) => {
    if (isUpdating) return

    const {
      id,
      room,
      appointment,
      temperature_bypass,
      first_name,
      last_name,
      phone,
      language_type_code,
      consultation_staff,
      procedure,
    } = data
    const propPatient = {
      id,
      first_name,
      last_name,
      phone,
      language_type_code,
    }
    const patient =
      autoCompletedPatient &&
      UtilService.arrDeepEqual(propPatient, {
        first_name: autoCompletedPatient.first_name,
        last_name: autoCompletedPatient.last_name,
        phone: autoCompletedPatient.phone,
        language_type_code: autoCompletedPatient.language_type_code,
      })
        ? autoCompletedPatient
        : propPatient

    const adjustedData = {
      room,
      patient,
      consultation_staff,
      temperature_bypass: temperature_bypass ? 1 : 0,
      start_time: format(appointment.at(0), TIME_FORMAT.TIME_PICKER_HH_SS),
      end_time: format(appointment.at(1), TIME_FORMAT.TIME_PICKER_HH_SS),
      procedure_code: !procedure?.isCustom ? procedure?.code : null,
      procedure_description: procedure?.description,
    }

    const searchRoom: IPatientRoom | undefined = rooms.find(
      (patientRoom: IPatientRoom): boolean => patientRoom.value === room,
    )

    const nrOverlappingEvents = updateOverlappingSlots(searchRoom, appointment)

    const closeAndReset = () => {
      clearModalData()
      resetForm()
    }

    const handleCreatePatient = async () => {
      await createPatientSchedule(adjustedData, tabKey)
      closeAndReset()
    }

    if (nrOverlappingEvents > 1) {
      const awaitConfirmationData = {
        scheduleData: adjustedData,
        resetFormCallback: closeAndReset,
        confirmAction: handleCreatePatient,
      }
      setAwaitConfirmationSchedule(awaitConfirmationData)
    } else {
      handleCreatePatient()
    }
  }

  const isBetween = (number: number, lowerBound: number, upperBound: number): boolean => {
    return number > lowerBound && number <= upperBound
  }

  const handleRescheduleAppointment = async (data: any, resetForm: Function) => {
    if (isUpdating) return
    const { room, appointment, consultation_staff, temperature_bypass, procedure } = data

    let start_time = appointment.at(0)
    let end_time = appointment.at(1)

    const oldEvent = schedules.find((item: any) => item.id === editedPatientData?.id)
    const patient = oldEvent.patient

    const shouldMoveToCurrentLine =
      DateService.isPast(start_time, timeZone) &&
      (!isSameMinute(start_time, oldEvent.start_time as any) || oldEvent.room.value !== room)

    if (shouldMoveToCurrentLine) {
      start_time = DateService.getNowToServerZone(timeZone)
      end_time = add(start_time, { minutes: 5 })
    }

    const adjustedData = {
      room,
      patient,
      consultation_staff,
      temperature_bypass: temperature_bypass ? 1 : 0,
      original_appointment_id: oldEvent.id,
      start_time: format(start_time, TIME_FORMAT.TIME_PICKER_HH_SS),
      end_time: format(end_time, TIME_FORMAT.TIME_PICKER_HH_SS),
      procedure_code: !procedure?.isCustom ? procedure?.code : null,
      procedure_description: procedure?.description,
    }

    const searchRoom: IPatientRoom | undefined = rooms.find(
      (patientRoom: IPatientRoom): boolean => patientRoom.value === room,
    )
    const nrOverlappingEvents = updateOverlappingSlots(searchRoom, [start_time, end_time])

    const closeAndReset = () => {
      clearModalData()
      resetForm()
    }

    const roomDisabledIntervals = disabledIntervals.filter((disabledInterval: any) =>
      disabledInterval.resourceId.includes(room),
    )

    if (
      roomDisabledIntervals.some((interval: any) =>
        DateService.dateRangeOverlaps(interval.start, interval.end, start_time, end_time),
      )
    ) {
      setNotification({
        title: 'Update schedule error',
        description: `Current time is out of the room's working hours.`,
        type: ALERT_CONSTANTS.ERROR,
      })

      clearModalData()
      resetForm()
      return
    }

    if (
      !isBetween(nrOverlappingEvents, 0, searchRoom?.max_patients) &&
      !selectedRoom?.is_waiting_room
    ) {
      setNotification({
        title: 'Update schedule error',
        description: `You reached the max number of ${
          searchRoom?.max_patients || 1
        } patients at the same time for this room.`,
        type: ALERT_CONSTANTS.ERROR,
      })

      clearModalData()
      resetForm()
      return
    }

    if (selectedRoom?.is_waiting_room) {
      const awaitConfirmationData = {
        scheduleData: adjustedData,
        resetFormCallback: closeAndReset,
        confirmAction: proceedToRescheduleAppointment,
      }
      setAwaitConfirmationSchedule(awaitConfirmationData)
    } else {
      proceedToRescheduleAppointment(adjustedData, resetForm)
      return
    }
  }

  const timePickerOptions = useMemo(() => {
    const updatedSchedules = schedules.filter(
      (schedule: IPatientSchedule) => schedule.id !== editedPatientData?.id,
    )
    return InfoService.getTimePickerOptions(updatedSchedules, date, timeZone, selectedRoom)
  }, [schedules, selectedRoom, date, editedPatientData])

  const proceedToUpdateAppointment = async (scheduleData: any, resetForm: Function) => {
    await updatePatientSchedule(scheduleData, editedPatientData?.id, tabKey)

    clearModalData()
    resetForm()
  }

  const proceedToRescheduleAppointment = async (scheduleData: any, resetForm: Function) => {
    await createPatientSchedule(scheduleData, tabKey)
    clearModalData()
    resetForm()
  }

  const handleNotify = async () => {
    await handleNotifyPatient(editedPatientData?.id)
    setNotifyOpen(false)

    const newDisabledUntil = Date.now() + 60 * 1000
    localStorage.setItem(
      `patient-${editedPatientData?.id}-disabledUntil`,
      newDisabledUntil.toString(),
    )
    setDisabledUntil(newDisabledUntil)
  }

  const handleUpdateAppointment = async (data: any, resetForm: Function) => {
    if (isUpdating) return

    const { room, appointment, consultation_staff, temperature_bypass, procedure } = data

    let start_time = appointment.at(0)
    let end_time = appointment.at(1)
    const oldEvent = schedules.find((item: any) => item.id === editedPatientData?.id)

    const shouldMoveToCurrentLine =
      DateService.isPast(start_time, timeZone) &&
      (!isSameMinute(start_time, oldEvent.start_time as any) || oldEvent.room.value !== room)

    if (shouldMoveToCurrentLine) {
      const diff = differenceInMinutes(end_time, start_time)
      start_time = DateService.getNowToServerZone(timeZone)
      end_time = add(start_time, { minutes: diff })
    }

    const adjustedData = {
      room,
      consultation_staff,
      temperature_bypass: temperature_bypass ? 1 : 0,
      start_time: format(start_time, TIME_FORMAT.TIME_PICKER_HH_SS),
      end_time: format(end_time, TIME_FORMAT.TIME_PICKER_HH_SS),
      procedure_code: !procedure?.isCustom ? procedure?.code : null,
      procedure_description: procedure?.description,
    }

    if (overlappingEvents > 1) {
      const awaitConfirmationData = {
        scheduleData: adjustedData,
        resetFormCallback: resetForm,
        confirmAction: proceedToUpdateAppointment,
      }
      setAwaitConfirmationSchedule(awaitConfirmationData)
      return
    }

    proceedToUpdateAppointment(adjustedData, resetForm)
  }

  const handleConfirmUpdateAppointment = async () => {
    const { scheduleData, resetFormCallback, confirmAction } = awaitConfirmationSchedule
    confirmAction(scheduleData, resetFormCallback)
    setAwaitConfirmationSchedule(null)
  }
  const defaultLanguageName = languages.find((lang: IOption) => lang.code === 1)?.name

  const defaultConsultationStaff = consultation_staffs.find(
    (consultation_staff: IPatientConsultationStaff) => consultation_staff.is_doctor === 1,
  )

  const getInitialFormValues = () => {
    if (isEditing) {
      return {
        [NEW_PATIENT_FORM_KEYS.NAME]: editedPatientData?.patient.name,
        [NEW_PATIENT_FORM_KEYS.FIRST_NAME]: editedPatientData?.patient.first_name,
        [NEW_PATIENT_FORM_KEYS.LAST_NAME]: editedPatientData?.patient.last_name,

        [NEW_PATIENT_FORM_KEYS.PHONE]: editedPatientData?.patient.phone,
        [NEW_PATIENT_FORM_KEYS.LANGUAGE]: editedPatientData?.patient.language,
        [NEW_PATIENT_FORM_KEYS.ROOM]: editedPatientData?.room.value,
        [NEW_PATIENT_FORM_KEYS.APPOINTMENT]: [
          new Date(`${date} ${editedPatientData?.start_time}`),
          new Date(`${date} ${editedPatientData?.end_time}`),
        ],
        [NEW_PATIENT_FORM_KEYS.CONSULTATION_STAFF]: editedPatientData?.consultation_staff,
        [NEW_PATIENT_FORM_KEYS.TEMPERATURE_BYPASS]: Boolean(editedPatientData?.temperature_bypass),
        [NEW_PATIENT_FORM_KEYS.PROCEDURE]: editedPatientData?.procedure,
      }
    } else if (!isEditing && !customAppointment) {
      return {
        ...INITIAL_PATIENT_DATA,
        [NEW_PATIENT_FORM_KEYS.LANGUAGE]: defaultLanguageName,
        [NEW_PATIENT_FORM_KEYS.CONSULTATION_STAFF]: defaultConsultationStaff?.id,
        [NEW_PATIENT_FORM_KEYS.PROCEDURE]: defaultConsultationStaff?.procedure,
      }
    }

    if (customAppointment) {
      const [label, value] = customAppointment.room.split('/')
      const room = rooms.find((room: any) => room.label === label && room.value === value)

      return {
        ...INITIAL_PATIENT_DATA,
        [NEW_PATIENT_FORM_KEYS.APPOINTMENT]: customAppointment?.appointment,
        [NEW_PATIENT_FORM_KEYS.ROOM]: room?.value,
        [NEW_PATIENT_FORM_KEYS.LANGUAGE]: defaultLanguageName,
        [NEW_PATIENT_FORM_KEYS.CONSULTATION_STAFF]: defaultConsultationStaff?.id,
        [NEW_PATIENT_FORM_KEYS.PROCEDURE]: defaultConsultationStaff?.procedure,
      }
    }

    return INITIAL_PATIENT_DATA
  }

  const getPreCompleteValues = (room: IPatientRoom, setFieldValue: Function) => {
    if (!room || !prePopulateSchedule) return

    const sortedSlots = InfoService.getSortedSlots(room.slots, date)

    const selectedRoomSchedules = schedules.filter(
      (schedule: IPatientSchedule) => schedule.room.label === room.label,
    )

    if (selectedRoomSchedules.length === 0) {
      const appointment = [
        sortedSlots[0].start_time,
        add(sortedSlots[0].start_time, { minutes: prePopulateSchedule }),
      ]
      setFieldValue(NEW_PATIENT_FORM_KEYS.APPOINTMENT, appointment)
      return
    }

    const sortedAppointments = InfoService.getSortedSlots(
      schedules.filter((item: any) => item.room.value === room.value),
      date,
    )

    const appointment = [
      sortedAppointments[sortedAppointments.length - 1]?.end_time,

      add(sortedAppointments[sortedAppointments.length - 1]?.end_time, {
        minutes: prePopulateSchedule,
      }),
    ]

    setFieldValue(NEW_PATIENT_FORM_KEYS.APPOINTMENT, appointment)
  }

  const handleUpdatePatientStatus = async (status: PATIENT_STATUSES, updatedRoom?: string) => {
    if (isUpdating) return
    const patientData = {
      room: updatedRoom || editedPatientData.room?.value,
      start_time: editedPatientData.start_time,
      end_time: editedPatientData.end_time,
      temperature_bypass: editedPatientData.temperature_bypass,
      consultation_staff: editedPatientData.consultation_staff,
      status_type_code: status,
      procedure_code: editedPatientData?.procedure?.code,
      procedure_description: editedPatientData?.procedure?.description,
    }

    await updatePatientSchedule(patientData, editedPatientData?.id, tabKey)
    clearModalData()
    setOpen()
  }

  const handleCancelAppointment = () => {
    handleUpdatePatientStatus(PATIENT_STATUSES.CANCELED)
    clearModalData()
  }

  const handleCheckOut = () => {
    const { room } = formRef.current.values
    handleUpdatePatientStatus(PATIENT_STATUSES.CHECKED_OUT, room)
  }

  const getAppointmentTime = () => {
    if (editedPatientData?.room_type_id !== ROOM_TYPE_IDS.WAITING_ROOM) return
    const { appointment_start_time, appointment_end_time, original_room } = editedPatientData
    if (!appointment_start_time || !appointment_end_time) return ''
    const startDate = parse(appointment_start_time, 'HH:mm:ss', new Date())
    const endDate = parse(appointment_end_time, 'HH:mm:ss', new Date())

    const start = format(startDate, TIME_FORMAT.TIME_GUTTER_PICKER_HH_SS)
    const end = format(endDate, TIME_FORMAT.TIME_GUTTER_PICKER_HH_SS)
    return `Original appointment: (${start} - ${end}, ${original_room})`
  }

  const handleRemoveSchedule = async () => {
    await removePatientSchedule(editedPatientData?.id, tabKey)
    clearModalData()
    setOpen()
  }
  const getRoomErrorMessage = () => {
    if (!selectedRoom) return null

    const shouldApplyRoomRule = !InfoService.checkIfCurrentDateOverlapDisabledIntervals(
      disabledIntervals,
      selectedRoom,
      dayInterval,
      timeZone,
    )
    const excludedRooms = [ROOM_STATUS.FREE, ROOM_STATUS.CLEANUP, ROOM_STATUS.BUSY]

    const shouldDisplayRoomWarning =
      selectedRoom &&
      selectedRoom?.status &&
      !excludedRooms.includes(selectedRoom?.status?.code) &&
      shouldApplyRoomRule

    if (shouldDisplayRoomWarning) {
      return INFO_CONSTANTS.BUSY_ROOM_WARNING
    }
    if (overlappingEvents > selectedRoom?.max_patients && !selectedRoom?.is_waiting_room) {
      return `You reached the max number of ${
        selectedRoom?.max_patients || 1
      } patients at the same time for this room.`
    }
  }

  const appointmentOriginalTime = getAppointmentTime()

  function preSubmit(data: FormikValues) {
    const [startTime] = data.appointment
    const { setFieldError } = formRef.current
    if (overlappingDisabledInterval) {
      setFieldError('appointment', INFO_CONSTANTS.DISABLED_INTERVAL_WARNING)
      return
    }

    const appointmentTime = format(startTime, TIME_FORMAT.TIME_PICKER_HH_SS)
    const selectedDate = `${date} ${appointmentTime}`

    const isPast = DateService.isPast(new Date(selectedDate), timeZone)

    if (isPast && editedPatientData?.status?.code !== PATIENT_STATUSES.NO_SHOW)
      return setOverlappingRedLine(true)
    onSubmit()
  }

  function onSubmit(isFromWarning: boolean = false) {
    const { values, resetForm } = formRef.current
    const data = { ...values }

    if (isFromWarning) {
      const [selectedStartTime, selectedEndTime] = values.appointment

      const startTime = DateService.getNowToServerZone(timeZone)
      const diff = differenceInMinutes(selectedEndTime, selectedStartTime)
      const startWithGapDiff = add(startTime, { minutes: diff })

      const sortedSlots = InfoService.getSortedSlots(selectedRoom.slots, date)
      let endTime = startWithGapDiff as any

      if (sortedSlots.length) {
        const endOfTheDay = sortedSlots.at(-1).end_time
        endTime = isBefore(startWithGapDiff, endOfTheDay) ? startWithGapDiff : endOfTheDay
      }
      // const isLessThanMinAppointment =
      //   differenceInMinutes(endTime, startTime) < MIN_SCHEDULE_TIME_IN_MINUTES
      // if (isLessThanMinAppointment) {
      //   setFieldError('appointment', INFO_CONSTANTS.END_OF_THE_PROGRAM)
      //   return setOverlappingRedLine(false)
      // }
      data.appointment = [startTime, endTime]
    }
    if (isEditing) {
      if (editedPatientData.status.code === PATIENT_STATUSES.NO_SHOW) {
        handleRescheduleAppointment(data, resetForm)
      } else {
        handleUpdateAppointment(data, resetForm)
      }
    } else {
      handleCreateAppointment(data, resetForm)
    }

    setOverlappingRedLine(false)
  }

  return (
    <div className={styles.parent}>
      <Formik
        innerRef={formRef}
        initialValues={getInitialFormValues()}
        onSubmit={preSubmit}
        enableReinitialize
        validationSchema={NEW_PATIENT_SCHEMA}
        validateOnBlur
        validateOnChange={false}>
        {({ setFieldValue, resetForm, setFieldError, values, errors, setErrors, validateForm }) => {
          const roomErrorMessage = getRoomErrorMessage()

          return (
            <Modal
              destroyOnClose
              open={open && !Boolean(awaitConfirmationSchedule)}
              keyboard={false}
              cancelButton
              onCancel={() => {
                clearModalData()
                resetForm()
              }}
              title={title}>
              <Form className={styles.parentForm}>
                <div className={styles.parentFormSection}>
                  <FormControl
                    required
                    label={NEW_PATIENT_LABELS.FIRST_NAME}
                    type={INPUT_TYPES.TEXT}
                    name={NEW_PATIENT_FORM_KEYS.FIRST_NAME}
                    control={FORM_CONTROL_TYPE.AUTOCOMPLETE}
                    options={options}
                    disabled={isEditing || disabledEverything}
                    onChange={(value: any, option: any) => {
                      setFieldValue(NEW_PATIENT_FORM_KEYS.FIRST_NAME, value.replace(/\s+/g, ' '))
                      const updatedErrors = { ...errors }
                      delete (updatedErrors as any).first_name
                      setErrors({ ...updatedErrors })

                      onFieldChange({
                        field: NEW_PATIENT_FORM_KEYS.FIRST_NAME,
                        fieldValue: value,
                        setFieldValue,
                        option,
                        setErrors,
                        errors,
                        values,
                      })
                    }}
                  />
                  <FormControl
                    required
                    label={NEW_PATIENT_LABELS.LAST_NAME}
                    type={INPUT_TYPES.TEXT}
                    name={NEW_PATIENT_FORM_KEYS.LAST_NAME}
                    control={FORM_CONTROL_TYPE.AUTOCOMPLETE}
                    options={options}
                    disabled={isEditing || disabledEverything}
                    onChange={(value: any, option: any) => {
                      setFieldValue(NEW_PATIENT_FORM_KEYS.LAST_NAME, value.replace(/\s+/g, ' '))
                      const updatedErrors = { ...errors }
                      delete (updatedErrors as any).last_name
                      setErrors({ ...updatedErrors })

                      onFieldChange({
                        field: NEW_PATIENT_FORM_KEYS.LAST_NAME,
                        fieldValue: value,
                        setFieldValue,
                        option,
                        setErrors,
                        errors,
                        values,
                      })
                    }}
                  />
                </div>
                <div className={styles.parentFormSection}>
                  <div className={styles.parentSelect}>
                    <PhoneInput
                      required
                      error={errors?.phone as string}
                      label={NEW_PATIENT_LABELS.PHONE}
                      value={values?.phone as string}
                      onBlur={() => validateForm()}
                      onChange={(value) => setFieldValue(NEW_PATIENT_FORM_KEYS.PHONE, value)}
                      onResetCountry={(func) => (resetCountryFunction.current = func)}
                      disabled={isEditing || disabledEverything}
                    />
                  </div>
                  <FormControl
                    label={NEW_PATIENT_LABELS.LANGUAGE}
                    type={INPUT_TYPES.TEXT}
                    name={NEW_PATIENT_FORM_KEYS.LANGUAGE}
                    control={FORM_CONTROL_TYPE.SELECT}
                    disabled={isEditing || disabledEverything}
                    options={languages.map((language: IOption) => ({
                      code: language.code,
                      name: language.name,
                    }))}
                    onChange={(value) => {
                      const updatedErrors = { ...errors }
                      delete updatedErrors.language
                      setErrors({ ...updatedErrors })

                      onFieldChange({
                        field: NEW_PATIENT_FORM_KEYS.LANGUAGE,
                        fieldValue: value,
                        setFieldValue,
                        values,
                        setErrors,
                        errors,
                      })
                    }}
                  />
                </div>
                <div className={styles.parentFormSection}>
                  <FormControl
                    required
                    label={NEW_PATIENT_LABELS.ROOM}
                    type={INPUT_TYPES.TEXT}
                    name={NEW_PATIENT_FORM_KEYS.ROOM}
                    control={FORM_CONTROL_TYPE.SELECT}
                    disabled={!enableChangeRoom}
                    options={rooms.map((room: any) => ({
                      name: room.label,
                      code: room.value,
                    }))}
                    onChange={(value) => {
                      const updatedErrors = { ...errors }
                      delete updatedErrors.room
                      setErrors({ ...updatedErrors })

                      onFieldChange({
                        field: NEW_PATIENT_FORM_KEYS.ROOM,
                        fieldValue: value,
                        setFieldValue,
                        values,
                        setErrors,
                        errors,
                      })
                    }}
                  />

                  <FormControl
                    required
                    label={NEW_PATIENT_LABELS.APPOINTMENT_TIME}
                    timezone={timeZone}
                    type={INPUT_TYPES.TEXT}
                    name={NEW_PATIENT_FORM_KEYS.APPOINTMENT}
                    control={FORM_CONTROL_TYPE.TIME_PICKER_SLOTS}
                    options={timePickerOptions}
                    disabled={!enableChangeAppointmentTime}
                    setFieldError={setFieldError}
                    onChange={(value) => {
                      const updatedErrors = { ...errors }
                      delete updatedErrors.appointment
                      setErrors({ ...updatedErrors })

                      onFieldChange({
                        field: NEW_PATIENT_FORM_KEYS.APPOINTMENT,
                        fieldValue: value,
                        setFieldValue,
                        values,
                      })
                    }}
                    date={date}
                  />
                </div>
                {roomErrorMessage && <p className={styles.parentRoomError}>{roomErrorMessage}</p>}

                <div className={styles.parentFormSection}>
                  <div className={styles.parentSelect}>
                    <FormControl
                      label={NEW_PATIENT_LABELS.APPOINTMENT_WITH}
                      type={INPUT_TYPES.TEXT}
                      name={NEW_PATIENT_FORM_KEYS.CONSULTATION_STAFF}
                      disabled={!enableChangeConsultationStaff}
                      control={FORM_CONTROL_TYPE.SELECT}
                      options={consultation_staffs?.map(
                        (consultation_staff: IPatientConsultationStaff) => ({
                          code: consultation_staff.id,
                          name: consultation_staff.name,
                        }),
                      )}
                      onChange={(value) => {
                        const updatedErrors = { ...errors }
                        delete updatedErrors.consultation_staff
                        setErrors({ ...updatedErrors })

                        onFieldChange({
                          field: NEW_PATIENT_FORM_KEYS.CONSULTATION_STAFF,
                          fieldValue: value,
                          setFieldValue,
                          values,
                          setErrors,
                          errors,
                        })
                      }}
                    />
                  </div>
                  <div className={styles.parentTemperatureCheck}>
                    <FormControl
                      label={NEW_PATIENT_LABELS.TEMPERATURE_BYPASS}
                      type={INPUT_TYPES.CHECKBOX}
                      name={NEW_PATIENT_FORM_KEYS.TEMPERATURE_BYPASS}
                      control={FORM_CONTROL_TYPE.CHECKBOX}
                      disabled={!enableBypassTempCheck}
                    />
                  </div>
                </div>
                <div className={styles.parentFormSection}>
                  <AppointmentProcedure
                    label={NEW_PATIENT_LABELS.PROCEDURE}
                    setSelectedValue={(proc) => setFieldValue('procedure', proc)}
                    selectedValue={values.procedure}
                  />
                </div>

                {!!editedPatientData &&
                  !!patientAccessCode &&
                  (tabKey === TAB_KEYS.PATIENT_ACTIVITY || TAB_KEYS.PATIENT_SCHEDULE) && (
                    <>
                      <hr className={styles.parentHR} />
                      <div className={styles.parentBottomActions}>
                        <p className={styles.parentPatientCode}>
                          {INFO_CONSTANTS.PATIENT_CODE}:{' '}
                          <span>{editedPatientData?.access_code}</span>
                        </p>

                        {editedPatientData?.status.code !== PATIENT_STATUSES.CHECKED_OUT && (
                          <Button
                            className={styles.parentNotifyButton}
                            modifier={BUTTON_MODIFIER.SECONDARY}
                            proportion={BUTTON_PROPORTION.SMALL}
                            onClick={() => setNotifyOpen(true)}
                            disabled={isUpdating || Date.now() < disabledUntil}>
                            {BUTTON_CONSTANTS.NOTIFY_PATIENT}
                          </Button>
                        )}
                      </div>
                      {editedPatientData?.last_notification && (
                        <p className={styles.parentLastSMS}>
                          {INFO_CONSTANTS.LAST_SMS_SENT}{' '}
                          <span>
                            {format(
                              parse(
                                editedPatientData.last_notification.replace(
                                  'Last Text Message sent on ',
                                  '',
                                ),
                                `MM/dd/yyyy 'at' ${TIME_FORMAT.TWELVE_HOUR_FORMAT_WITH_SECONDS}`,
                                new Date(),
                              ),
                              `MM/dd/yyyy 'at' ${TIME_FORMAT.TWELVE_HOUR_FORMAT}`,
                            )}
                          </span>
                        </p>
                      )}
                      {editedPatientData?.error_sms_message && (
                        <p className={styles.parentErrorMessage}>
                          {INFO_CONSTANTS.ERROR} <span>{editedPatientData?.error_sms_message}</span>
                        </p>
                      )}
                    </>
                  )}
                <Show when={appointmentOriginalTime}>
                  <p className={styles.parentLastSMS}>{appointmentOriginalTime}</p>
                </Show>
                <div className={styles.parentFormActions}>
                  <div className={styles.parentFormRemoveButton}>
                    {displayDeletePatientTrash && (
                      <button
                        className={styles.parentDeleteButton}
                        onClick={handleRemoveSchedule}
                        type='button'
                        disabled={isUpdating}>
                        <Tooltip title={INFO_CONSTANTS.REMOVE_PATIENT} placement='topLeft'>
                          <img
                            className={styles.parentFormDeleteAction}
                            src={trashIcon}
                            alt={ALT_CONSTANTS.ACTION_ICON}
                          />
                        </Tooltip>
                      </button>
                    )}
                  </div>
                  <div className={styles.parentFormCheckout}>
                    {displayCheckOutButton && (
                      <Button
                        className={styles.parentSubmitButton}
                        severity={BUTTON_SEVERITY.SUCCESS_FILLED}
                        modifier={BUTTON_MODIFIER.PRIMARY}
                        onClick={handleCheckOut}
                        disabled={isUpdating}>
                        {BUTTON_CONSTANTS.CHECK_OUT}
                      </Button>
                    )}
                  </div>
                  <div className={styles.parentFormSubmit}>
                    {displayCancelAppointmentButton && (
                      <Button
                        className={styles.parentSubmitButton}
                        severity={BUTTON_SEVERITY.DANGER}
                        modifier={BUTTON_MODIFIER.PRIMARY}
                        onClick={handleCancelAppointment}
                        disabled={isUpdating}>
                        {BUTTON_CONSTANTS.CANCEL_APPOINTMENT}
                      </Button>
                    )}
                    {!disabledEverything && (
                      <Button
                        className={styles.parentSubmitButton}
                        htmlType='submit'
                        data-cy='submit-btn'
                        loading={isUpdating}
                        disabled={Boolean(roomErrorMessage) || !!errors?.appointment}>
                        {editedPatientData?.status?.code === PATIENT_STATUSES.NO_SHOW
                          ? BUTTON_CONSTANTS.RESCHEDULE
                          : BUTTON_CONSTANTS.SUBMIT}
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            </Modal>
          )
        }}
      </Formik>
      <InfoWarning
        open={Boolean(awaitConfirmationSchedule)}
        title={INFO_CONSTANTS.ARE_YOU_SURE}
        warning={INFO_CONSTANTS.MULTIPLE_PATIENTS_TITLE}
        okButtonText={INFO_CONSTANTS.YES}
        handleClose={() => setAwaitConfirmationSchedule(null)}
        handleConfirm={handleConfirmUpdateAppointment}
        hasCancel
      />
      <InfoWarning
        open={overlappingRedLine}
        title={INFO_CONSTANTS.ATTENTION}
        warning={INFO_CONSTANTS.CURRENT_TIME_EXCEEDED}
        okButtonText={INFO_CONSTANTS.YES}
        handleClose={() => setOverlappingRedLine(false)}
        handleConfirm={() => onSubmit(true)}
        hasCancel
      />
      <ModalAction
        destroyOnClose
        isOpen={isNotifyOpen}
        title={MODAL_LABELS.NOTIFY_PATIENT}
        confirmText={BUTTON_CONSTANTS.SEND}
        cancelText={BUTTON_CONSTANTS.CANCEL}
        onConfirm={() => {
          setNotifyOpen(false)
          handleNotify()
        }}
        onClose={() => setNotifyOpen(false)}
        description={PATIENTS_LABELS.CONFIRM_NOTIFY}
        confirmButtonSeverity={BUTTON_SEVERITY.SUCCESS_FILLED}
      />
    </div>
  )
}

export default InfoAddEditPatient
