import type { FC, ReactNode } from 'react'

import { ReactComponent as ContractGuy } from 'assets/icons/contract-guy.svg'

import { INFO_CONSTANTS } from 'features/Info/constants/infoConstants'

import styles from './InfoEmptyRoomsList.module.scss'

interface IProps {
  title?: string
  content?: ReactNode
}

const InfoEmptyRoomsList: FC<IProps> = ({
  title = INFO_CONSTANTS.NO_ACTIVE_LEASES_TODAY,
  content,
}) => {
  return (
    <div className={styles.container}>
      <ContractGuy />
      <h3 className={styles.containerMessage}>{title}</h3>
      {content}
    </div>
  )
}

export default InfoEmptyRoomsList
