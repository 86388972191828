import './promotion-room-availability.scss'

import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Checkbox, Skeleton, Spin, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'

import { Button } from 'common/components/Button/Button'
import { Input } from 'common/components/Input/Input'
import { Label } from 'common/components/Label/Label'
import { Modal } from 'common/components/Modal'
import Show from 'common/components/Show2/Show'
import { TagStatus } from 'common/components/TagStatus/TagStatus'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { useAppDispatch } from 'common/hooks/redux'

import {
  useCreatePromotionRoomsBookingMutation,
  useLazyGetDayRoomsAvailabilityQuery,
} from 'features/Promotions/state/api/promotionsApi'
import { updatePromotionLatestCrud } from 'features/Promotions/state/api/promotionsSlice'
import type { PromotionRoom } from 'features/Promotions/types/promotion.types'

type Props = {
  handleCancel: () => void
  open: boolean
  date: string | null
  skeletonRooms?: number
  rooms: PromotionRoom[]
}

const PromotionRoomAvailability = ({
  open,
  date,
  rooms = [],
  skeletonRooms = 2,
  handleCancel,
}: Props) => {
  const params = useParams()

  const leaseConnection = {
    leaseId: params?.leaseId,
    connection: params?.connection,
  }
  const dispatch = useAppDispatch()
  const [fetchRoomAvailability, { data, isFetching }] = useLazyGetDayRoomsAvailabilityQuery()

  const [createBooking] = useCreatePromotionRoomsBookingMutation()

  const { values, isSubmitting, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues: {
      slots: {},
    },
    onSubmit,
    enableReinitialize: true,
  })

  async function onSubmit() {
    try {
      const data = Object.keys(values.slots).map((key) => {
        return {
          id: Number(key),
          slots: values.slots[key],
        }
      })
      const response = await createBooking({
        ...leaseConnection,
        date,
        data,
      })

      dispatch(updatePromotionLatestCrud(response.data))

      handleCancel()
      resetForm()
    } catch (error) {}
  }

  const onChangeSlot = (room_id: number, slot_id: number) => {
    if (values.slots?.[room_id]?.includes(slot_id)) {
      setFieldValue(
        `slots.${room_id}`,
        [...values.slots[room_id]].filter((id: number) => id !== slot_id),
      )
    } else {
      setFieldValue(`slots.${room_id}`, [...(values.slots[room_id] ?? []), slot_id])
    }
  }

  const hasSelectedSlot = Object.keys(values.slots).some((key) => values.slots[key]?.length)

  useEffect(() => {
    if (open && date) {
      fetchRoomAvailability({
        ...leaseConnection,
        date,
      })
    }
  }, [open])

  const getIsWeekend = () => {
    const selectedDate = dayjs(date).startOf('day')
    const dayOfWeek = selectedDate.day()
    return dayOfWeek === 0 || dayOfWeek === 6
  }

  const isWeekend = getIsWeekend()

  const getDisabledMessage = (roomId: number, isInactive: boolean) => {
    if (isInactive) {
      return 'This slot is not available!'
    }
    const room = rooms.find((room) => room.id === roomId)
    if (!room) return false
    const slots = isWeekend ? room.weekend_slots : room.weekday_slots
    if (slots - (values.slots[roomId]?.length || 0) <= 0)
      return "You've reached maximum slots for this week!"
    return undefined
  }
  return (
    <Modal
      wrapClassName='promotion-room-availability-wrapper'
      destroyOnClose
      onCancel={handleCancel}
      open={open}
      title='I would like to book an:'>
      <Spin spinning={isSubmitting}>
        <div className='promotion-room-availability'>
          <Show>
            <Show.When isVisible={isFetching}>
              <div className='skeleton-container'>
                {Array.from({ length: skeletonRooms }).map((_, ind) => {
                  return (
                    <div className='room-skeleton' key={ind}>
                      <Skeleton.Image active className='room-skeleton-img' />
                      {Array.from({ length: 3 }).map((_, index) => {
                        return (
                          <div key={index} className='room-skeleton-timeslot'>
                            <Skeleton.Button active className='checkbox-skeleton' />
                            <Skeleton.Input active className='input-skeleton' />
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            </Show.When>
            <Show.Else>
              {data?.map((room) => {
                return (
                  <div key={room.id} className='item-container'>
                    <div className='room-header'>
                      <img src={room?.images[0]} className='image' alt={ALT_CONSTANTS.ROOM_PHOTO} />
                      <div className='content-container'>
                        <h2 className='title'>{room?.name}</h2>
                        <TagStatus status='Active' />
                      </div>
                    </div>
                    {room.slots.map((slot, slotInd) => {
                      const isChecked = values.slots?.[room.id]?.includes(slot.id)

                      const disabledMessage = getDisabledMessage(room.id, slot.is_active === 0)

                      const isDisabled = !!disabledMessage && !isChecked

                      return (
                        <div className='time-slot-wrapper' key={`${room.id}_${slot.id}`}>
                          {slotInd === 0 && <Label label='Time Slots' className='timeslot-label' />}
                          <div className='room-timeslot'>
                            <Tooltip title={isDisabled ? disabledMessage : undefined}>
                              <Checkbox
                                disabled={isDisabled}
                                onChange={() => onChangeSlot(room.id, slot.id)}
                                checked={isChecked}
                              />
                            </Tooltip>
                            <Input
                              name={`slot_${slot.id}`}
                              disabled={isDisabled}
                              parentClassName='input-value'
                              inputContainerClassName='input-container'
                              value={slot.name}
                              isActive={isChecked}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
              <div className='footer-container'>
                <Button
                  disabled={isSubmitting || !hasSelectedSlot}
                  htmlType='submit'
                  onClick={() => handleSubmit()}>
                  Add
                </Button>
              </div>
            </Show.Else>
          </Show>
        </div>
      </Spin>
    </Modal>
  )
}

export default PromotionRoomAvailability
