import { BOOKING_TYPE } from 'features/Booking/constants/booking.constants'

const config = {
  getTabs: (bookingType: BOOKING_TYPE) => {
    switch (bookingType) {
      case BOOKING_TYPE.MAILBOX:
        return [
          {
            path: 'details',
            title: 'Booking Details',
          },
          {
            path: 'staff',
            title: 'Staff',
          },
          {
            path: 'documents',
            title: 'Documents',
          },
        ]

      default:
        return [
          {
            path: 'details',
            title: 'Booking Details',
          },
          {
            path: 'patient-schedule',
            title: 'Patient Schedule',
          },
          {
            path: 'patient-activity',
            title: 'Real-time Patient Tracker',
          },
          {
            path: 'staff',
            title: 'Staff',
          },
          {
            path: 'documents',
            title: 'Documents',
          },
          {
            path: 'shop',
            title: 'Shop',
          },
        ]
    }
  },
}

export type BookingConfigType = typeof config

export const bookingConfig: BookingConfigType = Object.freeze(config)
