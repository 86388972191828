import './promotion-calendar.scss'

import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Tooltip } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'

import { useScreenWidth } from 'app/hooks/useScreenWidth'

import { ReactComponent as PlusCircle } from 'assets/icons/plus-circle.svg'

import { Button } from 'common/components/Button/Button'
import { SpinnerOverlay } from 'common/components/SpinnerOverlay'
import {
  BUTTON_MODIFIER,
  BUTTON_PROPORTION,
  BUTTON_SEVERITY,
} from 'common/constants/buttonConstants'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useApiResponse } from 'common/hooks/useApiResponse'
import type { ApiResponse } from 'common/interfaces/IRequestResponse'

import { getRoomName } from 'features/Promotions/components/PromotionDetailsRooms/PromotionDetailsRooms'
import PromotionRoomAvailability from 'features/Promotions/components/PromotionRoomAvailability/PromotionRoomAvailability'
import { useDeleteSlotBookingMutation } from 'features/Promotions/state/api/promotionsApi'
import { removeRoomFromErrorSlots } from 'features/Promotions/state/api/promotionsSlice'
import type {
  PromotionBookingsType,
  PromotionSlot,
  PromotionType,
} from 'features/Promotions/types/promotion.types'

import SlotDetails, { SlotDetailsList } from './SlotDetails'

type Props = {
  bookingsData: PromotionBookingsType
  promotion: PromotionType
  isLoading: boolean
}

const CELL_HEIGHT = 80

function calculateSlotFactor(slot: PromotionSlot) {
  const start_time = slot.start_time.split(':')
  const end_time = slot.end_time.split(':')
  const start = dayjs()
    .hour(Number(start_time[0]))
    .minute(Number(start_time[1]))
    .second(Number(start_time[2]))
  const end = dayjs()
    .hour(Number(end_time[0]))
    .minute(Number(end_time[1]))
    .second(Number(end_time[2]))
  return end.diff(start, 'minute') / 60
}

function formatSlotHour(time: string) {
  return dayjs(time, 'h:mm:ss').format('h:mm A')
}

function formatSlotDetailsDate(date: string, slotTime: string) {
  return `${dayjs(date).format('MMMM D')} ${slotTime}`
}

const PromotionCalendar = ({ bookingsData, promotion, isLoading }: Props) => {
  const { breakpoints } = useScreenWidth()
  const { processApiResponse } = useApiResponse()
  const dispatch = useAppDispatch()
  const params = useParams()

  const [deleteBookingSlot, { isLoading: isDeletingBookingSlot }] = useDeleteSlotBookingMutation()
  const { promotionErrorSlots } = useAppSelector((state) => state.promotionReducer)

  const leaseConnection = {
    leaseId: params?.leaseId,
    connection: params?.connection,
  }

  const [bookingDate, setBookingDate] = useState(null)

  const loading = isLoading || isDeletingBookingSlot

  const formatHeaderDate = (date: string): string => {
    const format = 'ddd[\n]D'
    return dayjs(date).format(format)
  }

  const handleDeleteBooking = useCallback(
    async (bookingId: number) => {
      const response = await deleteBookingSlot({ ...leaseConnection, bookingId })
      processApiResponse(response as ApiResponse, {
        success: 'Booking removed!',
        error: 'Failed to remove booking',
      })
    },
    [leaseConnection],
  )

  const handleDismissErrorRoom = (date: string, slotId: number, roomId: number) => {
    dispatch(
      removeRoomFromErrorSlots({
        date,
        slotId,
        roomId,
      }),
    )
  }

  const handleRemoveBooking = (hasError: boolean, date: string, slotId: number, roomId: number) => {
    if (hasError) {
      return handleDismissErrorRoom(date, slotId, roomId)
    }
    handleDeleteBooking(roomId)
  }

  const isBookAvailable = (date: string): string | undefined => {
    const currentDate = dayjs().startOf('day')
    const selectedDate = dayjs(date).startOf('day')

    if (selectedDate.isBefore(currentDate)) {
      return "You can't book slots in the past!"
    }

    if (
      selectedDate.isSame(dayjs(promotion?.end_date).startOf('day')) ||
      selectedDate.isAfter(dayjs(promotion?.end_date).startOf('day'))
    ) {
      return 'Booking is not available after or on the promotion end date!'
    }

    const dayOfWeek = selectedDate.day()
    const isWeekend = dayOfWeek === 0 || dayOfWeek === 6

    const isAvailable = bookingsData.convention.rooms.some((room) => {
      const availableSlots = isWeekend ? room.weekend_slots : room.weekday_slots
      return availableSlots > 0
    })

    return isAvailable ? undefined : "You've reached maximum slots for this week!"
  }

  return (
    <>
      <SpinnerOverlay isLoading={loading} />
      <PromotionRoomAvailability
        open={!!bookingDate}
        handleCancel={() => setBookingDate(null)}
        date={bookingDate}
        rooms={bookingsData?.convention?.rooms}
      />
      <div className='promotion-calendar'>
        <div className='main-grid'>
          {bookingsData?.bookings?.map((booking) => {
            const bookingDate = booking.date
            const formattedDate = formatHeaderDate(bookingDate)
            const isDisabledBooking = isBookAvailable(bookingDate)

            const errorsSlots = promotionErrorSlots[bookingDate] ?? []

            const combinedSlots = booking.slots.map((sl) => {
              const findSlot = errorsSlots.find((errorSl) => errorSl.id === sl.id)
              const findSlotsRooms = findSlot?.rooms ?? []
              return {
                ...sl,
                rooms: [...sl.rooms, ...findSlotsRooms],
              }
            })

            return (
              <div key={booking.date} className='calendar-col'>
                <Tooltip title={isDisabledBooking}>
                  <div className='header-cell'>
                    <button
                      className='book-button'
                      onClick={() => setBookingDate(booking.date)}
                      disabled={!!isDisabledBooking}>
                      {!isDisabledBooking && <PlusCircle width={18} height={18} />}
                      {!isDisabledBooking ? 'Book' : 'N/A'}
                    </button>
                    <p className='header-date-label'>{formattedDate}</p>
                  </div>
                </Tooltip>
                <div key={booking.date} className='body-col'>
                  {combinedSlots.map((slot) => {
                    const factor = calculateSlotFactor(slot)
                    let showMoreValue = 0
                    let showMoreAdded = false

                    return (
                      <div
                        style={{ height: factor * CELL_HEIGHT }}
                        key={slot.id}
                        className='body-cell'>
                        {!slot.rooms.length && (
                          <span className='empty-slot'>
                            {formatSlotHour(slot.start_time)} - {formatSlotHour(slot.end_time)}
                          </span>
                        )}
                        {slot.rooms.map((room, roomInd, roomsArr) => {
                          const hideExtraSmall = (breakpoints.xs || breakpoints.sm) && roomInd > 0
                          const hideExtraMedium = (breakpoints.md || breakpoints.lg) && roomInd > 0
                          const hideExtraLarge = (breakpoints.lg || breakpoints.xl) && roomInd > 1
                          const hideExtraXLarge = breakpoints.xxl && roomInd >= 2

                          const hides = [
                            hideExtraSmall,
                            hideExtraMedium,
                            hideExtraLarge,
                            hideExtraXLarge,
                          ]

                          const prevAdded = showMoreAdded

                          const isHide = hides.includes(true)

                          if (isHide && !showMoreValue && !showMoreAdded) {
                            showMoreValue = roomsArr.length - roomInd
                            showMoreAdded = true
                          }

                          const slotTime = `${formatSlotHour(slot.start_time)} - ${formatSlotHour(
                            slot.end_time,
                          )}`

                          const hasRoomError = room?.status === 'error'

                          return (
                            <React.Fragment key={room.id}>
                              <Tooltip
                                align={{ offset: [0, 10] }}
                                overlayClassName='slot-details'
                                trigger='click'
                                title={
                                  <SlotDetails
                                    hasError={hasRoomError}
                                    isDeleteDisabled={loading}
                                    handleDeleteBooking={() =>
                                      handleRemoveBooking(
                                        hasRoomError,
                                        bookingDate,
                                        slot.id,
                                        room.id,
                                      )
                                    }
                                    room={{
                                      ...room,
                                      date: booking.date,
                                      timeSlot: formatSlotDetailsDate(booking.date, slotTime),
                                    }}
                                  />
                                }
                                destroyTooltipOnHide>
                                <div
                                  data-error={hasRoomError}
                                  className={classNames('room', {
                                    hide: isHide,
                                  })}>
                                  <span className='base-label'>
                                    {formatSlotHour(slot.start_time)} -{' '}
                                    {formatSlotHour(slot.end_time)}
                                  </span>
                                  <span className='room-name'>{getRoomName(room.name, true)}</span>
                                  {hasRoomError && (
                                    <span className='base-label'>
                                      {'UNABLE TO RESERVE,\nPLEASE TRY ANOTHER SLOT'}
                                    </span>
                                  )}
                                  {hasRoomError && (
                                    <Button
                                      onClick={() =>
                                        handleDismissErrorRoom(bookingDate, slot.id, room.id)
                                      }
                                      proportion={BUTTON_PROPORTION.SMALL}
                                      modifier={BUTTON_MODIFIER.DEFAULT}
                                      severity={BUTTON_SEVERITY.DANGER_OUTLINE}>
                                      Dismiss
                                    </Button>
                                  )}
                                </div>
                              </Tooltip>
                              {showMoreAdded && !prevAdded && (
                                <Tooltip
                                  align={{ offset: [0, 10] }}
                                  overlayClassName='slot-details'
                                  trigger='click'
                                  title={
                                    <SlotDetailsList
                                      isDeleteDisabled={loading}
                                      handleDeleteBooking={(hasErr, roomId) =>
                                        handleRemoveBooking(hasErr, bookingDate, slot.id, roomId)
                                      }
                                      rooms={slot.rooms.map((room) => {
                                        return {
                                          ...room,
                                          date: booking.date,
                                          timeSlot: formatSlotDetailsDate(booking.date, slotTime),
                                        }
                                      })}
                                    />
                                  }
                                  destroyTooltipOnHide>
                                  <p className='more'>+{showMoreValue} Show More</p>
                                </Tooltip>
                              )}
                            </React.Fragment>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default PromotionCalendar
