import { PATH_SEGMENT } from 'routes/pathSegments'

// import adminIcon from './assets/admin.svg'
// import faqIcon from './assets/faq.svg'
// import homeIcon from './assets/home.svg'
// import patientsIcon from './assets/patients.svg'
// import staffIcon from './assets/staff.svg'
// import supportIcon from './assets/support.svg'

import { SIDEBAR_CONSTANTS } from './sidebar.constants'
import type { ISidebarTab } from './sidebar.types'

const config = {
  getItems: (
    isGuest: boolean,
    isUnconfirmedDoctor: boolean,
    isSuperDoctor: boolean,
  ): ISidebarTab[] => {
    const allItems: ISidebarTab[] = [
      {
        route: PATH_SEGMENT.HOME,
        title: SIDEBAR_CONSTANTS.HOME,
        icon: 'ph:house-line-light',
      },
      {
        route: PATH_SEGMENT.PROFILE,
        title: SIDEBAR_CONSTANTS.PROFILE,
        icon: 'ph:user',
      },
      {
        route: PATH_SEGMENT.STAFF,
        title: SIDEBAR_CONSTANTS.STAFF,
        icon: 'tabler:nurse',
      },
      {
        route: PATH_SEGMENT.PATIENTS,
        title: SIDEBAR_CONSTANTS.PATIENTS,
        icon: 'ph:users-three-light',
      },
      {
        route: PATH_SEGMENT.SUPPORT,
        title: SIDEBAR_CONSTANTS.SUPPORT,
        icon: 'ph:chats-circle',
      },
      {
        route: PATH_SEGMENT.FAQ,
        title: SIDEBAR_CONSTANTS.FAQ,
        icon: 'ph:rows',
      },
      {
        route: PATH_SEGMENT.ADMIN,
        title: SIDEBAR_CONSTANTS.ADMIN,
        icon: 'ph:shield-check-light',
      },
    ]

    return allItems.filter((item) => {
      if (
        isGuest &&
        [PATH_SEGMENT.STAFF, PATH_SEGMENT.PATIENTS, PATH_SEGMENT.SUPPORT].includes(item.route)
      ) {
        return false
      }

      if (isUnconfirmedDoctor && [PATH_SEGMENT.STAFF, PATH_SEGMENT.PATIENTS].includes(item.route)) {
        return false
      }

      return !(!isSuperDoctor && item.route === PATH_SEGMENT.ADMIN)
    })
  },
}

export type SidebarConfigType = typeof config

export const sidebarConfig: SidebarConfigType = Object.freeze(config)
