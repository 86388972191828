import 'styles/index.scss'
import type { FC } from 'react'
import { memo, useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import Header from 'layout/Header/Header'
import { PATH_SEGMENT } from 'routes/pathSegments'
import type { RoutesConfigType } from 'routes/routes.utils'
import { generateRouter } from 'routes/routes.utils'

import { Home } from 'features/Home'

export const Guest: FC = memo(() => {
  const routesConfig: RoutesConfigType = useMemo(
    () => ({
      [PATH_SEGMENT.HOME]: <Home />,
      [PATH_SEGMENT.OTHER]: <Navigate to={PATH_SEGMENT.HOME} replace />,
    }),
    [],
  )

  return (
    <>
      <Header />

      <div className='application-layout__content' data-guest={true}>
        <div className='application-layout__content-container'>{generateRouter(routesConfig)}</div>
      </div>
    </>
  )
})
