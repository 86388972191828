import type { FC } from 'react'
import { useCallback, useMemo } from 'react'

import dayjs from 'dayjs'

import { useAuth } from 'app/providers'
import { useSitePoints } from 'app/providers/sitePoints/SitePointsProvider'

import emptyBookedIcon from 'assets/icons/empty-booked.svg'
import fullyBookedIcon from 'assets/icons/fully-booked.svg'
import halfBookedIcon from 'assets/icons/half-booked.svg'
import quartersBookedIcon from 'assets/icons/quarters-booked.svg'
import threeQuartersBookedIcon from 'assets/icons/three-quarters-booked.svg'

import { Calendar } from 'common/components/Calendar/Calendar'
import { Collapse } from 'common/components/Collapse/Collapse'
import { OverlayDetails } from 'common/components/OverlayDetails/OverlayDetails'
import { Show } from 'common/components/Show/Show'
import { BOOKING_STATUS, BOOKING_STATUS_LABELS } from 'common/constants/calendarConstants'
import { DATE_FORMAT } from 'common/constants/dateFormatConstants'
import { RESPONSE_PROPERTY_CONSTANTS } from 'common/constants/reponsePropertyConstants'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useSites } from 'common/hooks/useSites'
import type { ITriggerRequest } from 'common/interfaces/IRequestResponse'

import { LeaseOptionList } from 'features/Booking/components/LeaseOptionList/LeaseOptionList'
import { LeaseSlotList } from 'features/Booking/components/LeaseSlotList/LeaseSlotList'
import { LeaseSubmit } from 'features/Booking/components/LeaseSubmit/LeaseSubmit'
import { BOOKING_CONSTANTS } from 'features/Booking/constants/bookingConstants'
import type {
  IRoomOptionProduct,
  IRoomSelectedDay,
  IRoomTimeSlot,
} from 'features/Booking/interfaces/ILeaseInfoRoom'
import { LeaseBackToBackService } from 'features/Booking/services/leaseBackToBackService'
import { getLeasePriceBySlots, LeaseService } from 'features/Booking/services/leaseService'
import { useLazyFetchRoomSlotsQuery } from 'features/Booking/state/api/bookingApi'
import {
  FILTER_NEW_LEASE_SELECTED_SLOTS,
  UPDATE_NEW_LEASE_ROOM_DATE_INFO,
  UPDATE_NEW_LEASE_ROOM_INFO,
  UPDATE_NEW_LEASE_SELECTED_SLOT,
  UPDATE_NEW_LEASE_SELECTED_SLOT_OPTIONS,
  UPDATE_SELECTED_SLOT_OPTION_STOCK,
} from 'features/Booking/state/slices/leaseSlice'
import type { IOptionSlot } from 'features/Option'
import { SET_OPTION_TYPE_SLOTS } from 'features/Option'
import type { IRemoveSlotInOptionsProps } from 'features/Option/interfaces/IOptionService'
import { OptionService } from 'features/Option/services/optionService'

import styles from './leaseCotent.module.scss'

const { removeSlotInOptions } = OptionService
const { updateSiteOptionsVisibility } = LeaseService
const { updateRoomOptions, updateAvailabilitySlotServices } = LeaseBackToBackService

export interface IBookingContentProps {
  handleCreateLeaseAndClose: () => void
  handleCreateLeaseAndCheckout: () => void
  fetchRoomCalendar: (id?: number | null, date?: string) => void
}

export const LeaseContent: FC<IBookingContentProps> = ({
  handleCreateLeaseAndClose,
  handleCreateLeaseAndCheckout,
  fetchRoomCalendar,
}) => {
  const dispatch = useAppDispatch()
  const sitePoints = useSitePoints()

  const { selectedSite } = useSites()
  const { user, hasCompletedDocuments } = useAuth()

  const { optionTypeSlots } = useAppSelector((state) => state.optionReducer)
  const { newLeaseInfo } = useAppSelector((state) => state.leaseReducer)

  const [fetchSlots, { isFetching: isFetchingSlots }]: ITriggerRequest<IRoomTimeSlot[]> =
    useLazyFetchRoomSlotsQuery()

  const { room, selectedDay, selectedDays, days, slots, isLoading } = newLeaseInfo

  console.log('>>> newLeaseInfo', newLeaseInfo)

  const point_per_dollar = sitePoints?.levels?.point_per_dollar
  const options = selectedDay?.selectedSlot?.options

  const handleChangeDate = useCallback(
    async (date: string): Promise<void> => {
      dispatch(
        UPDATE_NEW_LEASE_ROOM_DATE_INFO({ date: date, selectedSlots: [], selectedSlot: null }),
      )
      if (date) {
        const response = await fetchSlots({ roomId: room.id, date, connection: selectedSite?.code })

        if (response.hasOwnProperty(RESPONSE_PROPERTY_CONSTANTS.SUCCESS)) {
          dispatch(UPDATE_NEW_LEASE_ROOM_INFO({ slots: response.data }))
        }
      }
    },
    [room?.id, selectedSite],
  )

  const adjustedRoomOptions: IRoomOptionProduct[] = useMemo(
    (): IRoomOptionProduct[] =>
      updateSiteOptionsVisibility(!selectedDay?.selectedSlot || isFetchingSlots, [
        ...(options?.services || []),
        ...(options?.products || []),
      ]),
    [selectedDay, isFetchingSlots],
  )

  const adjustedRoomProvisionPackages: IRoomOptionProduct[] = useMemo(
    (): IRoomOptionProduct[] =>
      updateSiteOptionsVisibility(
        !selectedDay?.selectedSlot || isFetchingSlots,
        options?.provision_packages,
      ),
    [selectedDay, isFetchingSlots],
  )

  const handleSelectSlotOption = useCallback(
    (option: IRoomOptionProduct): void => {
      const { selectedOptions } = selectedDay.selectedSlot
      const updatedOptions: IRoomOptionProduct[] = updateRoomOptions(option, selectedOptions)
      dispatch(UPDATE_NEW_LEASE_SELECTED_SLOT_OPTIONS(updatedOptions))
    },
    [selectedDay],
  )

  const handleRemoveTimeSlot = useCallback(
    (slot: IRoomTimeSlot): void => {
      const removeSlotProps: IRemoveSlotInOptionsProps = {
        slotId: slot.id,
        date: selectedDay.date,
        options: optionTypeSlots,
      }
      const optionSlots: IOptionSlot[] = removeSlotInOptions(removeSlotProps)
      dispatch(SET_OPTION_TYPE_SLOTS(optionSlots))
      dispatch(FILTER_NEW_LEASE_SELECTED_SLOTS(slot))
    },
    [selectedDay, optionTypeSlots],
  )

  const handleUpdateSlot = useCallback(
    (slot: IRoomTimeSlot): void => {
      const updatedSlot: IRoomTimeSlot = updateAvailabilitySlotServices(selectedDay, slot)
      dispatch(UPDATE_NEW_LEASE_SELECTED_SLOT(updatedSlot))
    },
    [selectedDay],
  )

  const adjustedSelectedDays = useMemo(
    (): string[] => selectedDays?.map((selectedDay: IRoomSelectedDay): string => selectedDay.date),
    [selectedDays],
  )

  const selectedCalendarDate = useMemo((): string => {
    if (selectedDay?.date) return selectedDay?.date

    const tomorrow: string = dayjs().format('YYYY-MM-DD')
    let nextActiveDate: string | null = null

    for (
      let date: string = tomorrow;
      date in days;
      date = dayjs(date).add(1, 'days').format('YYYY-MM-DD')
    ) {
      if (days[date].active === 1) {
        nextActiveDate = date
        break
      }
    }

    return nextActiveDate
  }, [selectedDay?.date])

  const handleUpdateOption = useCallback((option: IRoomOptionProduct): void => {
    dispatch(UPDATE_SELECTED_SLOT_OPTION_STOCK(option))
  }, [])

  const leasePrice = useMemo(() => {
    if (!selectedDay || !selectedDay?.selectedSlot) return 0
    return getLeasePriceBySlots([selectedDay.selectedSlot])
  }, [selectedDay])

  return (
    <div className={styles.parent}>
      <OverlayDetails room={room} />
      <Collapse summary={BOOKING_CONSTANTS.CHOOSE_DATE_AND_TIME}>
        <div>
          <div className={styles.parentSlots}>
            <Calendar
              days={days}
              withFakeRerender
              isLoading={isLoading}
              handleChangeDate={handleChangeDate}
              selectedDays={adjustedSelectedDays}
              handleChangeMonth={fetchRoomCalendar}
              currentDay={selectedCalendarDate}
            />
            <div className={styles.parentSelectedSlot}>
              <Show when={selectedDay?.date}>
                <h5 className={styles.parentSelectedDay}>
                  {dayjs(selectedDay?.date || selectedCalendarDate).format(
                    DATE_FORMAT.SELECTED_DATE_LABEL,
                  )}
                </h5>
              </Show>

              <LeaseSlotList
                slots={slots.flat()}
                isFetching={isFetchingSlots}
                currentSelected={selectedDay?.selectedSlot}
                handleSelectActiveSlot={handleUpdateSlot}
                handleConfirmActiveSlot={handleUpdateSlot}
                handleRemoveTimeSlot={handleRemoveTimeSlot}
                selectedSlots={selectedDay?.selectedSlots || []}
              />
            </div>
          </div>
          <div className={styles.parentBookingStatusDescription}>
            <div className={styles.parentBookingStatusFullAndEmpty}>
              <div>
                <img
                  className={styles.parentBookingStatusIcon}
                  src={emptyBookedIcon}
                  alt={BOOKING_STATUS.EMPTY}
                />
                {BOOKING_STATUS_LABELS.empty}
              </div>
              <div>
                <img
                  className={styles.parentBookingStatusIcon}
                  src={fullyBookedIcon}
                  alt={BOOKING_STATUS.FULL}
                />
                {BOOKING_STATUS_LABELS.full}
              </div>
            </div>
            <div className={styles.parentBookingStatusQuarters}>
              <div>
                <img
                  className={styles.parentBookingStatusIcon}
                  src={halfBookedIcon}
                  alt={BOOKING_STATUS.HALF}
                />
                {BOOKING_STATUS_LABELS.half}
              </div>
              <div>
                <img
                  className={styles.parentBookingStatusIcon}
                  src={quartersBookedIcon}
                  alt={BOOKING_STATUS.QUARTER}
                />
                {BOOKING_STATUS_LABELS.quarter}
              </div>
              <div>
                <img
                  className={styles.parentBookingStatusIcon}
                  src={threeQuartersBookedIcon}
                  alt={BOOKING_STATUS.THREE_QUARTERS}
                />
                {BOOKING_STATUS_LABELS['three-quarters']}
              </div>
            </div>
          </div>
        </div>
      </Collapse>
      <Collapse
        summary={BOOKING_CONSTANTS.EXTRAS}
        disabled={
          !selectedDay?.selectedSlot ||
          (!adjustedRoomOptions.length && !adjustedRoomProvisionPackages.length)
        }>
        <>
          <div className={styles.parentOptions}>
            <LeaseOptionList
              disabledList={!user || !hasCompletedDocuments}
              options={adjustedRoomOptions}
              siteValue={point_per_dollar?.[selectedSite?.code]}
              handleUpdateOption={handleUpdateOption}
              handleSelectOption={handleSelectSlotOption}
              selectedOptions={selectedDay?.selectedSlot?.selectedOptions || []}
            />
            {!!adjustedRoomProvisionPackages.length && (
              <div className={styles.parentPackages}>
                <h3 className={styles.parentProvisionTitle}>
                  {BOOKING_CONSTANTS.PROVISION_PACKAGES}
                </h3>
                <LeaseOptionList
                  disabledList={!user || !hasCompletedDocuments}
                  siteValue={point_per_dollar?.[selectedSite?.code]}
                  options={adjustedRoomProvisionPackages}
                  handleUpdateOption={handleUpdateOption}
                  handleSelectOption={handleSelectSlotOption}
                  selectedOptions={selectedDay?.selectedSlot?.selectedOptions || []}
                />
              </div>
            )}
          </div>
        </>
      </Collapse>

      <LeaseSubmit
        handleCreateLeaseAndCheckout={handleCreateLeaseAndCheckout}
        handleCreateLeaseAndClose={handleCreateLeaseAndClose}
        price={leasePrice}
      />

      <p className={styles.parentInform}>
        {BOOKING_CONSTANTS.LEASE_INFO_MESSAGE}
        <br />
        {BOOKING_CONSTANTS.IN_ORDER_TO_CONFIRM}
      </p>
    </div>
  )
}
