import type { FC } from 'react'
import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Tabs } from 'antd'

import { useScreenWidth } from 'app/hooks/useScreenWidth'
import { useAuth } from 'app/providers'

import type { ITabPanel } from 'common/interfaces/ITabPanel'
import { DateService } from 'common/services/dateService'
import { UtilService } from 'common/services/utilService'

import styles from './tabPanel.module.scss'

const { getIsValidAndCurrentDay } = DateService

export const TabPanel: FC<ITabPanel> = ({
  onChangeCallback,
  polkaDotCurrentDay,
  tabs,
  timeZone,
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const { isMobile } = useScreenWidth()
  const { isSidebarVisible, toggleIsSidebarVisible } = useAuth()

  const parsedTabs = useMemo(() => {
    if (!tabs) {
      return []
    }

    return tabs.map((tab) => ({
      key: String(tab.path),
      tab:
        polkaDotCurrentDay && getIsValidAndCurrentDay(tab.title, timeZone) ? (
          <span
            className={styles.parent}
            data-cy={`tab-${UtilService.replaceSpaceToUnderscore(tab.title)}`}>
            {tab.title}
            <span className={styles.parentDot} />
          </span>
        ) : (
          <span data-cy={`tab-${UtilService.replaceSpaceToUnderscore(tab.title)}`}>
            {tab.title}
          </span>
        ),
      label:
        polkaDotCurrentDay && getIsValidAndCurrentDay(tab.title, timeZone) ? (
          <span
            onClick={() => {
              if (isSidebarVisible && isMobile) {
                toggleIsSidebarVisible?.()
              }
            }}>
            {tab.title}
            <span className={styles.parentDot} />
          </span>
        ) : (
          <span data-cy={`tab-${UtilService.replaceSpaceToUnderscore(tab.title)}`}>
            {tab.title}
          </span>
        ),
      disabled: tab?.disabled,
    }))
  }, [tabs])

  const tabChangeHandler = useCallback(
    (tabKey: string) => {
      if (onChangeCallback) {
        onChangeCallback(tabKey)
        return
      }
      navigate(tabKey)
    },
    [onChangeCallback],
  )

  const activeTab = useMemo(() => {
    return tabs.find((tab) => location.pathname.includes(tab.path))?.path
  }, [location.pathname])

  return <Tabs items={parsedTabs} onChange={tabChangeHandler} activeKey={activeTab} />
}
