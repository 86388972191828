import addPatientIcon from 'assets/icons/add-patient.svg'

import { ALT_CONSTANTS } from 'common/constants/altConstants'

import { INFO_CONSTANTS } from 'features/Info/constants/infoConstants'

import styles from './InfoAddWalkInPatientButton.module.scss'

interface IProps {
  isDisabled: boolean
  handleClick: () => void
  label?: string
}

const InfoAddWalkInPatientButton = ({
  isDisabled,
  handleClick,
  label = INFO_CONSTANTS.ADD_WALK_IN_PATIENT,
}: IProps) => {
  return (
    <button
      data-cy='add-patient-btn'
      className={styles.newPatient}
      onClick={handleClick}
      disabled={isDisabled}>
      <img src={addPatientIcon} alt={ALT_CONSTANTS.ACTION_ICON} />
      <span className={styles.newPatientLabel}>{label}</span>
    </button>
  )
}

export default InfoAddWalkInPatientButton
