import type { JSX } from 'react'
import { useCallback } from 'react'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import {
  LeaseService,
  RESET_NEW_LEASE_ROOM_INFO,
  SET_NEW_LEASE_ROOMS,
  TOGGLE_IS_CREATE_NEW_LEASE,
  UPDATE_NEW_LEASE_ROOM_INFO,
} from 'features/Booking'
import type { IBookingInfoRoom, IRoomTimeSlot } from 'features/Booking/interfaces/ILeaseInfoRoom'
import { CartRoomCard } from 'features/Cart/components/CartRoomCard/CartRoomCard'
import { useCartManager } from 'features/Cart/hooks/useCartManager'
import type { ICartOption } from 'features/Cart/interfaces/ICartOption'
import {
  TOGGLE_IS_OPEN_CART_DETAILS,
  UPDATE_CART_CONTENT_DATA,
} from 'features/Cart/state/slices/cartSlice'
import type { IOptionSelectFixed, IOptionSlot } from 'features/Option'
import {
  OptionFixedService,
  OptionSelectFixedService,
  SET_OPTION_TYPE_SLOTS,
} from 'features/Option'
import { OptionService } from 'features/Option/services/optionService'
import {
  RESET_OPTION_TYPE_SLOT,
  SET_OPTION_TYPE_SELECT_FIXED,
  UPDATE_OPTION_TYPE_FIXED,
} from 'features/Option/state/slices/optionSlice'

const { removeSlotInOptions } = OptionService
const { removeDateInOptions } = OptionFixedService
const { invalidateRoomInterval } = OptionSelectFixedService
const { getUpdatedLease, getUpdatedRoom, getDayInSelectedDays } = LeaseService

interface IProps extends ICartOption {
  validateLeaseUrl: () => void
}

export const CartRoomList = ({ handleUpdate, validateLeaseUrl, updateCartPrice }: IProps) => {
  const dispatch = useAppDispatch()

  const { isOpenCart } = useAppSelector((state) => state.cartReducer)
  const { bookingCart } = useCartManager()

  const rooms = bookingCart?.data?.rooms ?? []

  const { optionTypeSlots, optionTypeFixed, optionSelectFixed } = useAppSelector(
    (state) => state.optionReducer,
  )

  const handleEditRoom = useCallback(
    (room: IBookingInfoRoom): void => {
      dispatch(UPDATE_NEW_LEASE_ROOM_INFO(room))
      if (isOpenCart) dispatch(TOGGLE_IS_OPEN_CART_DETAILS())
      dispatch(TOGGLE_IS_CREATE_NEW_LEASE())
      validateLeaseUrl()
    },
    [rooms, bookingCart],
  )

  const handleUpdateRoom = (
    slot: IRoomTimeSlot,
    date: string,
    roomInfo: IBookingInfoRoom,
  ): void => {
    const updatedRoomInfo: IBookingInfoRoom = getUpdatedRoom(slot.id, date, roomInfo)
    dispatch(UPDATE_NEW_LEASE_ROOM_INFO(updatedRoomInfo))

    const updatedRooms: IBookingInfoRoom[] = getUpdatedLease(updatedRoomInfo, rooms)
    const isLastDate: boolean = !getDayInSelectedDays(updatedRoomInfo.selectedDays, date)

    const optionsSlot: IOptionSlot[] = updateOptionsSlot(slot.id, date)
    const optionsFixed: IOptionSlot[] = updateOptionsFixed(isLastDate, date)
    const optionsSelect: IOptionSelectFixed[] = updateOptionsSelect(isLastDate, date, roomInfo)

    handleUpdate({
      rooms: updatedRooms,
      optionSlots: optionsSlot,
      optionFixed: optionsFixed,
      optionSelectFixed: optionsSelect,
    })

    dispatch(UPDATE_OPTION_TYPE_FIXED(optionsFixed))
    updateLeasePrice(updatedRooms)
  }

  const updateLeasePrice = (updatedRooms: IBookingInfoRoom[]): void => {
    dispatch(SET_NEW_LEASE_ROOMS(updatedRooms))
    updateCartPrice({
      rooms: updatedRooms,
      optionSelectFixed,
      options: [...optionTypeSlots, ...optionTypeFixed],
    })
    dispatch(UPDATE_CART_CONTENT_DATA({ rooms: updatedRooms }))
    dispatch(RESET_NEW_LEASE_ROOM_INFO())
  }

  const updateOptionsSlot = (slotId: number, date: string): IOptionSlot[] => {
    if (!!optionTypeSlots.length) {
      const updatedOptions: IOptionSlot[] = removeSlotInOptions({
        date,
        slotId,
        options: optionTypeSlots,
      })
      dispatch(SET_OPTION_TYPE_SLOTS(updatedOptions))
      dispatch(RESET_OPTION_TYPE_SLOT())
      return updatedOptions
    }
    return optionTypeSlots
  }

  const updateOptionsFixed = (isLast: boolean, date: string): IOptionSlot[] => {
    if (isLast && !!optionTypeFixed.length) return removeDateInOptions(date, optionTypeFixed)
    return optionTypeFixed
  }

  const updateOptionsSelect = (
    isLast: boolean,
    date: string,
    roomInfo: IBookingInfoRoom,
  ): IOptionSelectFixed[] => {
    if (isLast) {
      const { room, id } = roomInfo
      const roomAlias: string = `${room.name} #${id}`
      const selectFixed: IOptionSelectFixed[] = invalidateRoomInterval(
        roomAlias,
        date,
        optionSelectFixed,
      )
      dispatch(SET_OPTION_TYPE_SELECT_FIXED(selectFixed))
      return selectFixed
    }
    return optionSelectFixed
  }

  return (
    <>
      {rooms.map(
        (room: IBookingInfoRoom, index: number): JSX.Element => (
          <div className='cart-card' key={index}>
            <hr />
            <CartRoomCard
              roomInfo={room}
              handleEditRoom={handleEditRoom}
              handleUpdateRoom={handleUpdateRoom}
            />
          </div>
        ),
      )}
    </>
  )
}
