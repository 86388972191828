import type { FC } from 'react'
import React, { memo, useEffect, useMemo } from 'react'

import { Typography } from 'antd'

import { useAuth } from 'app/providers'

import { useSites } from 'common/hooks/useSites'

import { BOOKING_TYPE } from 'features/Booking/constants/bookingType'
import {
  useLazyFetchBookingsQuery,
  useLazyFetchBookingTypesQuery,
} from 'features/Booking/state/api/bookingApi'

import styles from './dashboard.module.scss'
import { ChooseBookingType } from './User/ChooseBookingType/ChooseBookingType'
import { ActiveBookings } from './User/ActiveBookings/ActiveBookings'
import { Guest } from './Guest/Guest'

const { Title } = Typography

export const Dashboard: FC = memo(() => {
  const { selectedSite } = useSites()
  const { user, hasCompletedDocuments } = useAuth()

  const [fetchBookings, { data: bookings, isFetching: isFetchingBooking }] =
    useLazyFetchBookingsQuery()
  const [fetchBookingTypes, { data: bookingTypes, isFetching: isFetchingBookingTypes }] =
    useLazyFetchBookingTypesQuery({
      refetchOnFocus: true,
    })

  const isNotGuest = useMemo(() => hasCompletedDocuments || user, [hasCompletedDocuments, user])

  const isLoading = useMemo(
    () => isFetchingBooking || isFetchingBookingTypes,
    [isFetchingBooking, isFetchingBookingTypes],
  )

  useEffect(() => {
    if (!selectedSite || !user) {
      return
    }

    fetchBookings(BOOKING_TYPE.ONGOING)
    fetchBookingTypes(selectedSite.code)
  }, [selectedSite])

  return (
    <div className={styles.homeContainer} data-non-auth={!isNotGuest}>
      {isNotGuest ? (
        <>
          <Title level={2} className='m-0'>
            Good Morning 🌤 Dr. <span className='font-semibold'>{user?.name}</span>
          </Title>

          <ChooseBookingType isLoading={isLoading} bookingTypes={bookingTypes} />
          <ActiveBookings isLoading={isLoading} bookings={bookings} />
        </>
      ) : (
        <Guest />
      )}
    </div>
  )
})

export type DashboardType = typeof Dashboard

Dashboard.displayName = 'Dashboard'
