import React from 'react'
import { Navigate } from 'react-router-dom'

import { PATH_SEGMENT } from 'routes/pathSegments'

import { useAuth } from 'app/providers'

export const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user } = useAuth()

  if (!user) {
    return <Navigate to={PATH_SEGMENT.AUTH} replace />
  }

  return <>{children}</>
}
