import type { FC } from 'react'

import classNames from 'classnames'
import { SIDEBAR_CONSTANTS } from 'layout/Sidebar/sidebar.constants'

import activeLinkHoverIcon from 'assets/icons/active-link-hover.svg'
import activeLinkIcon from 'assets/icons/active-link.svg'

import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import styles from './sidebarItem.module.scss'
import type { SidebarItemProps } from './sidebarItem.types'
import { Icon } from '@iconify/react'

export const SidebarItem: FC<SidebarItemProps> = ({ title, isActive, icon, isHovered }) => {
  const adminClass = { [styles.adminMenu]: title === SIDEBAR_CONSTANTS.ADMIN }
  return (
    <>
      <Icon icon={icon} fontSize={24} className={classNames(adminClass)} />
      <span className={classNames(adminClass)}>{title}</span>
      <Show
        when={!isActive}
        fallback={
          <img
            className={styles.activeLink}
            src={activeLinkIcon}
            alt={ALT_CONSTANTS.ACTIVE_ROUTE_ICON}
          />
        }>
        <Show when={isHovered}>
          <img
            className={styles.activeLinkHovered}
            src={activeLinkHoverIcon}
            alt={ALT_CONSTANTS.ACTIVE_ROUTE_ICON}
          />
        </Show>
      </Show>
    </>
  )
}
