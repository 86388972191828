import React, { type FC, memo, useEffect, useMemo } from 'react'
import type { DocumentsProps } from './documents.types'
import { DocumentsTable } from './DocumentsTable/DocumentsTable'
import { PATH_SEGMENT } from 'routes/pathSegments'
import { Navigate } from 'react-router-dom'
import { generateRouter } from 'routes/routes.utils'
import { TabPanel } from 'common/components/TabPanel/TabPanel'
import { BillingHistory } from 'features/Profile/features/BillingHistory/BillingHistory'
import { useLazyFetchLeaseDocumentsQuery } from 'features/Info/state/api/infoApi'

export const Documents: FC<DocumentsProps> = memo(({ bookingApiProps }) => {
  const [fetchDocuments, { data, isLoading }] = useLazyFetchLeaseDocumentsQuery()

  const tabs = useMemo(
    () => [
      {
        path: 'contracts',
        title: 'Contracts',
      },
      {
        path: 'billing',
        title: 'Billing',
      },
    ],
    [],
  )

  const routesConfig = useMemo(
    () => ({
      [PATH_SEGMENT.CONTRACTS]: <DocumentsTable data={data} isLoading={isLoading} />,
      [PATH_SEGMENT.BILLING]: <BillingHistory bookingId={bookingApiProps?.bookingId} />,
      [PATH_SEGMENT.ROOT]: <Navigate to={PATH_SEGMENT.CONTRACTS} replace />,
    }),
    [data, isLoading, bookingApiProps],
  )

  useEffect(() => {
    if (!bookingApiProps) {
      return
    }

    fetchDocuments({ ...bookingApiProps })
  }, [bookingApiProps])

  return (
    <>
      <TabPanel tabs={tabs} />
      {generateRouter(routesConfig)}
    </>
  )
})
