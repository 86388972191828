import { Button } from 'common/components/Button/Button'
import { Modal } from 'common/components/Modal'
import { Show } from 'common/components/Show/Show'
import { BUTTON_CONSTANTS, BUTTON_MODIFIER } from 'common/constants/buttonConstants'

import styles from './InfoWarning.module.scss'

interface IProps {
  open: boolean
  title: string
  warning: string
  okButtonText: string
  hasCancel?: boolean
  handleClose: () => void
  handleConfirm: () => void
}

const InfoWarning = ({
  open,
  title,
  warning,
  okButtonText,
  hasCancel = false,
  handleClose,
  handleConfirm,
}: IProps) => {
  return (
    <Modal open={open} title={title} cancelButton={false} destroyOnClose>
      <div className={styles.parent}>
        <p>{warning}</p>
        <div className={styles.parentActions}>
          <div>
            <Show when={hasCancel}>
              <Button
                className={styles.parentActionButton}
                modifier={BUTTON_MODIFIER.DEFAULT}
                onClick={handleClose}>
                {BUTTON_CONSTANTS.CANCEL}
              </Button>
            </Show>
          </div>
          <Button className={styles.parentActionButton} onClick={handleConfirm}>
            {okButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default InfoWarning
