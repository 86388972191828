import type { FC } from 'react'
import { memo } from 'react'

import { IncludedList } from 'common/components/IncludedList/IncludedList'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'

import styles from './overlayDetails.module.scss'

interface IProps {
  room: ISiteRoom
}

export const OverlayDetails: FC<IProps> = memo(({ room }) => (
  <div className={styles.parent}>
    <div className={styles.parentInfo}>
      <img className={styles.parentPhoto} src={room?.images[0]} alt={ALT_CONSTANTS.ROOM_PHOTO} />
      <p className={styles.parentDescription}>{room?.description}</p>
    </div>
    <IncludedList includedList={room?.attributes} />
    <hr />
  </div>
))
